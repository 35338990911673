/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import underscore from 'underscore';

import * as NotificationsActions from 'src/blueprints/core/actions/notifications';
import { historyShape } from 'src/lib/defs';
import _ from 'src/lib/i18n';
import { autoDispatchActionCreator } from 'src/lib/redux';
import * as requests from 'src/lib/requests';

import * as AuthActions from '../actions/auth';

export class AuthProvider extends React.Component {
  constructor(props) {
    super(props);

    this.deregisterAuthErrorHandler = null;
    this.onRequestAuthError = this.onRequestAuthError.bind(this);
    this.onRouteChange = this.onRouteChange.bind(this);
  }
  componentDidMount() {
    this.props.history.listen(this.onRouteChange);

    this.deregisterAuthErrorHandler = requests.APIRequest.registerAuthErrorHandler(
      this.onRequestAuthError
    );

    if (this.props.token && this.props.login) {
      this.onRouteChange();
    } else {
      this.props.actions.getAuth();
    }
  }
  componentWillUnmount() {
    if (underscore.isFunction(this.deregisterAuthErrorHandler)) {
      this.deregisterAuthErrorHandler();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.token && nextProps.login) {
      if (this.props.ready !== nextProps.ready && nextProps.ready) {
        this.props.actions.presentNotification(
          _('Welcome back, <%- login %>!', { login: nextProps.login }),
          'success'
        );
      }
    }
  }
  componentDidUpdate(prevProps) {
    this.onRouteChange();
  }
  onRequestAuthError(message, statusCode) {
    if (this.props.history.getCurrentLocation().pathname !== '/login') {
      this.props.actions.removeAuth();
    }
  }
  onRouteChange() {
    const location = this.props.history.getCurrentLocation();
    if (this.props.token && this.props.login) {
      if (this.props.ready) {
        if (location.pathname === '/auth/login') {
          this.props.history.push('/');
        }
      } else {
        this.props.actions.verifyAuth();
      }
    } else {
      if (!location.pathname.startsWith('/auth/')) {
        this.props.history.push('/auth/login');
      }
    }
  }
  render() {
    return this.props.children;
  }
}

AuthProvider.propTypes = {
  actions: PropTypes.shape({
    getAuth: PropTypes.func.isRequired,
    presentNotification: PropTypes.func.isRequired,
    removeAuth: PropTypes.func.isRequired,
    verifyAuth: PropTypes.func.isRequired
  }),
  history: historyShape,
  login: PropTypes.string,
  token: PropTypes.string,
  ready: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    history: props.history,
    login: state.auth.get('login'),
    ready: state.auth.get('ready'),
    token: state.auth.get('token')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      getAuth: AuthActions.getAuth,
      presentNotification: NotificationsActions.present,
      removeAuth: AuthActions.removeAuth
    },
    dispatch
  );

  actions.verifyAuth = autoDispatchActionCreator(
    AuthActions.verifyAuth,
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthProvider);

export default reduxContainer;
