/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ClassName from 'classnames';
import React from 'react';
import underscore from 'underscore';

import { Column } from 'src/components/AppTable/Column';

export const AppNameHeader = props => {
  const headerProps = underscore.extendOwn({}, props || {});
  headerProps.className = ClassName('app-name-header', props.className);

  return <Header {...headerProps}>{props.children}</Header>;
};

export const Header = props => {
  const className = ClassName('header', props.className);

  return (
    <Column className={props.columnClassName}>
      <div className={className}>
        {props.children && <span>{props.children}</span>}
      </div>
    </Column>
  );
};
