/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { AppTable } from 'src/components';

export class ReleaseDetailsApproversRow extends React.Component {
  render() {
    return (
      <>
        <div className="header">
          <AppTable.Row>
            <AppTable.Cell columnClassName="doublewidth" className="grey-text">
              APPROVED BY
            </AppTable.Cell>

            <AppTable.Cell className="grey-text">CREATED BY</AppTable.Cell>
            <AppTable.Cell className="grey-text">EXECUTED BY</AppTable.Cell>
            <AppTable.Cell className="grey-text">TICKET</AppTable.Cell>
          </AppTable.Row>
        </div>
        <div className="data">
          <AppTable.Row>
            <AppTable.Cell></AppTable.Cell>
            <AppTable.Cell></AppTable.Cell>
            <AppTable.Cell></AppTable.Cell>
            <AppTable.Cell></AppTable.Cell>
            <AppTable.Cell></AppTable.Cell>
          </AppTable.Row>
        </div>
        <div className="header">
          <AppTable.Row>
            <AppTable.Cell className="grey-text">STATUS</AppTable.Cell>

            <AppTable.Cell columnClassName="triplewidth" className="grey-text">
              DESCRIPTION
            </AppTable.Cell>
          </AppTable.Row>
        </div>
        <div className="data">
          <AppTable.Row>
            <AppTable.Cell></AppTable.Cell>
            <AppTable.Cell columnClassName="triplewidth"></AppTable.Cell>
          </AppTable.Row>
        </div>
      </>
    );
  }
}

ReleaseDetailsApproversRow.propTypes = {
  approvers: PropTypes.array.isRequired
};
