/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ClassName from 'classnames';
import React from 'react';
import underscore from 'underscore';

import { Column } from 'src/components/AppTable/Column';
import { Glyphicon } from 'src/components/Bootstrap';
import { Label } from 'src/components/Label';
import { Loader } from 'src/components/Loader';

export const AppNameCell = props => {
  const cellClassName = ClassName('app-name-cell', props.className);

  const cellProps = underscore.extendOwn(
    {
      center: true,
      className: cellClassName
    },
    {
      onClick: props.onClick
    }
  );

  return (
    <Cell {...cellProps}>
      <span>{props.children}</span>
    </Cell>
  );
};

export const Cell = props => {
  const className = ClassName('cell', props.className, {
    clickable: underscore.isFunction(props.onClick),
    'text-center': props.center,
    'hide-text-overflow': props.hideTextOverflow
  });

  const onClick = event => {
    if (underscore.isFunction(props.onClick)) {
      props.onClick(event);
    }
  };

  return (
    <Column className={props.columnClassName}>
      <div className={className} onClick={onClick}>
        {props.children}
      </div>
    </Column>
  );
};

export const GlyphiconCell = props => {
  const cellClassName = ClassName('glyphicon-cell', props.className);

  const cellProps = underscore.extendOwn(
    {
      center: true,
      className: cellClassName
    },
    {
      onClick: props.onClick
    }
  );

  return (
    <Cell {...cellProps}>
      <span>{props.value}</span>
      <Glyphicon glyph={props.glyph} />
    </Cell>
  );
};

export const LabelCell = props => {
  const cellClassName = ClassName('label-cell', props.className);

  const cellProps = underscore.extendOwn(
    {
      center: true,
      className: cellClassName
    },
    {
      onClick: props.onClick
    }
  );

  return (
    <Cell {...cellProps}>
      <span title={props.value}>{props.value}</span>
      <Label bsStyle={props.labelStyle} title={props.title}>
        {props.labelText || ' '}
      </Label>
    </Cell>
  );
};

export const RowHeaderCell = props => {
  const cellClassName = ClassName('row-header-cell', props.className);

  const cellProps = underscore.extendOwn(
    {
      className: cellClassName
    },
    {
      onClick: props.onClick
    }
  );

  return (
    <Cell {...cellProps}>
      {props.children && <span>{props.children}</span>}
    </Cell>
  );
};

export const SegmentedControlCell = props => {
  const cellClassName = ClassName(props.className, {
    'segmented-control-cell': true
  });

  const cellProps = underscore.extendOwn(
    {
      className: cellClassName
    },
    {
      onClick: props.onClick
    }
  );

  return (
    <Cell {...cellProps}>
      <ul className="list-unstyled">
        {React.Children.map(props.children, child => {
          return <li>{child}</li>;
        })}
      </ul>
    </Cell>
  );
};

export const LoaderCell = props => {
  const cellClassName = ClassName(props.className, {
    'loader-cell': true
  });

  const cellProps = underscore.extendOwn(
    {
      className: cellClassName
    },
    {
      onClick: props.onClick
    }
  );

  const loaderProps = {
    animate: props.animate,
    small: true,
    text: props.text
  };

  return (
    <Cell {...cellProps}>
      <Loader {...loaderProps} />
    </Cell>
  );
};
