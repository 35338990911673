/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ClassName from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export class DropdownToggle extends React.Component {
  render() {
    return this.props.children || null;
  }
}

export class DropdownMenu extends React.Component {
  render() {
    const menuClassName = ClassName('dropdown-menu', {
      open: this.props.open
    });

    return (
      <div className={menuClassName}>
        <div className="dropdown-menu-wrapper-left"></div>
        <div className="dropdown-menu-wrapper-right"></div>

        <div className="dropdown-menu-inner">
          <ul className="list-unstyled">{this.props.children}</ul>
        </div>
      </div>
    );
  }
}

DropdownMenu.propTypes = {
  open: PropTypes.bool
};
