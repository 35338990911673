/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';

import { Alert, I18NMessage } from 'src/components';
import _ from 'src/lib/i18n';

export const NoDataPlaceholder = props => {
  return (
    <Alert bsStyle="info">
      <b>{_('No data.')}</b>&nbsp;
      <I18NMessage
        message={_(
          "Find out how to add some from the <a href='https://docs.putit.io/'>Documentation</a>."
        )}
      />
    </Alert>
  );
};
