/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import URI from 'urijs';

import Config from 'src/config';
import { get, post } from 'src/lib/requests';
import { applyFilters } from 'src/lib/urilib';

export const list = (filters, include, onSuccess, onError) => {
  let requestURL = new URI(Config.get('api_base_url'));
  requestURL = URI.joinPaths(requestURL, '/orders');

  if (include && include.length > 0) {
    requestURL.setSearch({ include: include.join(',') });
  }

  requestURL = applyFilters(requestURL, filters);
  return get(requestURL, onSuccess, onError);
};

export const addNewChange = (params, onSuccess, onError) => {
  if (!params.name) return;

  let requestURL = new URI(Config.get('api_base_url'));
  requestURL = URI.joinPaths(requestURL, '/release', params.name, '/orders');

  const body = {
    name: params.changeName,
    description: params.description,
    start_date: params.startDate,
    end_date: params.endDate
  };
  return post(requestURL, body, onSuccess, onError);
};

export const addApplicationsToChange = (params, onSuccess, onError) => {
  if (!params.name || !params.changeName) return;

  let requestURL = new URI(Config.get('api_base_url'));
  requestURL = URI.joinPaths(
    requestURL,
    '/release',
    params.name,
    '/orders',
    params.changeName,
    '/applications'
  );

  const body = params.applications.map(app => ({
    application_name: app.name,
    version_name: app.version
  }));

  return post(requestURL, body, onSuccess, onError);
};

export const addEnvToApplication = (params, onSuccess, onError) => {
  if (
    !params.name ||
    !params.changeName ||
    !params.appName ||
    !params.appVersion
  )
    return;

  let requestURL = new URI(Config.get('api_base_url'));
  requestURL = URI.joinPaths(
    requestURL,
    '/release',
    params.name,
    '/orders',
    params.changeName,
    '/applications',
    params.appName,
    params.appVersion,
    '/envs'
  );

  const body = {
    env_name: params.envName
  };
  return post(requestURL, body, onSuccess, onError);
};

export const addApproversToChange = (params, onSuccess, onError) => {
  if (!params.name || !params.changeName || params.approvers.length === 0)
    return;

  let requestURL = new URI(Config.get('api_base_url'));
  requestURL = URI.joinPaths(
    requestURL,
    '/release',
    params.name,
    '/orders',
    params.changeName,
    '/approvers'
  );

  const body = params.approvers.map(app => ({
    email: app
  }));

  return post(requestURL, body, onSuccess, onError);
};
