/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import API from 'src/lib/api';

import { DASHBOARD_ACTIONS } from '../defs';

export const cleanup = () => {
  return {
    type: DASHBOARD_ACTIONS.CLEANUP
  };
};

export const listApplications = dispatch => {
  API.Application.list(
    ['envs'],
    data => {
      dispatch(onListApplicationsSuccess(data));
    },
    error => {
      dispatch(onListApplicationsError(error));
    }
  );

  return {
    type: DASHBOARD_ACTIONS.LIST_APPLICATIONS_START
  };
};

export const onListApplicationsSuccess = data => {
  return {
    type: DASHBOARD_ACTIONS.LIST_APPLICATIONS_SUCCESS,
    applications: data
  };
};

export const onListApplicationsError = error => {
  return {
    type: DASHBOARD_ACTIONS.LIST_APPLICATIONS_ERROR,
    error: error
  };
};

export const loadEnvStatus = (dispatch, application, env) => {
  API.Status.load(
    application,
    env,
    data => {
      dispatch(onLoadEnvStatusSuccess(application, env, data));
    },
    (error, response) => {
      dispatch(
        onLoadEnvStatusError(application, env, error, response.statusCode)
      );
    }
  );

  return {
    type: DASHBOARD_ACTIONS.LOAD_ENV_STATUS_START
  };
};

export const onLoadEnvStatusSuccess = (application, env, data) => {
  return {
    type: DASHBOARD_ACTIONS.LOAD_ENV_STATUS_SUCCESS,
    env: env,
    application: application,
    status: data
  };
};

export const onLoadEnvStatusError = (application, env, error, statusCode) => {
  return {
    type: DASHBOARD_ACTIONS.LOAD_ENV_STATUS_ERROR,
    env: env,
    application: application,
    error: error,
    statusCode: statusCode
  };
};
