/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { List, Map, fromJS } from 'immutable';
import underscore from 'underscore';

import * as DataProcessors from 'src/lib/data_processors';

import { APP_DASHBOARD_ACTIONS } from '../defs';

const DEFAULT_STATE = Map({
  artifacts: Map(),
  credentials: List(),
  envs: List(),
  error: null,
  loading: false,
  releaseCounts: List(),
  releaseStatusCounts: Map(),
  statuses: List(),
  upcomingReleases: Map()
});

const appDashboardReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case APP_DASHBOARD_ACTIONS.CLEANUP:
      return DEFAULT_STATE;

    case APP_DASHBOARD_ACTIONS.LOAD_START:
      return DEFAULT_STATE.merge({
        loading: true,
        error: null
      });

    case APP_DASHBOARD_ACTIONS.SET_ENVS:
      return state.set('envs', fromJS(action.envs));

    case APP_DASHBOARD_ACTIONS.SET_STATUSES:
      return state.set(
        'statuses',
        fromJS(underscore.map(action.statuses, DataProcessors.StatusProcessor))
      );

    case APP_DASHBOARD_ACTIONS.SET_ARTIFACTS:
      return state.set('artifacts', fromJS(action.artifacts));

    case APP_DASHBOARD_ACTIONS.SET_CREDENTIALS:
      return state.set(
        'credentials',
        fromJS(
          underscore.map(action.credentials, DataProcessors.CredentialProcessor)
        )
      );

    case APP_DASHBOARD_ACTIONS.SET_RELEASE_COUNTS:
      return state.set('releaseCounts', fromJS(action.releaseCounts));

    case APP_DASHBOARD_ACTIONS.SET_RELEASE_STATUS_COUNTS:
      return state.set(
        'releaseStatusCounts',
        fromJS(action.releaseStatusCounts)
      );

    case APP_DASHBOARD_ACTIONS.SET_UPCOMING_RELEASES:
      return state.set(
        'upcomingReleases',
        fromJS(
          DataProcessors.UpcomingReleasesProcessor(action.upcomingReleases)
        )
      );

    case APP_DASHBOARD_ACTIONS.LOAD_SUCCESS:
      return state.merge({
        loading: false,
        error: null
      });

    case APP_DASHBOARD_ACTIONS.LOAD_ERROR:
      return state.merge({
        loading: false,
        error: action.error
      });

    default:
      return state;
  }
};

export default appDashboardReducer;
