/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Provider } from 'react-redux';
import * as ReactRouter from 'react-router';

import GlobalErrorReporterView from 'src/blueprints/core/views/GlobalErrorReporterView';
import { AuthProvider } from 'src/blueprints/auth/providers';
import * as ErrorsActions from 'src/blueprints/core/actions/errors';
import { navItemsShape, widgetsShape } from 'src/blueprints/core/defs';
import {
  I18NProvider,
  SettingsProvider,
  WelcomeProvider
} from 'src/blueprints/core/providers';
import { historyShape, routesShape, storeShape } from 'src/lib/defs';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.onGlobalError = this.onGlobalError.bind(this);
  }

  getChildContext() {
    return {
      navItems: this.props.navItems,
      widgets: this.props.widgets
    };
  }

  componentDidMount() {
    window.addEventListener('error', this.onGlobalError);
  }
  componentWillUnmount() {
    window.removeEventListener('error', this.onGlobalError);
  }
  onGlobalError(event) {
    this.props.store.dispatch(ErrorsActions.report(event));
  }
  render() {
    return (
      <Provider store={this.props.store}>
        <GlobalErrorReporterView>
          <I18NProvider>
            <AuthProvider history={this.props.history}>
              <SettingsProvider history={this.props.history}>
                <WelcomeProvider history={this.props.history}>
                  <ReactRouter.Router
                    history={this.props.history}
                    routes={this.props.routes}
                  />
                </WelcomeProvider>
              </SettingsProvider>
            </AuthProvider>
          </I18NProvider>
        </GlobalErrorReporterView>
      </Provider>
    );
  }
}

App.childContextTypes = {
  navItems: navItemsShape,
  widgets: widgetsShape
};

App.propTypes = {
  history: historyShape,
  navItems: navItemsShape,
  routes: routesShape,
  store: storeShape,
  widgets: widgetsShape
};
