/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Map } from 'immutable';

import { SETTINGS_ACTIONS, SETTINGS_DEFAULTS } from '../defs';

const DEFAULT_STATE = Map({
  lang: undefined,
  messagesLoaded: false,
  messagesLoadError: null,
  needsSetupWizard: SETTINGS_DEFAULTS.needsSetupWizard,
  settingsLoaded: false,
  settingsLoadError: null
});

const settingsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SETTINGS_ACTIONS.INIT_I18N:
      return state.merge({
        lang: action.lang,
        messagesLoaded: false,
        messagesLoadError: null
      });

    case SETTINGS_ACTIONS.SET_LANG:
      return state.set('lang', action.lang);

    case SETTINGS_ACTIONS.LOAD_MESSAGES_START:
      return state.merge({
        messagesLoaded: false,
        messagesLoadError: null
      });

    case SETTINGS_ACTIONS.LOAD_MESSAGES_SUCCESS:
      return state.set('messagesLoaded', true);

    case SETTINGS_ACTIONS.LOAD_MESSAGES_ERROR:
      return state.merge({
        messagesLoaded: true,
        messagesLoadError: action.messagesLoadError
      });

    case SETTINGS_ACTIONS.LOAD_SETTINGS_START:
      return state.merge({
        settingsLoaded: false,
        settingsLoadError: null
      });

    case SETTINGS_ACTIONS.LOAD_SETTINGS_SUCCESS:
      return state.merge({
        needsSetupWizard: action.needsSetupWizard,
        settingsLoaded: true
      });

    case SETTINGS_ACTIONS.LOAD_SETTINGS_ERROR:
      return state.merge({
        settingsLoaded: false,
        settingsLoadError: action.settingsLoadError
      });

    case SETTINGS_ACTIONS.DISABLE_SETUP_WIZARD:
      return state.set('needsSetupWizard', false);

    case SETTINGS_ACTIONS.RESET:
      return state.merge({
        needsSetupWizard: SETTINGS_DEFAULTS.needsSetupWizard,
        settingsLoaded: false,
        settingsLoadError: null
      });

    default:
      return state;
  }
};

export default settingsReducer;
