/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { routerShape } from 'react-router';

import { Navbar, Sidebar } from '../components';
import NotificationCenterView from './NotificationCenterView';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import _ from 'src/lib/i18n.js';

export class AppView extends React.Component {
  constructor(props) {
    super(props);
    this.onLogoClick = this.onLogoClick.bind(this);
  }
  onLogoClick() {
    this.context.router.push('/');
  }
  render() {
    if (!this.props.authReady) {
      return null;
    }

    if (this.props.needsSetupWizard) {
      return null;
    }

    return (
      <div id="AppView">
        <BreadcrumbsItem to="/">{_('Home')}</BreadcrumbsItem>
        <Navbar router={this.context.router} />

        <Sidebar onLogoClick={this.onLogoClick}>
          {this.props.sidebarWidgetsView}
        </Sidebar>

        <div className="container-fluid">
          {this.props.mainView ? this.props.mainView : this.props.children}
        </div>

        <NotificationCenterView />
      </div>
    );
  }
}

AppView.contextTypes = {
  router: routerShape
};

AppView.propTypes = {
  authReady: PropTypes.bool.isRequired,
  needsSetupWizard: PropTypes.bool.isRequired,
  mainView: PropTypes.node,
  sidebarWidgetsView: PropTypes.node
};

const mapStateToProps = (state, props) => {
  return {
    authReady: state.auth.get('ready'),
    needsSetupWizard: state.settings.get('needsSetupWizard'),
    mainView: props.mainView,
    sidebarWidgetsView: props.sidebarWidgetsView
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const reduxContainer = connect(mapStateToProps, mapDispatchToProps)(AppView);

export default reduxContainer;
