/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import URI from 'urijs';
import underscore from 'underscore';

import Config from 'src/config';
import API from 'src/lib/api';
import { RequestsQueue } from 'src/lib/requests';

import * as AppDashboardActions from '../actions/app_dashboard';

export class AppDashboardDataSource {
  constructor(dispatch, application) {
    this.onError = this.onError.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onLoadEnvs = this.onLoadEnvs.bind(this);
    this.onLoadStatuses = this.onLoadStatuses.bind(this);
    this.onLoadArtifacts = this.onLoadArtifacts.bind(this);
    this.onLoadCredentials = this.onLoadCredentials.bind(this);
    this.onLoadReleaseCounts = this.onLoadReleaseCounts.bind(this);
    this.onLoadReleaseStatusCounts = this.onLoadReleaseStatusCounts.bind(this);
    this.onLoadUpcomingReleases = this.onLoadUpcomingReleases.bind(this);

    this.application = application;
    this.dispatch = dispatch;

    this.envs = [];
    this.statuses = [];
    this.artifacts = {};
    this.credentials = [];
    this.releaseCounts = [];
    this.releaseStatusCounts = {};
    this.upcomingReleases = {};
  }
  onError(error, response) {
    if (
      response.statusCode !== 404 ||
      (/\/upcoming$/.test(response.url) && response.statusCode !== 500)
    ) {
      this.dispatch(AppDashboardActions.onLoadError(error));
    }
  }
  onSuccess() {
    this.dispatch(AppDashboardActions.onLoadSuccess());
  }
  loadEnvs() {
    API.Application.listEnvs(this.application, this.onLoadEnvs, this.onError);
  }
  onLoadEnvs(envs) {
    this.envs = envs;

    this.dispatch(AppDashboardActions.setEnvs(envs));
    this.loadStatuses();
  }
  loadStatuses() {
    const queue = new RequestsQueue(false);
    queue.onError(this.onError);
    queue.onSuccess(this.onLoadStatuses);

    underscore.each(this.envs, (env, index) => {
      let requestURL = new URI(Config.get('api_base_url'));
      requestURL = URI.joinPaths(
        requestURL,
        '/status',
        this.application,
        env.name
      );

      queue.get(requestURL, status => {
        this.statuses[index] = status || {};
      });
    });

    queue.start();
  }
  onLoadStatuses() {
    this.dispatch(AppDashboardActions.setStatuses(this.statuses));
    this.loadArtifacts();
  }
  loadArtifacts() {
    const queue = new RequestsQueue(false);
    queue.onError(this.onError);
    queue.onSuccess(this.onLoadArtifacts);

    underscore.each(this.statuses, (status, index) => {
      const needsLoad = underscore.isUndefined(this.artifacts[status.version]);
      if (status.version && needsLoad) {
        this.artifacts[status.version] = null;

        let requestURL = new URI(Config.get('api_base_url'));

        requestURL = URI.joinPaths(
          requestURL,
          '/application',
          this.application,
          'versions',
          status.version,
          'artifacts'
        );

        queue.get(requestURL, data => {
          this.artifacts[status.version] = data.length;
        });
      }
    });

    queue.start();
  }
  onLoadArtifacts() {
    this.dispatch(AppDashboardActions.setArtifacts(this.artifacts));
    this.loadCredentials();
  }
  loadCredentials() {
    const queue = new RequestsQueue(false);
    queue.onError(this.onError);
    queue.onSuccess(this.onLoadCredentials);

    underscore.each(this.envs, (env, index) => {
      let requestURL = new URI(Config.get('api_base_url'));

      requestURL = URI.joinPaths(
        requestURL,
        '/application',
        this.application,
        'envs',
        env.name,
        'credential'
      );

      queue.get(requestURL, data => {
        this.credentials[index] = data || null;
      });
    });

    queue.start();
  }
  onLoadCredentials() {
    this.dispatch(AppDashboardActions.setCredentials(this.credentials));
    this.loadReleaseCounts();
  }
  loadReleaseCounts() {
    const queue = new RequestsQueue(false);
    queue.onError(this.onError);
    queue.onSuccess(this.onLoadReleaseCounts);

    underscore.each(this.envs, (env, index) => {
      let requestURL = new URI(Config.get('api_base_url'));

      requestURL = URI.joinPaths(
        requestURL,
        '/application',
        this.application,
        'envs',
        env.name,
        'releases',
        'count'
      );

      queue.get(requestURL, data => {
        if (data) {
          this.releaseCounts[index] = data.count;
        }
      });
    });

    queue.start();
  }
  onLoadReleaseCounts() {
    this.dispatch(AppDashboardActions.setReleaseCounts(this.releaseCounts));
    this.loadReleaseStatusCounts();
  }
  loadReleaseStatusCounts() {
    API.Application.getReleasesByEnv(
      this.application,
      this.onLoadReleaseStatusCounts,
      this.onLoadReleaseStatusCounts
    );
  }
  onLoadReleaseStatusCounts(data) {
    this.releaseStatusCounts = data;

    this.dispatch(
      AppDashboardActions.setReleaseStatusCounts(this.releaseStatusCounts)
    );

    this.loadUpcomingReleases();
  }
  loadUpcomingReleases() {
    API.Application.getUpcomingReleases(
      this.application,
      this.onLoadUpcomingReleases,
      this.onLoadUpcomingReleases
    );
  }
  onLoadUpcomingReleases(data) {
    this.upcomingReleases = data;

    this.dispatch(
      AppDashboardActions.setUpcomingReleases(this.upcomingReleases)
    );

    this.onSuccess();
  }
  load() {
    this.loadEnvs();
  }
}
