/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button, FullWindowContainer, Modal } from 'src/components';
import _ from 'src/lib/i18n.js';

import * as ErrorsActions from '../actions/errors';

class SyntheticErrorEvent {
  constructor(message, error) {
    this.error = error;
    this.message = message;
  }
}

export class GlobalErrorReporterView extends React.Component {
  constructor(props) {
    super(props);
    this.onShowDetailsButtonClick = this.onShowDetailsButtonClick.bind(this);

    this.state = {
      showDetails: false
    };
  }
  componentDidCatch(error, info) {
    this.props.actions.reportError(
      new SyntheticErrorEvent(_('React Error'), error)
    );
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.event !== this.props.event) {
      this.setState({ showDetails: false });
    }
  }
  formatDetails() {
    const result = [];

    if (this.props.event) {
      result.push(this.props.event.message);

      if (this.props.event.error && this.props.event.error.stack) {
        result.push(this.props.event.error.stack);
      }
    }

    return result.join('\n');
  }
  onShowDetailsButtonClick() {
    this.setState({ showDetails: true });
  }
  render() {
    if (this.props.event) {
      return (
        <FullWindowContainer>
          <Modal show={true}>
            <Modal.Body>
              <p className="lead">
                <strong>{_('An unexpected error has occurred.')}</strong>
              </p>
              <p>
                {_(
                  'If you wish to report it please include the details below.'
                )}
              </p>
              {!this.state.showDetails && (
                <Button
                  bsSize="xsmall"
                  bsStyle="primary"
                  onClick={this.onShowDetailsButtonClick}
                >
                  {_('Show details')}
                </Button>
              )}
              {this.state.showDetails && <pre>{this.formatDetails()}</pre>}
            </Modal.Body>
          </Modal>
        </FullWindowContainer>
      );
    }

    return this.props.children;
  }
}

GlobalErrorReporterView.propTypes = {
  event: PropTypes.object
};

const mapStateToProps = (state, props) => {
  return {
    event: state.errors.get('event'),
    actions: PropTypes.shape({
      reportError: PropTypes.func.isRequired
    })
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      reportError: ErrorsActions.report
    },
    dispatch
  );

  return { actions: actions };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalErrorReporterView);

export default reduxContainer;
