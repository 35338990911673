/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const RE_APP_ENV_NAME = /^[A-Za-z0-9._-]{1,32}$/;
export const RE_AWS_TAGS = /^[a-zA-Z0-9!&+-=._:/@]+?$/;
export const RE_IP = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/;
export const RE_USER_NAME = /^[a-z_][a-z0-9_-]*[$]?$/;

export const USER_NAME_MAX_LENGTH = 32;
