/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import underscore from 'underscore';

import * as NotificationsActions from '../actions/notifications';
import { NotificationCenterAlert } from '../components';

export class NotificationCenterView extends React.Component {
  componentWillUnmount() {
    this.props.actions.cleanup();
  }
  render() {
    return (
      <div id="NotificationCenterView">
        <TransitionGroup>
          {underscore.map(this.props.notifications.toJS(), (item, index) => {
            return (
              <CSSTransition
                key={item.identifier}
                classNames="nc"
                timeout={{ enter: 300, exit: 300 }}
              >
                <NotificationCenterAlert
                  identifier={item.identifier}
                  text={item.text}
                  kind={item.kind}
                  title={item.title}
                  subtitle={item.subtitle}
                  dismiss={this.props.actions.dismiss}
                />
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </div>
    );
  }
}

NotificationCenterView.propTypes = {
  notifications: PropTypes.instanceOf(List).isRequired,
  actions: PropTypes.shape({
    cleanup: PropTypes.func.isRequired,
    dismiss: PropTypes.func.isRequired
  })
};

const mapStateToProps = (state, props) => {
  return {
    notifications: state.notifications
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      cleanup: NotificationsActions.cleanup,
      dismiss: NotificationsActions.dismiss
    },
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationCenterView);

export default reduxContainer;
