/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import API from 'src/lib/api';

import { RESET_PASSWORD_ACTIONS } from '../defs';

export const cleanup = () => {
  return {
    type: RESET_PASSWORD_ACTIONS.CLEANUP
  };
};

export const start = (dispatch, email) => {
  API.Auth.resetPassword(
    email,
    data => {
      dispatch(onSuccess());
    },
    error => {
      dispatch(onError(error));
    }
  );

  return {
    type: RESET_PASSWORD_ACTIONS.START
  };
};

export const onSuccess = () => {
  return {
    type: RESET_PASSWORD_ACTIONS.SUCCESS
  };
};

export const onError = error => {
  return {
    type: RESET_PASSWORD_ACTIONS.ERROR,
    error: error
  };
};
