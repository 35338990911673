/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { AppTable, SlashDateFormatter, Button } from 'src/components';
import _ from 'src/lib/i18n';

export class ReleaseNameCell extends React.Component {
  constructor(props) {
    super(props);
    this.onInfoClick = this.onInfoClick.bind(this);
  }
  onInfoClick(event) {
    event.stopPropagation();
    event.preventDefault();
  }
  render() {
    const className = ClassNames(
      'release-name-cell',
      this.props.release.status
    );
    const infoClassName = ClassNames('release-info expanded');

    return (
      <AppTable.Cell className={className}>
        <span title={this.props.release.name}>{this.props.release.name}</span>
        <div className={infoClassName} onClick={this.onInfoClick}>
          <dl className="dl-horizontal">
            <dt>{_('Opened')}</dt>
            <dd>
              <SlashDateFormatter value={this.props.release.start_date} />
            </dd>
            <dt>{_('Closed')}</dt>
            <dd>
              <SlashDateFormatter value={this.props.release.end_date} />
            </dd>
          </dl>
          <div className="actions">
            <Button className="btn-default-short" wrapperClassName="no-padding">
              <span className={`lock ${this.props.release.status}`} />
            </Button>

            <Button
              className="btn-white-short no-padding"
              wrapperClassName="no-padding"
              onClick={this.props.onReleaseDetailsClick}
            >
              <span className="details" />
            </Button>
          </div>
        </div>
      </AppTable.Cell>
    );
  }
}

ReleaseNameCell.propTypes = {
  release: PropTypes.object.isRequired,
  onReleaseDetailsClick: PropTypes.func.isRequired
};
