/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';

import { Glyphicon } from 'src/components';
import _ from 'src/lib/i18n';

import { ApplyConflict, ApplyError } from '../components';
import { SETUP_WIZARD_APPLY_STATUS } from '../defs';

export const ApplyStatus = props => {
  switch (props.status) {
    case SETUP_WIZARD_APPLY_STATUS.WAITING:
      return <span className="waiting">{_('Waiting')}</span>;

    case SETUP_WIZARD_APPLY_STATUS.APPLYING:
      return <span className="applying">{_('Applying...')}</span>;

    case SETUP_WIZARD_APPLY_STATUS.SUCCESS:
      return (
        <span className="success">
          <Glyphicon bsStyle="success" glyph="ok" /> {_('Done')}
        </span>
      );

    case SETUP_WIZARD_APPLY_STATUS.CONFLICT:
      return <ApplyConflict envs={props.envs} conflicts={props.conflicts} />;

    case SETUP_WIZARD_APPLY_STATUS.ERROR:
      return <ApplyError hosts={props.hosts} errors={props.errors} />;

    default:
      return (
        <span className="danger">
          <Glyphicon bsStyle="danger" glyph="exclamation-sign" /> {_('Error')}
        </span>
      );
  }
};
