/*eslint no-console: ["error", { allow: ["log"] }] */
/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDocumentTitle } from 'redux-doctitle';

import * as Components from 'src/components';

class DevThemeView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onTestInputChange = this.onTestInputChange.bind(this);
    this.onTestCheckboxChange = this.onTestCheckboxChange.bind(this);
    this.onTestSelectChange = this.onTestSelectChange.bind(this);

    this.state = {
      testInputValue: '',
      testCheckboxChecked: true,
      testSelectValue: ''
    };
  }
  componentDidMount() {
    this.props.actions.setDocumentTitle('Putit Style Guide');
  }
  onTestInputChange(event) {
    this.setState({ testInputValue: event.target.value });
  }
  onTestCheckboxChange(newFlag) {
    this.setState({ testCheckboxChecked: newFlag });
  }
  onTestSelectChange(newValue) {
    this.setState({ testSelectValue: newValue });
  }
  render() {
    return (
      <div id="DevThemeView" className="container-fluid">
        <div className="page-header">
          <h1>Putit Style Guide</h1>
        </div>

        <Components.Panel header={<b>Fonts</b>}>
          <p>Regular text font</p>
          <p className="text-light">Light text font</p>
          <p>
            <b>Bold text font</b>
          </p>
          <p>
            <em>Italic text font</em>
          </p>
        </Components.Panel>

        <Components.Panel header={<b>Color palette</b>}>
          <div className="row">
            <div className="col-md-2">
              <p className="text-center">
                <b>Text color</b>
              </p>
              <div style={{ height: 64, background: '#333' }}></div>
            </div>
            <div className="col-md-2">
              <p className="text-center">
                <b>Primary color</b>
              </p>
              <div className="bg-primary" style={{ height: 64 }}></div>
            </div>
            <div className="col-md-2">
              <p className="text-center">
                <b>Success/positive color</b>
              </p>
              <div className="bg-success" style={{ height: 64 }}></div>
            </div>
            <div className="col-md-2">
              <p className="text-center">
                <b>Info color</b>
              </p>
              <div className="bg-info" style={{ height: 64 }}></div>
            </div>
            <div className="col-md-2">
              <p className="text-center">
                <b>Warning color</b>
              </p>
              <div className="bg-warning" style={{ height: 64 }}></div>
            </div>
            <div className="col-md-2">
              <p className="text-center">
                <b>Danger/negative color</b>
              </p>
              <div className="bg-danger" style={{ height: 64 }}></div>
            </div>
          </div>
        </Components.Panel>

        <Components.Panel header={<b>Headings</b>}>
          <h1>h1 Heading</h1>
          <h2>h2 Heading</h2>
          <h3>h3 Heading</h3>
          <h4>h4 Heading</h4>
          <h5>h5 Heading</h5>
          <h6>h6 Heading</h6>
        </Components.Panel>

        <Components.Panel header={<b>Typography</b>}>
          <h2>Default body text</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur a
            elit libero. Morbi cursus, enim a iaculis sodales, turpis ante
            suscipit ipsum, a tincidunt dolor nulla nec orci. Nulla vestibulum
            auctor purus a dignissim. Quisque eleifend ornare odio, nec blandit
            tellus elementum a. Sed non augue vel nibh hendrerit eleifend.
          </p>

          <h2>Lead text</h2>
          <p className="lead">
            Phasellus convallis, ligula nec pharetra pellentesque, nibh diam
            volutpat tortor, nec tempus tortor tellus eu mi. Nullam tempor
            blandit nisl, at tincidunt nulla placerat sed. In efficitur sem nec
            sem tempus, ut convallis mauris bibendum.
          </p>

          <h2>Small text</h2>
          <p>
            <small>
              Ut bibendum, sem a sollicitudin efficitur, lacus erat volutpat
              lorem, iaculis sollicitudin enim velit nec risus. Praesent in orci
              sed libero sagittis varius eget eu dui. Donec pretium ornare
              auctor.
            </small>
          </p>
        </Components.Panel>

        <Components.Panel header={<b>Table</b>}>
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </Components.Panel>

        <Components.Panel header={<b>Form controls</b>}>
          <div className="row">
            <div className="col-sm-6">
              <p className="text-center">
                <b>Input states</b>
              </p>
              <form>
                <div className="form-group">
                  <label>Normal</label>
                  <Components.FormControl
                    type="text"
                    placeholder="Enter something here"
                    value={this.state.testInputValue}
                    onChange={this.onTestInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>Focus</label>
                  <Components.FormControl
                    type="text"
                    placeholder="Enter something here"
                    onBlur={() => {
                      console.log('FormControl.onBlur()');
                    }}
                    onFocus={() => {
                      console.log('FormControl.onFocus()');
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Disabled</label>
                  <Components.FormControl
                    type="text"
                    defaultValue="Enter something here"
                    disabled
                  />
                </div>
                <div className="form-group has-error">
                  <label>Input with problem</label>
                  <Components.FormControl
                    type="text"
                    placeholder="Enter something here"
                  />
                </div>
                <div className="form-group has-warning">
                  <label>Input with warning</label>
                  <Components.FormControl
                    type="text"
                    placeholder="Enter something here"
                  />
                </div>
                <div className="form-group has-success">
                  <label>Input with success</label>
                  <Components.FormControl
                    type="text"
                    placeholder="Enter something here"
                  />
                </div>
              </form>
            </div>
            <div className="col-sm-6">
              <p className="text-center">
                <b>Field types</b>
              </p>
              <form>
                <div className="form-group">
                  <label>Generic input</label>
                  <Components.FormControl
                    type="text"
                    placeholder="Text/password/whatever input"
                  />
                </div>

                <div className="form-group">
                  <label>Textarea</label>
                  <Components.FormControl
                    componentClass="textarea"
                    placeholder="Textarea"
                    rows={3}
                  />
                </div>

                <Components.Checkbox
                  checked={this.state.testCheckboxChecked}
                  label="A checkbox"
                  onChange={this.onTestCheckboxChange}
                />
                <Components.Checkbox
                  checked={true}
                  label="A checked checkbox"
                />
                <Components.Checkbox
                  checked={false}
                  disabled
                  label="A disabled checkbox"
                />
                <Components.Checkbox
                  checked={true}
                  disabled
                  label="A disabled checked checkbox"
                />

                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      name="optionsRadios"
                      value="option1"
                      defaultChecked
                    />
                    Option one is this and that&mdash;be sure to include why
                    it's great
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="optionsRadios" value="option2" />
                    Option two can be something else and selecting it will
                    deselect option one
                  </label>
                </div>
                <div className="radio disabled">
                  <label>
                    <input
                      type="radio"
                      name="optionsRadios"
                      value="option3"
                      disabled
                    />
                    Option three is disabled
                  </label>
                </div>

                <div className="form-group">
                  <label>Select</label>
                  <Components.Select
                    placeholder="-- choose --"
                    onChange={this.onTestSelectChange}
                    value={this.state.testSelectValue}
                  >
                    <Components.Select.Option value="spam">
                      spam
                    </Components.Select.Option>
                    <Components.Select.Option value="eggs">
                      eggs
                    </Components.Select.Option>
                  </Components.Select>
                </div>

                <div className="form-group">
                  <label>Disabled select</label>
                  <Components.Select
                    disabled={true}
                    value="spam"
                    onChange={newValue => {
                      console.log('Disabled select change?');
                    }}
                  >
                    <Components.Select.Option value="spam">
                      spam
                    </Components.Select.Option>
                    <Components.Select.Option value="eggs">
                      eggs
                    </Components.Select.Option>
                  </Components.Select>
                </div>

                <div className="form-group">
                  <label>File input</label>
                  <input type="file" />
                </div>
              </form>
            </div>
          </div>
        </Components.Panel>

        <Components.Panel header={<b>Buttons</b>}>
          <table className="table">
            <thead>
              <tr>
                <th>Size</th>
                <th>Default</th>
                <th>Primary</th>
                <th>Success</th>
                <th>Info</th>
                <th>Warning</th>
                <th>Danger</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>Normal</b>
                </td>
                <td>
                  <Components.Button
                    bsStyle="default"
                    onClick={() => {
                      console.log('Button click');
                    }}
                  >
                    Default
                  </Components.Button>
                </td>
                <td>
                  <Components.Button bsStyle="primary">
                    Primary
                  </Components.Button>
                </td>
                <td>
                  <Components.Button bsStyle="success">
                    Success
                  </Components.Button>
                </td>
                <td>
                  <Components.Button bsStyle="info">Info</Components.Button>
                </td>
                <td>
                  <Components.Button bsStyle="warning">
                    Warning
                  </Components.Button>
                </td>
                <td>
                  <Components.Button bsStyle="danger">Danger</Components.Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Components.Panel>

        <Components.Panel header={<b>Dropdown</b>}>
          <Components.Dropdown id="themeDropdown1" defaultOpen>
            <Components.DropdownToggle bsRole="toggle" />

            <Components.DropdownMenu bsRole="menu">
              <Components.MenuItem header>DEV</Components.MenuItem>
              <Components.MenuItem>host1.example.com</Components.MenuItem>
              <Components.MenuItem disabled>
                host2.example.com
              </Components.MenuItem>
              <Components.MenuItem>host3.example.com</Components.MenuItem>
            </Components.DropdownMenu>
          </Components.Dropdown>
        </Components.Panel>

        <Components.Panel header={<b>Alerts</b>}>
          <Components.Alert bsStyle="success">
            <strong>Well done!</strong> You successfully read this important
            alert message.
          </Components.Alert>
          <Components.Alert bsStyle="info">
            <strong>Heads up!</strong> This alert needs your attention, but it's
            not super important.
            <br />
            Hi, I'm a second line!
          </Components.Alert>
          <Components.Alert bsStyle="warning">
            <strong>Warning!</strong> Best check yo self, you're not looking too
            good.
          </Components.Alert>
          <Components.Alert bsStyle="danger">
            <strong>Oh snap!</strong> Change a few things up and try submitting
            again.
          </Components.Alert>
        </Components.Panel>

        <Components.Panel header={<b>Labels</b>}>
          <Components.Label bsStyle="default" />
          <Components.Label bsStyle="primary" />
          <Components.Label bsStyle="success" />
          <Components.Label bsStyle="info" />
          <Components.Label bsStyle="warning" />
          <Components.Label bsStyle="danger" />
        </Components.Panel>

        <Components.Panel header={<b>Breadcrumb</b>}>
          <Components.Breadcrumb>
            <Components.Breadcrumb.Item>Spam</Components.Breadcrumb.Item>
            <Components.Breadcrumb.Item>Eggs</Components.Breadcrumb.Item>
            <Components.Breadcrumb.Item active>
              Spameggs
            </Components.Breadcrumb.Item>
          </Components.Breadcrumb>
        </Components.Panel>

        <Components.Panel header={<b>Modal</b>}>
          <div className="putit-style-guide-modal">
            <Components.Modal.Dialog>
              <Components.Modal.Body>
                <p>One fine body&hellip;</p>
              </Components.Modal.Body>

              <Components.Modal.Footer>
                <Components.ModalButton
                  onClick={() => {
                    console.log('ModalButton click');
                  }}
                >
                  Close
                </Components.ModalButton>
                <Components.ModalButton disabled>Delete</Components.ModalButton>
                <Components.ModalButton>Save</Components.ModalButton>
              </Components.Modal.Footer>
            </Components.Modal.Dialog>
          </div>
        </Components.Panel>

        <Components.Panel header={<b>Big Loader</b>}>
          <Components.Loader />
        </Components.Panel>

        <Components.Panel header={<b>Small Loader</b>}>
          <Components.Loader small />
        </Components.Panel>
      </div>
    );
  }
}

DevThemeView.propTypes = {
  actions: PropTypes.shape({
    setDocumentTitle: PropTypes.func.isRequired
  })
};

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      setDocumentTitle: setDocumentTitle
    },
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DevThemeView);

export default reduxContainer;
