/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import underscore from 'underscore';

import { Glyphicon } from 'src/components';
import _ from 'src/lib/i18n';

export const AppConflict = props => {
  if (props.conflicts.size === 0) {
    return null;
  }

  return (
    <p>
      <b>{_('Application name')}</b>:
      {underscore.map(props.conflicts.toJS(), (conflict, index) => {
        switch (conflict) {
          case 'name':
            return (
              <span key={index} className="conflict" field={conflict}>
                <span className="text-danger">
                  {_('This application already exists')}
                </span>
              </span>
            );
          default:
            return null;
        }
      })}
    </p>
  );
};

export const EnvsConflict = props => {
  if (props.conflicts.size === 0) {
    return null;
  }

  return (
    <p>
      <b>{_('Environments')}</b>:
      {underscore.map(props.conflicts.toJS(), (env, index) => {
        return (
          <span key={index} className="conflict" field={env}>
            <i>{env}</i>:&nbsp;
            <span className="text-danger">
              {_('This environment already exists')}
            </span>
          </span>
        );
      })}
    </p>
  );
};

export const HostsConflict = props => {
  if (props.conflicts.size === 0) {
    return null;
  }

  return (
    <p>
      <b>{_('Hosts')}</b>:
      {underscore.map(props.conflicts.toJS(), (name, index) => {
        return (
          <span key={index} className="conflict" field={name}>
            <i>{name}</i>:&nbsp;
            <span className="text-danger">{_('This host already exists')}</span>
          </span>
        );
      })}
    </p>
  );
};

export const CredentialsConflict = props => {
  if (props.conflicts.size === 0 || props.envs.size === 0) {
    return null;
  }

  return (
    <p>
      <b>{_('Credentials')}</b>:
      {underscore.map(props.conflicts.toJS(), (conflict, index) => {
        const env = props.envs.get(index);

        let conflictText = null;
        switch (conflict) {
          case 'ssh_key':
            conflictText = _('SSH key already exists');
            break;

          case 'user':
            conflictText = _('User already exists');
            break;

          case 'credential':
            conflictText = _('Credential already exists');
            break;

          default:
            break;
        }

        return (
          <span
            key={index}
            className="conflict"
            field={`${env.get('name')}-${conflict}`}
          >
            <i>
              {env.get('user')}@{env.get('name')}
            </i>
            :&nbsp;
            <span className="text-danger">{conflictText}</span>
          </span>
        );
      })}
    </p>
  );
};

export const ApplyConflict = props => {
  return (
    <span className="danger apply-conflict">
      <Glyphicon bsStyle="danger" glyph="exclamation-sign" /> {_('Conflict')}
      <AppConflict conflicts={props.conflicts.get('application')} />
      <EnvsConflict conflicts={props.conflicts.get('envs')} />
      <HostsConflict conflicts={props.conflicts.get('hosts')} />
      <CredentialsConflict
        envs={props.envs}
        conflicts={props.conflicts.get('credentials')}
      />
    </span>
  );
};
