/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import underscore from 'underscore';

import { AppTable, Glyphicon } from 'src/components';
import { DEFAULT_ENV_STATUS, NO_VALUE_PLACEHOLDER } from 'src/lib/defs';
import _ from 'src/lib/i18n';

import * as types from 'src/types';

interface Props {
  applicationName: string;
  envName: string;
  loadError: string;
  loadEnvStatus: (applicationName: string, envName: string) => void;
  status: types.EnvStatus;
}

export class EnvStatusCell extends React.Component<Props> {
  componentDidMount() {
    if (underscore.isUndefined(this.props.status)) {
      this.props.loadEnvStatus(this.props.applicationName, this.props.envName);
    }
  }
  render() {
    let cellView = null;

    if (!underscore.isUndefined(this.props.loadError)) {
      cellView = (
        <AppTable.Cell center className="load-error">
          <Glyphicon glyph="exclamation-sign" /> {_('Load error!')}
        </AppTable.Cell>
      );
    } else if (underscore.isUndefined(this.props.status)) {
      cellView = <AppTable.LoaderCell />;
    } else {
      cellView = (
        <AppTable.LabelCell
          labelStyle={this.props.status.style || DEFAULT_ENV_STATUS}
          value={this.props.status.version || NO_VALUE_PLACEHOLDER}
        />
      );
    }

    return cellView;
  }
}
