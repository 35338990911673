/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as SetupWizardActions from '../actions/setup_wizard';
import { SETUP_WIZARD_MODAL_MODE } from '../defs';
import SetupWizardModalView from './ModalView';

export class AppWizardView extends React.Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }
  onClose() {
    this.props.onClose();
    this.props.actions.cleanup();
  }
  render() {
    return (
      <SetupWizardModalView
        mode={SETUP_WIZARD_MODAL_MODE.APP_WIZARD}
        show={this.props.show}
        onClose={this.onClose}
      />
    );
  }
}

AppWizardView.propTypes = {
  actions: PropTypes.shape({
    cleanup: PropTypes.func.isRequired
  }),
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    show: props.show,
    onClose: props.onClose
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      cleanup: SetupWizardActions.cleanup
    },
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppWizardView);

export default reduxContainer;
