/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ClassName from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import underscore from 'underscore';

import Config from 'src/config';
import { widgetsShape } from '../defs';

export class SidebarWidgets extends React.Component {
  render() {
    if (!this.props.widgets || !this.props.widgets.sidebar) {
      return null;
    }

    const className = ClassName('widgets', this.props.className, {
      'putit-hidden': underscore.isBoolean(this.props.hidden)
        ? this.props.hidden
        : false
    });

    return (
      <div className={className}>
        {underscore.map(this.props.widgets.sidebar, (widget, index) => {
          return React.createElement(widget, { key: index });
        })}
      </div>
    );
  }
}

SidebarWidgets.propTypes = {
  className: PropTypes.string,
  hidden: PropTypes.bool,
  widgets: widgetsShape
};

export class Sidebar extends React.Component {
  render() {
    return (
      <div id="Sidebar">
        <div
          className="logo"
          onClick={this.props.onLogoClick}
          title={Config.appVersion(false)}
        ></div>

        <SidebarWidgets widgets={this.context.widgets} />

        <div className="blueprint-widgets">{this.props.children}</div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  onLogoClick: PropTypes.func.isRequired
};

Sidebar.contextTypes = {
  widgets: widgetsShape
};
