/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import underscore from 'underscore';
import URI from 'urijs';

import Config from 'src/config';
import StyleVariables from 'src/styles/_variables.scss';
import { Modal, AppTable, Button, SlashDateFormatter } from 'src/components';
import {
  DEFAULT_ENV_STATUS,
  NO_VALUE_PLACEHOLDER,
  STATUS_TO_LABEL_STYLE
} from 'src/lib/defs';
import _ from 'src/lib/i18n.js';

const ROW_HEIGHT = parseInt(StyleVariables.appTableRowHeight, 10);
const SIDEBAR_WIDTH = parseInt(StyleVariables.sidebarWidth, 10);

export class ReleaseOrderDetailsPopup extends React.Component {
  constructor(props) {
    super(props);
    this.onPopupLayout = this.onPopupLayout.bind(this);
  }
  getResultRows() {
    let result = [];
    if (this.props.order) {
      result = underscore.reduce(
        this.props.order.release_order_results,
        (acc, releaseOrderResult) => {
          const application = underscore.find(
            this.props.order.applications_with_versions,
            (applicationWithVersion, index) => {
              return (
                applicationWithVersion.application_with_version.id ===
                releaseOrderResult.application_with_version_id
              );
            }
          );

          if (application) {
            const applicationName = application.application_with_version.name;
            const version = application.application_with_version.version;
            const env = releaseOrderResult.env;

            const releaseName = this.props.release.name;
            const orderName = this.props.order.name;

            let logURL = null;
            if (releaseOrderResult.has_log) {
              let logURI = new URI(Config.get('api_base_url'));

              logURI = URI.joinPaths(
                logURI,
                'release',
                releaseName,
                'orders',
                orderName,
                'results',
                applicationName,
                version,
                'envs',
                env,
                'logs'
              );

              logURI.query({ as_attachment: true });

              logURL = logURI.href().toString();
            }

            acc.push({
              application: applicationName,
              version: version,
              env: env,
              status: releaseOrderResult.status,
              deploymentDate: releaseOrderResult.deployment_date,
              logURL: logURL
            });
          }

          return acc;
        },
        []
      );
    }

    return result;
  }
  onPopupLayout(layout) {
    const top = layout[1] + ROW_HEIGHT;

    return [SIDEBAR_WIDTH, top, window.innerWidth - SIDEBAR_WIDTH];
  }
  render() {
    const resultRows = this.getResultRows();
    return (
      <Modal
        show={true}
        onHide={this.props.onOverlayClick}
        dialogClassName="putit-release-details-popup putit-release-order-details-popup"
      >
        <Modal.Body>
          <AppTable.Table>
            <div className="header">
              <AppTable.Row>
                <AppTable.Cell>
                  {this.props.release.name} {_('Info')}:
                </AppTable.Cell>
                <AppTable.Cell columnClassName="triplewidth">
                  {this.props.order.name}
                </AppTable.Cell>
                <AppTable.Cell>
                  {_('Total')} {resultRows.length}
                </AppTable.Cell>
                <AppTable.Cell columnClassName="buttons-cell">
                  <div className="buttons pull-right">
                    <Button className="btn-white-short">
                      <span className="delete" />
                    </Button>

                    <Button className="btn-default-short">
                      <span className="save" />
                    </Button>
                  </div>{' '}
                </AppTable.Cell>
              </AppTable.Row>
            </div>

            {resultRows.length === 0 && (
              <div className="no-border">
                <AppTable.Row>
                  <AppTable.Cell columnClassName="triplewidth">
                    <strong>{_('No release order results.')}</strong>
                  </AppTable.Cell>
                </AppTable.Row>{' '}
              </div>
            )}
            {resultRows.length > 0 && (
              <div className="">
                <div className="header-details header details no-border">
                  <AppTable.Row>
                    <AppTable.Cell className="grey-text">
                      {_('Application')}
                    </AppTable.Cell>
                    <AppTable.Cell className="grey-text">
                      {_('Local')}
                    </AppTable.Cell>
                    <AppTable.Cell className="grey-text">
                      {_('Deploy')}
                    </AppTable.Cell>
                    <AppTable.Cell className="grey-text">
                      {_('Status')}
                    </AppTable.Cell>
                    <AppTable.Cell className="grey-text">
                      {_('Date')}
                    </AppTable.Cell>
                  </AppTable.Row>
                </div>
                <div className="data">
                  {resultRows.map((result, index) => {
                    return (
                      <AppTable.Row key={index}>
                        <AppTable.Cell>{result.application}</AppTable.Cell>
                        <AppTable.Cell>{result.version}</AppTable.Cell>
                        <AppTable.Cell>{result.env}</AppTable.Cell>
                        <AppTable.Cell className="no-padding-left">
                          <AppTable.LabelCell
                            labelStyle={
                              STATUS_TO_LABEL_STYLE[result.status] ||
                              DEFAULT_ENV_STATUS
                            }
                            title={result.status}
                            value={
                              result.status === DEFAULT_ENV_STATUS
                                ? NO_VALUE_PLACEHOLDER
                                : result.status.toUpperCase()
                            }
                          />
                        </AppTable.Cell>
                        <AppTable.Cell>
                          <SlashDateFormatter value={result.deploymentDate} />
                        </AppTable.Cell>
                        <AppTable.Cell>
                          <Button
                            className="btn-white-short"
                            wrapperClassName="no-padding"
                            href={result.logURL}
                          >
                            <span className="logs" />
                          </Button>
                        </AppTable.Cell>
                      </AppTable.Row>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="no-border description">
              <div className="header details">
                <AppTable.Row>
                  <AppTable.Cell className="grey-text">
                    {_('Description')}
                  </AppTable.Cell>
                </AppTable.Row>
              </div>
              <div className="data">
                <AppTable.Row>
                  <AppTable.Cell>{this.props.order.description}</AppTable.Cell>
                </AppTable.Row>
              </div>
            </div>
          </AppTable.Table>
        </Modal.Body>
      </Modal>
    );
  }
}

ReleaseOrderDetailsPopup.propTypes = {
  anchor: PropTypes.any.isRequired,
  order: PropTypes.object.isRequired,
  release: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onOverlayClick: PropTypes.func.isRequired
};
