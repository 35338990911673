/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ClassName from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export class Widget extends React.Component {
  constructor(props) {
    super(props);
    this.onTitleClick = this.onTitleClick.bind(this);

    this.state = {
      expanded: false
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.disabled !== this.props.disabled) {
      if (this.props.disabled) {
        this.setState({ expanded: false });
      }
    }
  }
  onTitleClick(event) {
    event.stopPropagation();
    event.preventDefault();

    if (!this.props.disabled) {
      this.setState({ expanded: !this.state.expanded });
    }
  }
  render() {
    const className = ClassName('widget', {
      disabled: this.props.disabled
    });

    const caretClassName = ClassName('widget-caret', {
      expanded: this.state.expanded
    });

    const contentClassName = ClassName('widget-content', {
      expanded: this.state.expanded
    });

    return (
      <div id={this.props.id} className={className}>
        <div className="widget-inner">
          <span className="widget-icon" />
          {!this.props.disabled && <span className={caretClassName} />}
          <p className="widget-title" onClick={this.onTitleClick}>
            {this.props.title}
          </p>
          <div className={contentClassName}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

Widget.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired
};
