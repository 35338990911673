/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

export class NavbarLoginView extends React.Component {
  constructor() {
    super();
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.state = {
      hover: false
    };
  }

  getInitialsFromLogin(login) {
    return login
      .split(' ')
      .map(n => n[0])
      .join('');
  }
  onMouseEnter() {
    this.setState({ hover: true });
  }
  onMouseLeave() {
    this.setState({ hover: false });
  }
  render() {
    return (
      <div
        className={
          this.state.hover
            ? 'AuthNavbarLoginView expanded'
            : 'AuthNavbarLoginView'
        }
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {this.state.hover ? (
          <div className="fullNameMenu">
            <span className="fullNameMenuText">{this.props.login || ''}</span>
            <span className="fullNameMenuIcon"></span>
          </div>
        ) : (
          <span>
            {this.props.login
              ? this.getInitialsFromLogin(this.props.login)
              : ''}
          </span>
        )}
      </div>
    );
  }
}

NavbarLoginView.propTypes = {
  login: PropTypes.string
};

const mapStateToProps = (state, props) => {
  return {
    login: state.auth.get('login')
  };
};

const reduxContainer = connect(mapStateToProps)(NavbarLoginView);

export default reduxContainer;
