/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'src/lib/i18n.js';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { AppTable, Button, Modal } from 'src/components';
import { ReleaseDetailsAppRow } from './ReleaseDetailsAppRow';
import { DEFAULT_ENV_STATUS } from 'src/lib/defs';

export class ReleaseDetailsPopup extends React.Component {
  getResultRows() {
    const result = [];

    for (const env of this.props.release.envs) {
      for (const order of env.orders) {
        for (const application_with_versions of order.applications_with_versions) {
          let date;
          let status;
          const awv = application_with_versions.application_with_version;
          const releaseOrderResult = order.release_order_results.find(
            r => r.awv_id === awv.id
          );
          if (releaseOrderResult) {
            date = releaseOrderResult.deployment_date;
            status = releaseOrderResult.status;
          } else {
            status = DEFAULT_ENV_STATUS;
            date = order.end_date;
          }
          result.push({
            name: awv.name,
            version: awv.version,
            env: env.name.toUpperCase(),
            status: status,
            date: date,
            approvers: []
          });
        }
      }
    }
    return result;
  }
  render() {
    const results = this.getResultRows();
    return (
      <>
        <BreadcrumbsItem to={`/#/releases/${this.props.release.name}/details`}>
          {_('Info')}
        </BreadcrumbsItem>

        <Modal
          show={true}
          onHide={this.props.onHide}
          dialogClassName="putit-release-details-popup"
        >
          <Modal.Body>
            <AppTable.Table>
              <div className="header">
                <AppTable.Row>
                  <AppTable.Cell>
                    {this.props.release.name} {_('Info')}:
                  </AppTable.Cell>
                  <AppTable.Cell columnClassName="triplewidth">
                    Lorem Ipsum
                  </AppTable.Cell>
                  <AppTable.Cell>
                    {_('Total')} {results.length}
                  </AppTable.Cell>
                  <AppTable.Cell columnClassName="buttons-cell">
                    <div className="buttons pull-right">
                      <Button className="btn-white-short">
                        <span className="delete" />
                      </Button>

                      <Button className="btn-default-short">
                        <span className="save" />
                      </Button>
                    </div>{' '}
                  </AppTable.Cell>
                </AppTable.Row>
              </div>
              {results.map((result, index) => {
                return (
                  <ReleaseDetailsAppRow
                    key={index}
                    releaseAppName={result.name}
                    releaseAppVersion={result.version}
                    releaseAppDate={result.date}
                    releaseAppStatus={result.status}
                    releaseAppEnv={result.env}
                    releaseAppApprovers={result.approvers}
                  />
                );
              })}
            </AppTable.Table>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

ReleaseDetailsPopup.propTypes = {
  release: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired
};
