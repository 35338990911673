/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import underscore from 'underscore';

import { AppTable } from 'src/components';

import { ReleaseNameCell } from './ReleaseNameCell';
import { ReleaseOrderDetailsPopup } from './ReleaseOrderDetailsPopup';
import { ReleaseOrderStatusCell } from './ReleaseOrderStatusCell';
import { ReleaseDetailsPopup } from './ReleaseDetailsPopup';

export class ReleaseTable extends React.Component {
  constructor(props) {
    super(props);

    this.popupAnchorRef = React.createRef();

    this.onActionButtonPlusClick = this.onActionButtonPlusClick.bind(this);
    this.onReleaseDetailsClick = this.onReleaseDetailsClick.bind(this);
    this.onHideReleaseDetailsClick = this.onHideReleaseDetailsClick.bind(this);
    this.state = {
      popupVisible: false,
      detailsPopupVisible: false
    };
    this.sortReleaseOrders = this.sortReleaseOrders.bind(this);
  }
  sortReleaseOrders() {
    this.props.release.envs.forEach(e => {
      e.orders.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
    });
  }
  onActionButtonPlusClick() {
    alert('not implemented');
  }
  onReleaseDetailsClick() {
    this.props.onReleaseDetailsClick(this.props.release);
    this.setState({ detailsPopupVisible: true });
  }
  onHideReleaseDetailsClick() {
    this.setState({ detailsPopupVisible: false });
    this.props.onPopupOverlayClick();
  }
  expandedOrderCoords() {
    let expandedOrderEnvIndex = -1;
    let expandedOrderIndex = -1;
    if (this.props.expandedEnvName && this.props.expandedReleaseOrderName) {
      for (
        let envIndex = 0;
        envIndex < this.props.release.envs.length;
        envIndex++
      ) {
        const env = this.props.release.envs[envIndex];
        if (env.name === this.props.expandedEnvName) {
          for (
            let orderIndex = 0;
            orderIndex < env.orders.length;
            orderIndex++
          ) {
            if (
              env.orders[orderIndex].name ===
              this.props.expandedReleaseOrderName
            ) {
              expandedOrderIndex = orderIndex;
              break;
            }
          }

          if (expandedOrderIndex !== -1) {
            expandedOrderEnvIndex = envIndex;
            break;
          }
        }
      }
    }

    return {
      env: expandedOrderEnvIndex,
      order: expandedOrderIndex
    };
  }
  componentDidMount() {
    const expandedOrderCoords = this.expandedOrderCoords();
    if (expandedOrderCoords.env !== -1 && expandedOrderCoords.order !== -1) {
      this.setState({ popupVisible: true });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.expandedReleaseOrderName !== this.props.expandedReleaseOrderName
    ) {
      const expandedOrderCoords = this.expandedOrderCoords();
      if (expandedOrderCoords.env !== -1 && expandedOrderCoords.order !== -1) {
        this.setState({ popupVisible: true });
      } else {
        this.setState({ popupVisible: false });
      }
    }
  }
  render() {
    this.sortReleaseOrders();
    const expandedOrderCoords = this.expandedOrderCoords();
    let expandedOrder = null;
    if (expandedOrderCoords.env !== -1 && expandedOrderCoords.order !== -1) {
      expandedOrder = this.props.release.envs[expandedOrderCoords.env].orders[
        expandedOrderCoords.order
      ];
    }

    return (
      <React.Fragment>
        <AppTable.Table className={this.props.release.status}>
          <AppTable.Head>
            <AppTable.AppNameHeader />

            <AppTable.ScrollView>
              {underscore.map(this.props.release.envs, (env, index) => {
                return (
                  <AppTable.Header key={env.id}>{env.name}</AppTable.Header>
                );
              })}
            </AppTable.ScrollView>
          </AppTable.Head>

          {underscore.map(
            underscore.range(0, this.props.release.max_orders_count),
            orderIndex => {
              return (
                <AppTable.Row key={orderIndex}>
                  {orderIndex === 0 && (
                    <>
                      <ReleaseNameCell
                        release={this.props.release}
                        onReleaseDetailsClick={this.onReleaseDetailsClick}
                      />

                      <AppTable.ScrollViewWithButtons
                        onActionButtonPlusClick={this.onActionButtonPlusClick}
                      >
                        {underscore.map(
                          this.props.release.envs,
                          (env, index) => {
                            return (
                              <ReleaseOrderStatusCell
                                key={env.id}
                                env={env}
                                order={env.orders[orderIndex]}
                                onClick={
                                  this.props.onReleaseOrderStatusCellClick
                                }
                              />
                            );
                          }
                        )}
                      </AppTable.ScrollViewWithButtons>
                    </>
                  )}

                  {orderIndex > 0 && (
                    <>
                      <AppTable.RowHeaderCell />

                      <AppTable.ScrollView>
                        {underscore.map(
                          this.props.release.envs,
                          (env, index) => {
                            return (
                              <ReleaseOrderStatusCell
                                key={env.id}
                                env={env}
                                order={env.orders[orderIndex]}
                                onClick={
                                  this.props.onReleaseOrderStatusCellClick
                                }
                              />
                            );
                          }
                        )}
                      </AppTable.ScrollView>
                      <AppTable.Actions />
                    </>
                  )}
                </AppTable.Row>
              );
            }
          )}
        </AppTable.Table>
        {expandedOrder && (
          <ReleaseOrderDetailsPopup
            anchor={this.popupAnchorRef}
            order={expandedOrder}
            release={this.props.release}
            visible={this.state.popupVisible}
            onOverlayClick={this.props.onPopupOverlayClick}
          />
        )}
        {this.state.detailsPopupVisible && (
          <ReleaseDetailsPopup
            release={this.props.release}
            onHide={this.onHideReleaseDetailsClick}
          />
        )}
      </React.Fragment>
    );
  }
}

ReleaseTable.propTypes = {
  expandedEnvName: PropTypes.string,
  expandedReleaseOrderName: PropTypes.string,
  release: PropTypes.object.isRequired,
  onPopupOverlayClick: PropTypes.func.isRequired,
  onReleaseOrderStatusCellClick: PropTypes.func.isRequired,
  onReleaseDetailsClick: PropTypes.func.isRequired
};
