/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDocumentTitle } from 'redux-doctitle';

import Config from 'src/config';
import * as NotificationsActions from 'src/blueprints/core/actions/notifications';
import * as SettingsActions from 'src/blueprints/core/actions/settings';
import {
  Alert,
  Button,
  Breadcrumb,
  Col,
  ControlLabel,
  FormGroup,
  FormControl,
  Panel,
  Row,
  Select
} from 'src/components';
import * as requests from 'src/lib/requests';

class NotificationsPanel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onKindChange = this.onKindChange.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onSubtitleChange = this.onSubtitleChange.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onPresentButtonClick = this.onPresentButtonClick.bind(this);

    this.state = {
      kind: '',
      title: '',
      subtitle: '',
      text: ''
    };
  }
  onKindChange(newKind) {
    this.setState({ kind: newKind });
  }
  onTitleChange(event) {
    this.setState({ title: event.target.value });
  }
  onSubtitleChange(event) {
    this.setState({ subtitle: event.target.value });
  }
  onTextChange(event) {
    this.setState({ text: event.target.value });
  }
  onPresentButtonClick(event) {
    event.stopPropagation();
    event.preventDefault();

    this.props.presentNotification(
      this.state.text,
      this.state.kind,
      this.state.title,
      this.state.subtitle
    );
  }
  render() {
    const footerButton = (
      <Button
        bsSize="small"
        bsStyle="primary"
        onClick={this.onPresentButtonClick}
      >
        Present
      </Button>
    );

    const panelTitle = <h3>Notifications</h3>;

    return (
      <Panel footer={footerButton} header={panelTitle}>
        <form>
          <FormGroup controlId="kind">
            <ControlLabel>Kind</ControlLabel>
            <Select
              placeholder="Kind"
              value={this.state.kind}
              onChange={this.onKindChange}
            >
              <Select.Option value="">Default</Select.Option>
              <Select.Option value="success">Success</Select.Option>
              <Select.Option value="warning">Warning</Select.Option>
              <Select.Option value="danger">Danger</Select.Option>
              <Select.Option value="info">Info</Select.Option>
            </Select>
          </FormGroup>

          <FormGroup controlId="title">
            <FormControl
              id="title"
              type="text"
              placeholder="Title"
              value={this.state.title}
              onChange={this.onTitleChange}
            />
          </FormGroup>

          <FormGroup controlId="subtitle">
            <FormControl
              id="subtitle"
              type="text"
              placeholder="Subtitle"
              value={this.state.subtitle}
              onChange={this.onSubtitleChange}
            />
          </FormGroup>

          <FormGroup controlId="text">
            <FormControl
              id="text"
              type="text"
              placeholder="Text"
              value={this.state.text}
              onChange={this.onTextChange}
            />
          </FormGroup>
        </form>
      </Panel>
    );
  }
}

NotificationsPanel.propTypes = {
  presentNotification: PropTypes.func.isRequired
};

class RequestsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.onMethodChange = this.onMethodChange.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
    this.onBodyChange = this.onBodyChange.bind(this);
    this.onSendButtonClick = this.onSendButtonClick.bind(this);
    this.onRequestSuccess = this.onRequestSuccess.bind(this);
    this.onRequestError = this.onRequestError.bind(this);

    this.state = {
      method: '',
      url: '',
      body: '',
      lastResponse: null,
      lastError: null
    };
  }
  onMethodChange(newMethod) {
    this.setState({ method: newMethod });
  }
  onUrlChange(event) {
    this.setState({ url: event.target.value });
  }
  onBodyChange(event) {
    this.setState({ body: event.target.value });
  }
  onSendButtonClick(event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.state.method === 'get') {
      requests.get(this.state.url, this.onRequestSuccess, this.onRequestError);
    } else if (this.state.method === 'post') {
      requests.post(
        this.state.url,
        JSON.parse(this.state.body),
        this.onRequestSuccess,
        this.onRequestError
      );
    } else {
      throw new Error('Unknown method: ' + this.state.method);
    }
  }
  onRequestSuccess(data) {
    this.setState({
      lastResponse: JSON.stringify(data),
      lastError: null
    });
  }
  onRequestError(error) {
    this.setState({
      lastResponse: null,
      lastError: error
    });
  }
  render() {
    const footerButton = (
      <Button bsSize="small" bsStyle="primary" onClick={this.onSendButtonClick}>
        Send
      </Button>
    );

    const panelTitle = <h3>Requests</h3>;

    return (
      <Panel footer={footerButton} header={panelTitle}>
        <form>
          <FormGroup controlId="method">
            <ControlLabel>Method</ControlLabel>
            <Select value={this.state.method} onChange={this.onMethodChange}>
              <Select.Option value="">-- select method --</Select.Option>
              <Select.Option value="get">GET</Select.Option>
              <Select.Option value="post">POST</Select.Option>
            </Select>
          </FormGroup>

          <FormGroup controlId="url">
            <FormControl
              id="url"
              type="text"
              placeholder="URL"
              value={this.state.url}
              onChange={this.onUrlChange}
            />
          </FormGroup>

          <div className="form-group">
            <FormControl
              id="body"
              componentClass="textarea"
              disabled={this.state.method !== 'post'}
              placeholder="Body"
              value={this.state.body}
              onChange={this.onBodyChange}
            />
          </div>
        </form>

        {this.state.lastResponse && (
          <Alert bsStyle="success">
            <p>
              <b>Last response</b>
            </p>
            <textarea
              defaultValue={this.state.lastResponse}
              readOnly
              rows={3}
              style={{ width: '100%' }}
            />
          </Alert>
        )}

        {this.state.lastError && (
          <Alert bsStyle="danger">
            <p>
              <b>Last error</b>: {this.state.lastError}
            </p>
          </Alert>
        )}
      </Panel>
    );
  }
}

class SettingsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.onLangChange = this.onLangChange.bind(this);
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);

    this.state = {
      lang: this.props.lang
    };
  }
  onLangChange(newLang) {
    this.setState({ lang: newLang });
  }
  onSaveButtonClick() {
    if (this.state.lang !== this.props.lang) {
      this.props.setLang(this.state.lang);
    }
  }
  render() {
    const footerButton = (
      <Button bsSize="small" bsStyle="primary" onClick={this.onSaveButtonClick}>
        Save
      </Button>
    );

    const panelTitle = <h3>Settings</h3>;

    return (
      <Panel footer={footerButton} header={panelTitle}>
        <form>
          <FormGroup controlId="method">
            <ControlLabel>Language</ControlLabel>
            <Select value={this.state.lang} onChange={this.onLangChange}>
              <Select.Option value="">-- default --</Select.Option>
              <Select.Option value="en">English</Select.Option>
              <Select.Option value="pl">Polish</Select.Option>
            </Select>
          </FormGroup>
        </form>
      </Panel>
    );
  }
}

SettingsPanel.propTypes = {
  lang: PropTypes.string.isRequired,
  setLang: PropTypes.func.isRequired
};

export class DevConsoleView extends React.Component {
  componentDidMount() {
    this.props.actions.setDocumentTitle('Dev Console');
  }
  render() {
    return (
      <div id="DevConsoleView">
        <Breadcrumb>
          <Breadcrumb.Item active>Dev Console</Breadcrumb.Item>
          <Breadcrumb.Item active>
            Version: {Config.appVersion()}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row>
          <Col md={4}>
            <NotificationsPanel
              presentNotification={this.props.actions.presentNotification}
            />
          </Col>
          <Col md={4}>
            <RequestsPanel />
          </Col>
          <Col md={4}>
            <SettingsPanel
              lang={this.props.lang}
              setLang={this.props.actions.setLang}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

DevConsoleView.propTypes = {
  actions: PropTypes.shape({
    presentNotification: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired,
    setLang: PropTypes.func.isRequired
  })
};

const mapStateToProps = (state, props) => {
  return {
    lang: state.settings.get('lang')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      presentNotification: NotificationsActions.present,
      setDocumentTitle: setDocumentTitle,
      setLang: SettingsActions.setLang
    },
    dispatch
  );

  return { actions: actions };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DevConsoleView);

export default reduxContainer;
