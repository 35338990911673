/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import underscore from 'underscore';

import { Button, Form, Glyphicon } from 'src/components';
import {
  RE_APP_ENV_NAME,
  RE_AWS_TAGS,
  RE_USER_NAME,
  USER_NAME_MAX_LENGTH
} from 'src/lib/defs';
import _ from 'src/lib/i18n';

import * as SetupWizardActions from '../actions/setup_wizard';
import { EnvEditor } from '../components';
import { DEFAULT_ENV_VALIDATION_STATE, ENV_MODES } from '../defs';

export class EnvsPageView extends React.Component {
  constructor(props) {
    super(props);
    this.onAddEnvButtonClick = this.onAddEnvButtonClick.bind(this);

    this.state = {
      validationStates: this.props.envs.map(() => {
        return DEFAULT_ENV_VALIDATION_STATE;
      })
    };
  }
  componentDidMount() {
    this.props.actions.enableBack();

    const allValid = this.validateAll(this.props.envs).every(value => {
      return value.get('name') && value.get('user');
    });

    if (allValid) {
      this.props.actions.enableNext();
    } else {
      this.props.actions.disableNext();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.envs !== nextProps.envs) {
      const validationStates = this.validateAll(nextProps.envs);
      const allValid = validationStates.every(value => {
        return (
          value.get('name') &&
          value.get('user') &&
          value.get('aws_tags') &&
          value.get('mode')
        );
      });

      if (allValid) {
        this.props.actions.enableNext();
      } else {
        this.props.actions.disableNext();
      }

      if (this.props.envs.size >= nextProps.envs.size) {
        this.setState({ validationStates: validationStates });
      }
    }
  }
  validateAll(envs) {
    return envs.map((env, index) => {
      let result = DEFAULT_ENV_VALIDATION_STATE;

      result = result.set(
        'name',
        typeof env.get('name') === 'string' &&
          RE_APP_ENV_NAME.test(env.get('name'))
      );

      result = result.set(
        'mode',
        typeof env.get('mode') === 'string' &&
          underscore.contains(underscore.values(ENV_MODES), env.get('mode'))
      );

      if (env.get('mode') !== ENV_MODES.NONE) {
        result = result.set(
          'user',
          typeof env.get('user') === 'string' &&
            env.get('user').length < USER_NAME_MAX_LENGTH &&
            RE_USER_NAME.test(env.get('user'))
        );
      }

      if (env.get('mode') === ENV_MODES.TAGS) {
        result = result.set(
          'aws_tags',
          typeof env.get('aws_tags') === 'string' &&
            RE_AWS_TAGS.test(env.get('aws_tags'))
        );
      }

      return result;
    });
  }
  onAddEnvButtonClick(event) {
    this.props.actions.addEnv('', '', '', ENV_MODES.HOST);
  }
  render() {
    return (
      <div id="EnvsPageView">
        <h4>{_('Set up environments')}</h4>
        <p>
          {_(
            'Specify at least one environment for the application and click Next.'
          )}
        </p>
        <Form>
          {underscore.map(this.props.envs.toJS(), (env, index) => {
            return (
              <EnvEditor
                key={index}
                index={index}
                canRemove={this.props.envs.size > 1}
                name={env.name}
                user={env.user}
                awsTags={env.aws_tags}
                mode={env.mode}
                removeEnv={this.props.actions.removeEnv}
                setEnvName={this.props.actions.setEnvName}
                setEnvUser={this.props.actions.setEnvUser}
                setEnvAWSTags={this.props.actions.setEnvAWSTags}
                setEnvMode={this.props.actions.setEnvMode}
                validationState={this.state.validationStates.get(index)}
              />
            );
          })}
        </Form>
        <div className="text-right">
          <Button bsStyle="success" onClick={this.onAddEnvButtonClick}>
            <Glyphicon glyph="plus" /> {_('Add new')}
          </Button>
        </div>
      </div>
    );
  }
}

EnvsPageView.propTypes = {
  actions: PropTypes.shape({
    addEnv: PropTypes.func.isRequired,
    disableNext: PropTypes.func.isRequired,
    enableBack: PropTypes.func.isRequired,
    enableNext: PropTypes.func.isRequired,
    removeEnv: PropTypes.func.isRequired,
    setEnvName: PropTypes.func.isRequired,
    setEnvUser: PropTypes.func.isRequired,
    setEnvAWSTags: PropTypes.func.isRequired,
    setEnvMode: PropTypes.func.isRequired
  }),
  envs: PropTypes.instanceOf(List)
};

const mapStateToProps = (state, props) => {
  return {
    envs: state.setupWizard.get('envs')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      addEnv: SetupWizardActions.addEnv,
      disableNext: SetupWizardActions.disableNext,
      enableBack: SetupWizardActions.enableBack,
      enableNext: SetupWizardActions.enableNext,
      removeEnv: SetupWizardActions.removeEnv,
      setEnvName: SetupWizardActions.setEnvName,
      setEnvUser: SetupWizardActions.setEnvUser,
      setEnvAWSTags: SetupWizardActions.setEnvAWSTags,
      setEnvMode: SetupWizardActions.setEnvMode
    },
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EnvsPageView);

export default reduxContainer;
