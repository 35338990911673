/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import sinon from 'sinon';
import underscore from 'underscore';
import URI from 'urijs';

import DataGetApplicationIncludeEnvs from './data/GetApplicationIncludeEnvs.js';
import DataGetApplicationReleasesByEnvs from './data/GetApplicationReleasesByEnvs.js';
import DataGetApplicationVersionArtifacts from './data/GetApplicationVersionArtifacts.js';
import DataGetOrders from './data/GetOrders.js';
import DataGetReleases from './data/GetReleases.js';
import MessagesEN from 'po/en/en.json';
import MessagesPL from 'po/pl/pl.json';

const DataGetApplication = underscore.map(
  DataGetApplicationIncludeEnvs,
  (application, index) => {
    return underscore.pick(application, (value, key, object) => {
      return key !== 'envs';
    });
  }
);

const DEFAULT_OPTIONS = {
  respondImmediately: true,
  simulateError: false
};

let backendOptions = {};

export let server = null;

const respondWith = (xhr, response) => {
  if (xhr.readyState === 4) {
    return;
  }

  if (backendOptions.simulateError) {
    xhr.respond(500, {}, '');
  } else {
    const headers = response.headers || {
      'Content-Type': 'application/json;charset=utf-8'
    };
    const body = JSON.stringify(response.body);

    xhr.respond(response.status, headers, body);
  }
};

const respondWithData = (xhr, data) => {
  respondWith(xhr, { body: data, status: 200 });
};

const getApplicationEnvCredentials = (xhr, app, env) => {
  let response = { status: 404 };

  if (env !== 'uat') {
    response = {
      body: {
        id: 11,
        name: 'some_loser_credential',
        sshkey_name: 'some_loser_sshkey',
        depuser_name: 'some_loser'
      },
      status: 200
    };
  }

  respondWith(xhr, response);
};

const getMessages = (xhr, lang) => {
  let response = { status: 404 };

  if (lang === 'en') {
    response = {
      body: MessagesEN,
      status: 200
    };
  } else if (lang === 'pl') {
    response = {
      body: MessagesPL,
      status: 200
    };
  }

  respondWith(xhr, response);
};

const getApplicationEnvReleasesCount = (xhr, app, env) => {
  let response = { status: 404 };

  if (env !== 'uat') {
    response = {
      body: {
        count: 16
      },
      status: 200
    };
  }

  respondWith(xhr, response);
};

const getPostApplicationEnvs = (xhr, applicationName) => {
  if (xhr.method === 'POST') {
    respondWith(xhr, { status: 201, body: {} });
  } else if (xhr.method === 'GET') {
    const applications = underscore.filter(
      DataGetApplicationIncludeEnvs,
      app => {
        return app.name === applicationName;
      }
    );

    if (applications.length === 0 || applications[0].envs.length === 0) {
      return respondWith(xhr, { status: 404, body: {} });
    } else {
      return respondWith(xhr, { status: 200, body: applications[0].envs });
    }
  } else {
    respondWith(405, {}, '');
  }
};

const getApplicationOrdersUpcoming = xhr => {
  const response = {
    body: {
      count: 2,
      date: '2017-04-18T20:09:12.667Z'
    },
    status: 200
  };

  respondWith(xhr, response);
};

const getSettings = xhr => {
  const response = {
    body: {
      putit_core_url: 'http://localhost:3000/',
      putit_auth_url: 'http://localhost:3000/api/users/',
      needs_setup_wizard: 'false'
    },
    status: 200
  };

  respondWith(xhr, response);
};

const getStatus = (xhr, app, env) => {
  let response = { status: 404 };

  if (env !== 'uat') {
    response = {
      body: {
        status: 'success',
        version: '1.0'
      },
      status: 200
    };
  }

  respondWith(xhr, response);
};

const _getOrdersForApplication = (xhr, application, queryParams = '') => {
  const decodedApplication = decodeURIComponent(application);
  let orders = underscore.filter(DataGetOrders, o => {
    return underscore.any(
      o.applications_with_versions,
      avw => avw.application_with_version.name === decodedApplication
    );
  });

  if (!queryParams.includes('includeClosedReleases=true')) {
    orders = underscore.filter(orders, o => o.release.status === 'open');
  }

  if (queryParams.q) {
    orders = underscore.filter(
      orders,
      o =>
        o.name.toLowerCase().includes(queryParams.q.toLowerCase()) ||
        o.release.name.toLowerCase().includes(queryParams.q.toLowerCase())
    );
  }

  return orders;
};

const getOrdersForApplication = (xhr, application, queryParams = '') => {
  respondWithData(xhr, _getOrdersForApplication(xhr, application, queryParams));
};

const getOrdersForEnv = (xhr, application, envName, queryParams = '') => {
  let orders = _getOrdersForApplication(xhr, application, queryParams);

  orders = underscore.filter(orders, o => {
    return underscore.any(o.applications_with_versions, avw =>
      underscore.any(avw.envs, { name: envName })
    );
  });

  respondWithData(xhr, orders);
};

const postAuthLogin = xhr => {
  const response = {
    body: { token: 'spam' },
    status: 200
  };

  const auth = JSON.parse(xhr.requestBody);
  if (auth.user.email === 'error') {
    response.body = '';
    response.status = 401;
  }

  respondWith(xhr, response);
};

const postAuthReset = xhr => {
  const response = {
    body: '',
    status: 200
  };

  const auth = JSON.parse(xhr.requestBody);
  if (auth.user.email === 'error') {
    response.body = '';
    response.status = 400;
  }

  respondWith(xhr, response);
};

const postAuthSignup = xhr => {
  const response = {
    body: '',
    status: 200
  };

  const auth = JSON.parse(xhr.requestBody);
  if (auth.user.email === 'error') {
    response.body = '';
    response.status = 400;
  }

  respondWith(xhr, response);
};

const postPutAuthResetCreateNewPassword = xhr => {
  if (xhr.method === 'POST') {
    return postAuthReset(xhr);
  } else if (xhr.method === 'PUT') {
    return putAuthCreateNewPassword(xhr);
  } else {
    respondWith(405, {}, '');
  }
};

const putAuthCreateNewPassword = (xhr, request) => {
  const response = {
    body: '',
    status: 200
  };

  const auth = JSON.parse(xhr.requestBody);
  if (auth.user.reset_password_token === 'error') {
    response.body = '';
    response.status = 400;
  }

  respondWith(xhr, response);
};

const postSetupWizardApply = (xhr, request) => {
  const response = {
    status: 200,
    body: {
      status: 'success',
      results: {
        application: [{ application_id: 1 }],
        envs: [{ env_id: 1 }, { env_id: 2 }, { env_id: 3 }],
        hosts: [{ host_id: 1 }, { host_id: 2 }, { host_id: 3 }],
        credentials: [
          { credential_id: 1 },
          { credential_id: 2 },
          { credential_id: 3 }
        ]
      }
    }
  };

  const payload = JSON.parse(xhr.requestBody);
  if (payload.application.name === 'Conflict') {
    response.status = 409;
    response.body = {
      status: 'conflict',
      msg: 'Conflict',
      conflicts: {
        application: ['name'],
        envs: ['dev'],
        hosts: ['dev.myapp.lan'],
        credentials: ['ssh_key', 'user', 'credential']
      }
    };
  } else if (payload.application.name === 'Error') {
    response.status = 400;
    response.body = {
      status: 'error',
      msg: 'Validation error',
      errors: {
        application: [],
        envs: [],
        hosts: [
          { fqdn: 'dev.myapp.lan', error: 'FQDN Error' },
          { ip: '127.0.0.1', error: 'IP Error' }
        ],
        credentials: []
      }
    };
  }

  respondWith(xhr, response);
};

export const install = options => {
  backendOptions = underscore.extendOwn({}, DEFAULT_OPTIONS, options);

  server = sinon.createFakeServer({
    autoRespond: true,
    autoRespondTimeout: 300,
    respondImmediately: backendOptions.respondImmediately
  });

  server.respondWith('POST', '/application', xhr => {
    respondWith(xhr, { status: 201, body: {} });
  });

  server.respondWith('GET', /\/application\?include=envs/, xhr => {
    respondWithData(xhr, DataGetApplicationIncludeEnvs);
  });
  server.respondWith('GET', '/application/', xhr => {
    respondWithData(xhr, DataGetApplication);
  });
  server.respondWith(
    'GET',
    /\/application\/(.+?)\/envs\/(.+?)\/releases\/count$/,
    getApplicationEnvReleasesCount
  );
  server.respondWith(
    'GET',
    /\/application\/(.+?)\/envs$/,
    getPostApplicationEnvs
  );
  server.respondWith(
    'GET',
    /\/application\/(.+?)\/envs\/(.+?)\/credential$/,
    getApplicationEnvCredentials
  );
  server.respondWith(
    'POST',
    /\/application\/(.+?)\/envs$/,
    getPostApplicationEnvs
  );
  server.respondWith('GET', /\/application\/.+?\/results\/by_env$/, xhr => {
    respondWithData(xhr, DataGetApplicationReleasesByEnvs);
  });
  server.respondWith(
    'GET',
    /\/application\/.+?\/orders\/upcoming$/,
    getApplicationOrdersUpcoming
  );
  server.respondWith(
    'GET',
    /\/application\/.+?\/versions\/.+?\/artifacts$/,
    xhr => {
      respondWithData(xhr, DataGetApplicationVersionArtifacts);
    }
  );
  server.respondWith('POST', '/depuser', xhr => {
    respondWith(xhr, { status: 201, body: { id: 1 } });
  });
  server.respondWith('POST', /\/depuser\/.+?\/sshkeys$/, xhr => {
    respondWith(xhr, { status: 201, body: { id: 1 } });
  });
  server.respondWith('POST', /\/application\/.+?\/envs/, xhr => {
    respondWith(xhr, { status: 201, body: { env_id: 1 } });
  });
  server.respondWith(
    'PUT',
    /\/application\/.+?\/envs\/.+?\/credential\/.+?$/,
    xhr => {
      respondWith(xhr, { status: 201, body: { status: 'ok' } });
    }
  );
  server.respondWith(
    'PUT',
    /\/application\/.+?\/envs\/.+?\/host\/.+?\/credential\/.+?$/,
    xhr => {
      respondWith(xhr, { status: 201, body: { status: 'ok' } });
    }
  );
  server.respondWith('POST', /\/application\/.+?\/envs\/.+?\/hosts$/, xhr => {
    respondWith(xhr, { status: 201, body: { host_id: 1 } });
  });
  server.respondWith(
    'GET',
    /\/application\/(.+)\/orders(\?.*)?$/,
    getOrdersForApplication
  );
  server.respondWith(
    'GET',
    /\/application\/(.+)\/envs\/(.+)\/orders(\?.*)?$/,
    getOrdersForEnv
  );
  server.respondWith('GET', /messages\/(.+?)\.json$/, getMessages);
  server.respondWith('GET', /\/orders\?(.+)/, (xhr, queryParams) => {
    const params = URI.parseQuery(queryParams);
    let orders = DataGetOrders;
    if (params.release) {
      orders = underscore.filter(
        orders,
        o => o.release.name === params.release
      );
    }
    if (!params.includeClosedReleases) {
      orders = underscore.filter(orders, o => o.release.status === 'open');
    }
    if (params.q) {
      orders = underscore.filter(
        orders,
        o =>
          o.name.toLowerCase().includes(params.q.toLowerCase()) ||
          o.release.name.toLowerCase().includes(params.q.toLowerCase())
      );
    }
    respondWithData(xhr, orders);
  });
  server.respondWith('GET', '/orders', xhr => {
    const orders = underscore.filter(
      DataGetOrders,
      o => o.release.status === 'open'
    );
    respondWithData(xhr, orders);
  });
  server.respondWith('GET', /\/release\?(.+)$/, (xhr, queryParams = {}) => {
    respondWithData(xhr, DataGetReleases);
  });
  server.respondWith('GET', /\/release$/, xhr => {
    const releases = underscore.filter(
      DataGetReleases,
      r => r.status === 'open'
    );
    respondWithData(xhr, releases);
  });
  server.respondWith('POST', '/sshkey', xhr => {
    respondWith(xhr, { status: 201, body: { id: 1 } });
  });
  server.respondWith('GET', /\/settings/, getSettings);
  server.respondWith('POST', /\/settings/, xhr => {
    respondWith(xhr, { status: 200, body: { status: 'ok' } });
  });
  server.respondWith('POST', '/setup_wizard/apply', postSetupWizardApply);
  server.respondWith('GET', /\/status\/(.+?)\/(.+?)$/, getStatus);
  server.respondWith('POST', /\/users/, postAuthSignup);
  server.respondWith('GET', /\/users\/check\?v=[0-9]+$/, xhr => {
    respondWith(xhr, { status: 200, body: { status: 'ok' } });
  });
  server.respondWith(
    'POST',
    /\/users\/password/,
    postPutAuthResetCreateNewPassword
  );
  server.respondWith(
    'PUT',
    /\/users\/password/,
    postPutAuthResetCreateNewPassword
  );
  server.respondWith('POST', /\/users\/sign_in/, postAuthLogin);
  server.respondWith('DELETE', '/users/sign_out', xhr => {
    respondWith(xhr, { status: 200, body: { status: 'ok' } });
  });
  server.respondWith('GET', '/_testing_/auth_error', xhr => {
    respondWith(xhr, { status: 401, body: {} });
  });
};

export const setSimulateError = newFlag => {
  backendOptions.simulateError = underscore.isBoolean(newFlag)
    ? newFlag
    : false;
};

export const uninstall = () => {
  server.restore();
};
