/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import URI from 'urijs';

import Config from 'src/config';
import { get, post } from 'src/lib/requests';

export const load = (onSuccess, onError) => {
  const requestURL = new URI.joinPaths(
    Config.get('api_base_url'),
    '/settings/'
  );

  return get(requestURL, onSuccess, onError);
};

export const update = (update, onSuccess, onError) => {
  const requestURL = new URI.joinPaths(
    Config.get('api_base_url'),
    '/settings/'
  );

  return post(requestURL, update, onSuccess, onError);
};
