/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as AppDashboardDataSources from '../data_sources';

import { APP_DASHBOARD_ACTIONS } from '../defs';

export const cleanup = () => {
  return {
    type: APP_DASHBOARD_ACTIONS.CLEANUP
  };
};

export const loadData = (dispatch, application) => {
  const dataSource = new AppDashboardDataSources.AppDashboardDataSource(
    dispatch,
    application
  );
  dataSource.load();

  return {
    type: APP_DASHBOARD_ACTIONS.LOAD_START
  };
};

export const setEnvs = envs => {
  return {
    type: APP_DASHBOARD_ACTIONS.SET_ENVS,
    envs: envs
  };
};

export const setStatuses = statuses => {
  return {
    type: APP_DASHBOARD_ACTIONS.SET_STATUSES,
    statuses: statuses
  };
};

export const setArtifacts = artifacts => {
  return {
    type: APP_DASHBOARD_ACTIONS.SET_ARTIFACTS,
    artifacts: artifacts
  };
};

export const setCredentials = credentials => {
  return {
    type: APP_DASHBOARD_ACTIONS.SET_CREDENTIALS,
    credentials: credentials
  };
};

export const setReleaseCounts = releaseCounts => {
  return {
    type: APP_DASHBOARD_ACTIONS.SET_RELEASE_COUNTS,
    releaseCounts: releaseCounts
  };
};

export const setReleaseStatusCounts = releaseStatusCounts => {
  return {
    type: APP_DASHBOARD_ACTIONS.SET_RELEASE_STATUS_COUNTS,
    releaseStatusCounts: releaseStatusCounts
  };
};

export const setUpcomingReleases = upcomingReleases => {
  return {
    type: APP_DASHBOARD_ACTIONS.SET_UPCOMING_RELEASES,
    upcomingReleases: upcomingReleases
  };
};

export const onLoadSuccess = () => {
  return {
    type: APP_DASHBOARD_ACTIONS.LOAD_SUCCESS
  };
};

export const onLoadError = error => {
  return {
    type: APP_DASHBOARD_ACTIONS.LOAD_ERROR,
    error: error
  };
};
