/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';

import { ScrollView } from 'src/components/AppTable/ScrollView';

export class Head extends React.Component {
  render() {
    return (
      <div className="head">
        {React.Children.map(this.props.children || null, child => {
          if (child && child.type === ScrollView) {
            return React.cloneElement(child, {
              ref: this.context.scrollViewRef
            });
          }

          return child;
        })}
      </div>
    );
  }
}

Head.contextTypes = {
  scrollViewRef: PropTypes.func
};
