/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { List, Map, fromJS } from 'immutable';

import {
  DEFAULT_ERRORS_STATE,
  ENV_MODES,
  SETUP_WIZARD_ACTIONS,
  SETUP_WIZARD_APPLY_STATUS
} from '../defs';

const DEFAULT_STATE = Map({
  appName: '',
  applyConflicts: DEFAULT_ERRORS_STATE,
  applyErrors: DEFAULT_ERRORS_STATE,
  applyStatus: Map({
    setup: SETUP_WIZARD_APPLY_STATUS.WAITING,
    settings: SETUP_WIZARD_APPLY_STATUS.WAITING
  }),
  backEnabled: true,
  backTitle: '',
  envs: List([Map({ name: '', user: '', aws_tags: '', mode: ENV_MODES.HOST })]),
  hosts: List([Map({ env: -1, name: '', ip: '' })]),
  nextEnabled: true,
  nextTitle: ''
});

const handleApplyError = (state, action) => {
  let errorStatus = SETUP_WIZARD_APPLY_STATUS.ERROR;
  let errorsKey = 'applyErrors';

  if (action.error === 'Conflict') {
    errorStatus = SETUP_WIZARD_APPLY_STATUS.CONFLICT;
    errorsKey = 'applyConflicts';
  }

  let errors = DEFAULT_ERRORS_STATE;
  if (action.errors) {
    errors = fromJS(action.errors);
  }

  return state
    .setIn(['applyStatus', 'setup'], errorStatus)
    .set('error', action.error)
    .set(errorsKey, errors);
};

const setupWizardReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SETUP_WIZARD_ACTIONS.ENABLE_BACK:
      return state.set('backEnabled', true);

    case SETUP_WIZARD_ACTIONS.DISABLE_BACK:
      return state.set('backEnabled', false);

    case SETUP_WIZARD_ACTIONS.SET_BACK_TITLE:
      return state.set('backTitle', action.title);

    case SETUP_WIZARD_ACTIONS.ENABLE_NEXT:
      return state.set('nextEnabled', true);

    case SETUP_WIZARD_ACTIONS.DISABLE_NEXT:
      return state.set('nextEnabled', false);

    case SETUP_WIZARD_ACTIONS.SET_NEXT_TITLE:
      return state.set('nextTitle', action.title);

    case SETUP_WIZARD_ACTIONS.SET_APP_NAME:
      return state.set('appName', action.name);

    case SETUP_WIZARD_ACTIONS.ADD_ENV:
      return state.set(
        'envs',
        state.get('envs').push(
          Map({
            name: action.name,
            user: action.user,
            aws_tags: action.awsTags,
            mode: action.mode
          })
        )
      );

    case SETUP_WIZARD_ACTIONS.REMOVE_ENV:
      return state.set('envs', state.get('envs').remove(action.index));

    case SETUP_WIZARD_ACTIONS.SET_ENV_NAME:
      return state.set(
        'envs',
        state.get('envs').setIn([action.index, 'name'], action.name)
      );

    case SETUP_WIZARD_ACTIONS.SET_ENV_USER:
      return state.set(
        'envs',
        state.get('envs').setIn([action.index, 'user'], action.user)
      );

    case SETUP_WIZARD_ACTIONS.SET_ENV_AWS_TAGS:
      return state.set(
        'envs',
        state.get('envs').setIn([action.index, 'aws_tags'], action.awsTags)
      );

    case SETUP_WIZARD_ACTIONS.SET_ENV_MODE:
      return state.set(
        'envs',
        state.get('envs').setIn([action.index, 'mode'], action.mode)
      );

    case SETUP_WIZARD_ACTIONS.ADD_HOST:
      return state.set(
        'hosts',
        state.get('hosts').push(
          Map({
            env: action.env,
            ip: action.ip,
            name: action.name
          })
        )
      );

    case SETUP_WIZARD_ACTIONS.REMOVE_HOST:
      return state.set('hosts', state.get('hosts').remove(action.index));

    case SETUP_WIZARD_ACTIONS.SET_HOST_ENV:
      return state.set(
        'hosts',
        state.get('hosts').setIn([action.index, 'env'], action.env)
      );

    case SETUP_WIZARD_ACTIONS.SET_HOST_IP:
      return state.set(
        'hosts',
        state.get('hosts').setIn([action.index, 'ip'], action.ip)
      );

    case SETUP_WIZARD_ACTIONS.SET_HOST_NAME:
      return state.set(
        'hosts',
        state.get('hosts').setIn([action.index, 'name'], action.name)
      );

    case SETUP_WIZARD_ACTIONS.APPLY_START:
      return state.setIn(
        ['applyStatus', 'setup'],
        SETUP_WIZARD_APPLY_STATUS.APPLYING
      );

    case SETUP_WIZARD_ACTIONS.APPLY_SUCCESS:
      return state.setIn(
        ['applyStatus', 'setup'],
        SETUP_WIZARD_APPLY_STATUS.SUCCESS
      );

    case SETUP_WIZARD_ACTIONS.APPLY_ERROR:
      return handleApplyError(state, action);

    case SETUP_WIZARD_ACTIONS.APPLY_SETTINGS_START:
      return state.setIn(
        ['applyStatus', 'settings'],
        SETUP_WIZARD_APPLY_STATUS.APPLYING
      );

    case SETUP_WIZARD_ACTIONS.APPLY_SETTINGS_SUCCESS:
      return state.setIn(
        ['applyStatus', 'settings'],
        SETUP_WIZARD_APPLY_STATUS.SUCCESS
      );

    case SETUP_WIZARD_ACTIONS.APPLY_SETTINGS_ERROR:
      return state.setIn(['applyStatus', 'settings'], action.error);

    case SETUP_WIZARD_ACTIONS.CLEANUP:
      return DEFAULT_STATE;

    default:
      return state;
  }
};

export default setupWizardReducer;
