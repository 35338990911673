/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { List, Map, fromJS } from 'immutable';
import underscore from 'underscore';

import { ApplicationProcessor, StatusProcessor } from 'src/lib/data_processors';

import { DASHBOARD_ACTIONS } from '../defs';

const DEFAULT_STATE = Map({
  applications: List(),
  loading: false,
  error: null
});

export const processApplication = application => {
  const result = ApplicationProcessor(application);
  result.envs = underscore.map(result.envs, env => {
    return underscore.extendOwn({}, env, {
      status: undefined,
      load_error: undefined
    });
  });

  return fromJS(result);
};

const setEnvStatus = (application, envName, status) => {
  return application.set(
    'envs',
    application.get('envs').map((env, index) => {
      if (env.get('name') === envName) {
        return env.merge({
          status: StatusProcessor(status)
        });
      }

      return env;
    })
  );
};

const setEnvLoadError = (application, envName, error, statusCode) => {
  return application.set(
    'envs',
    application.get('envs').map((env, index) => {
      if (env.get('name') === envName) {
        return env.merge({
          status: statusCode === 404 ? {} : undefined,
          load_error: statusCode !== 404 ? error : undefined
        });
      }

      return env;
    })
  );
};

const dashboardReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case DASHBOARD_ACTIONS.CLEANUP:
      return DEFAULT_STATE;

    case DASHBOARD_ACTIONS.LIST_APPLICATIONS_START:
      return state.merge({
        loading: true,
        error: null
      });

    case DASHBOARD_ACTIONS.LIST_APPLICATIONS_ERROR:
      return state.merge({
        loading: false,
        error: action.error
      });

    case DASHBOARD_ACTIONS.LIST_APPLICATIONS_SUCCESS:
      return state.merge({
        loading: false,
        applications: List(
          underscore.map(action.applications, processApplication)
        )
      });

    case DASHBOARD_ACTIONS.LOAD_ENV_STATUS_SUCCESS:
      return state.set(
        'applications',
        state.get('applications').map((application, index) => {
          if (application.get('name') === action.application) {
            return setEnvStatus(application, action.env, action.status);
          }

          return application;
        })
      );

    case DASHBOARD_ACTIONS.LOAD_ENV_STATUS_ERROR:
      return state.set(
        'applications',
        state.get('applications').map((application, index) => {
          if (application.get('name') === action.application) {
            return setEnvLoadError(
              application,
              action.env,
              action.error,
              action.statusCode
            );
          }

          return application;
        })
      );

    default:
      return state;
  }
};

export default dashboardReducer;
