/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { AppTable, Button, SlashDateFormatter } from 'src/components';
import { ReleaseDetailsApproversRow } from './ReleaseDetailsApproversRow';
import {
  DEFAULT_ENV_STATUS,
  STATUS_TO_LABEL_STYLE,
  NO_VALUE_PLACEHOLDER
} from 'src/lib/defs';

export class ReleaseDetailsAppRow extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);

    this.state = {
      detailsExpanded: false
    };
  }
  onClick() {
    this.setState({ detailsExpanded: !this.state.detailsExpanded });
  }
  render() {
    return (
      <>
        <div className="no-border">
          <AppTable.Row>
            <AppTable.Cell>{this.props.releaseAppName}</AppTable.Cell>
            <AppTable.Cell>{this.props.releaseAppVersion}</AppTable.Cell>
            <AppTable.Cell>{this.props.releaseAppEnv}</AppTable.Cell>
            <AppTable.Cell>
              <SlashDateFormatter value={this.props.releaseAppDate} />
            </AppTable.Cell>
            <AppTable.Cell>
              <AppTable.LabelCell
                labelStyle={
                  STATUS_TO_LABEL_STYLE[this.props.releaseAppStatus] ||
                  DEFAULT_ENV_STATUS
                }
                title={this.props.releaseAppStatus}
                value={
                  this.props.releaseAppStatus === DEFAULT_ENV_STATUS
                    ? NO_VALUE_PLACEHOLDER
                    : this.props.releaseAppStatus.toUpperCase()
                }
              />
            </AppTable.Cell>
            <AppTable.Cell>
              <Button
                className="btn-white-short"
                wrapperClassName="no-padding"
                onClick={this.onClick}
              >
                <span className="details" />
              </Button>
            </AppTable.Cell>
          </AppTable.Row>
          <div
            className={
              'details ' + (this.state.detailsExpanded ? 'show' : 'hidden')
            }
          >
            <ReleaseDetailsApproversRow
              approvers={this.props.releaseAppApprovers}
            />
          </div>
          <hr />
        </div>
      </>
    );
  }
}

ReleaseDetailsAppRow.propTypes = {
  releaseAppName: PropTypes.string.isRequired,
  releaseAppVersion: PropTypes.string.isRequired,
  releaseAppEnv: PropTypes.string.isRequired,
  releaseAppDate: PropTypes.instanceOf(Date).isRequired,
  releaseAppStatus: PropTypes.string.isRequired,
  releaseAppApprovers: PropTypes.array.isRequired
};
