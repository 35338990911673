/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';

export const historyShape = PropTypes.shape({
  getCurrentLocation: PropTypes.func.isRequired,
  listen: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired
});

const routeShape = PropTypes.shape({
  path: PropTypes.string.isRequired,
  component: PropTypes.func,
  indexRoute: PropTypes.oneOfType([
    PropTypes.shape({
      component: PropTypes.func.isRequired
    }),
    PropTypes.shape({
      components: PropTypes.shape({
        mainView: PropTypes.func.isRequired,
        sidebarWidgetsView: PropTypes.func
      })
    }),
    PropTypes.shape({
      to: PropTypes.string.isRequired
    })
  ]),
  childRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired
    })
  )
});

export const routesShape = PropTypes.arrayOf(routeShape);

export const storeShape = PropTypes.shape({
  dispatch: PropTypes.func.isRequired,
  getState: PropTypes.func.isRequired,
  subscribe: PropTypes.func.isRequired,
  replaceReducer: PropTypes.func.isRequired
});

export const optionalSymbolPropType = (props, propName, componentName) => {
  const prop = props[propName];
  if (typeof prop !== 'undefined') {
    return requiredSymbolPropType(props, propName, componentName);
  }
};

export const requiredSymbolPropType = (props, propName, componentName) => {
  const propType = typeof props[propName];
  if (propType !== 'symbol') {
    return new Error(
      `Invalid prop \`#{propName}\` of type \`#{propType}\` supplied to ` +
        `\`#{componentName}\`, expected a symbol`
    );
  }
};
