/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { List, Map, fromJS } from 'immutable';
import { DateTime } from 'luxon';

import { OrdersProcessor } from 'src/lib/data_processors';

import { RC_DASHBOARD_ACTIONS } from '../defs';

const DEFAULT_STATE = Map({
  applications: List(),
  filters: Map({
    application: '',
    env: '',
    includeClosedReleases: true,
    release: '',
    searchString: '',
    timeline: Map({
      value: '',
      startDate: null,
      endDate: null
    })
  }),
  releases: List(),
  allReleases: List(),
  loading: false,
  error: null,
  newChangeStatus: null
});

const DATE_FORMAT = 'yyyy-MM-dd';

const processTimelineFilter = value => {
  const result = {
    value: '',
    startDate: null,
    endDate: null
  };

  let startDate = null;
  let endDate = DateTime.local();

  if (/^[0-9+]w$/.test(value)) {
    startDate = endDate.minus({
      weeks: parseInt(value.substr(0, value.length - 1), 10)
    });
  } else {
    startDate = null;
    endDate = null;
  }

  if (startDate && endDate) {
    result.value = value;
    result.startDate = startDate.toFormat(DATE_FORMAT);
    result.endDate = endDate.plus({ days: 1 }).toFormat(DATE_FORMAT);
  }

  return result;
};

const rcDashboardReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case RC_DASHBOARD_ACTIONS.CLEANUP:
      return DEFAULT_STATE;

    case RC_DASHBOARD_ACTIONS.LOAD_DATA_START:
      return state.merge({
        loading: true,
        error: null,
        releases: List()
      });

    case RC_DASHBOARD_ACTIONS.LOAD_DATA_ERROR:
      return state.merge({
        loading: false,
        error: action.error
      });

    case RC_DASHBOARD_ACTIONS.LOAD_DATA_SUCCESS:
      return state.merge({
        loading: false,
        releases: fromJS(OrdersProcessor(action.orders, true))
      });

    case RC_DASHBOARD_ACTIONS.SET_APPLICATION_FILTER:
      return state.setIn(['filters', 'application'], action.application);

    case RC_DASHBOARD_ACTIONS.SET_TIMELINE_FILTER:
      return state.setIn(
        ['filters', 'timeline'],
        fromJS(processTimelineFilter(action.timeline))
      );

    case RC_DASHBOARD_ACTIONS.SET_ENV_FILTER:
      return state.setIn(['filters', 'env'], action.env);

    case RC_DASHBOARD_ACTIONS.LOAD_APPLICATIONS_SUCCESS:
      return state.set('applications', fromJS(action.applications));

    case RC_DASHBOARD_ACTIONS.SET_INCLUDE_CLOSED_RELEASES:
      return state.setIn(
        ['filters', 'includeClosedReleases'],
        action.includeClosedReleases
      );

    case RC_DASHBOARD_ACTIONS.LOAD_RELEASES_SUCCESS:
      return state.set('allReleases', fromJS(action.releases));

    case RC_DASHBOARD_ACTIONS.SET_RELEASE_FILTER:
      return state.setIn(['filters', 'release'], action.release);

    case RC_DASHBOARD_ACTIONS.SET_SEARCH_STRING:
      return state.setIn(['filters', 'searchString'], action.searchString);

    case RC_DASHBOARD_ACTIONS.ADD_NEW_CHANGE_START:
      return state.merge({
        loading: true,
        error: null
      });

    case RC_DASHBOARD_ACTIONS.ADD_NEW_CHANGE_SUCCESS:
      return state.merge({
        loading: false,
        error: null,
        newChangeStatus: action
      });

    case RC_DASHBOARD_ACTIONS.ADD_NEW_CHANGE_ERROR:
      return state.merge({
        loading: false,
        error: action.error
      });

    case RC_DASHBOARD_ACTIONS.ADD_APPLICATIONS_TO_CHANGE_START:
      return state.merge({
        loading: true,
        error: null
      });

    case RC_DASHBOARD_ACTIONS.ADD_APPLICATIONS_TO_CHANGE_SUCCESS:
      return state.merge({
        loading: false,
        error: null
      });

    case RC_DASHBOARD_ACTIONS.ADD_APPLICATIONS_TO_CHANGE_ERROR:
      return state.merge({
        loading: false,
        error: action.error
      });

    case RC_DASHBOARD_ACTIONS.ADD_ENV_TO_APPLICATION_START:
      return state.merge({
        loading: true,
        error: null
      });

    case RC_DASHBOARD_ACTIONS.ADD_ENV_TO_APPLICATION_SUCCESS:
      return state.merge({
        loading: false,
        error: null
      });

    case RC_DASHBOARD_ACTIONS.ADD_ENV_TO_APPLICATION_ERROR:
      return state.merge({
        loading: false,
        error: action.error
      });

    case RC_DASHBOARD_ACTIONS.ADD_APPROVER_TO_CHANGE_START:
      return state.merge({
        loading: true,
        error: null
      });

    case RC_DASHBOARD_ACTIONS.ADD_APPROVER_TO_CHANGE_SUCCESS:
      return state.merge({
        loading: false,
        error: null
      });

    case RC_DASHBOARD_ACTIONS.ADD_APPROVER_TO_CHANGE_ERROR:
      return state.merge({
        loading: false,
        error: action.error
      });

    default:
      return state;
  }
};

export default rcDashboardReducer;
