/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ClassName from 'classnames';
import { List, Map, OrderedMap } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import underscore from 'underscore';

import _ from 'src/lib/i18n';

import * as SetupWizardActions from '../actions/setup_wizard';
import { ENV_MODES } from '../defs';

const EnvsView = props => {
  return (
    <p>
      {underscore.map(props.envs.toJS(), (env, index) => {
        switch (env.mode) {
          case ENV_MODES.HOST:
            return (
              <span key={index}>
                {env.name} ({_('User name')}: <i>{env.user}</i>)
              </span>
            );

          case ENV_MODES.TAGS:
            return (
              <span key={index}>
                {env.name} ({_('AWS Tags')}: <i>{env.aws_tags}</i>)
              </span>
            );

          default:
            return <span key={index}>{env.name}</span>;
        }
      })}
    </p>
  );
};

const HostsView = props => {
  let initialHostsByEnvs = OrderedMap({});
  props.envs.forEach((env, index) => {
    if (env.get('mode') === ENV_MODES.HOST) {
      initialHostsByEnvs = initialHostsByEnvs.set(index, List());
    }
  });
  const hostsByEnvs = props.hosts.reduce((memo, host) => {
    return memo.update(host.get('env'), value => {
      const ip = host.get('ip');
      const ipClassName = ClassName({
        'text-muted': !ip
      });

      return value.push(
        <span key={host.get('name')}>
          {host.get('name')} (<i className={ipClassName}>{ip || 'auto'}</i>)
        </span>
      );
    });
  }, initialHostsByEnvs);

  return (
    <React.Fragment>
      {underscore.map(props.envs.toJS(), (env, index) => {
        if (env.mode !== ENV_MODES.HOST) {
          return null;
        }

        return (
          <p key={index}>
            <b>{env.name}</b>: {hostsByEnvs.get(index)}
          </p>
        );
      })}
    </React.Fragment>
  );
};

export class SummaryPageView extends React.Component {
  componentDidMount() {
    this.props.actions.enableBack();
    this.props.actions.enableNext();
  }
  render() {
    const hasHostEnvs = this.props.setupWizard
      .get('envs')
      .some((env, index) => {
        return env.get('mode') === ENV_MODES.HOST;
      });

    return (
      <div id="SetupWizardSummaryPageView">
        <h4>{_('Almost done')}</h4>
        <p>{_('Review the information below and click Apply.')}</p>
        <dl className="dl-horizontal">
          <dt>{_('Application name')}</dt>
          <dd>{this.props.setupWizard.get('appName')}</dd>
          <dt>{_('Environments')}</dt>
          <dd>
            <EnvsView envs={this.props.setupWizard.get('envs')} />
          </dd>
          {hasHostEnvs && (
            <React.Fragment>
              <dt>{_('Hosts')}</dt>
              <dd>
                <HostsView
                  envs={this.props.setupWizard.get('envs')}
                  hosts={this.props.setupWizard.get('hosts')}
                />
              </dd>
            </React.Fragment>
          )}
        </dl>
      </div>
    );
  }
}

SummaryPageView.propTypes = {
  actions: PropTypes.shape({
    enableBack: PropTypes.func.isRequired,
    enableNext: PropTypes.func.isRequired
  }),
  setupWizard: PropTypes.instanceOf(Map).isRequired
};

const mapStateToProps = (state, props) => {
  return {
    setupWizard: state.setupWizard
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      enableBack: SetupWizardActions.enableBack,
      enableNext: SetupWizardActions.enableNext
    },
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryPageView);

export default reduxContainer;
