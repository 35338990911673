/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Map, List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import underscore from 'underscore';

import { FormGroup, Select, Checkbox, Widget } from 'src/components';
import _ from 'src/lib/i18n';
import { autoDispatchActionCreator } from 'src/lib/redux';

import * as RCDashboardActions from '../actions/rc_dashboard';

export class ReleaseWidgetView extends React.Component {
  constructor(props) {
    super(props);
    this.onIncludeClosedReleasesCheckboxChanged = this.onIncludeClosedReleasesCheckboxChanged.bind(
      this
    );
    this.onReleaseSelectChange = this.onReleaseSelectChange.bind(this);
    this.onClearActionClick = this.onClearActionClick.bind(this);
  }
  componentDidMount() {
    this.props.actions.loadReleases(this.props.filters.toJS());
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filters !== this.props.filters) {
      this.props.actions.loadReleases(this.props.filters.toJS());
    }
  }
  onIncludeClosedReleasesCheckboxChanged(value) {
    this.props.actions.setIncludeClosedReleasesFilter(value);
  }
  onReleaseSelectChange(newValue) {
    this.props.actions.setReleaseFilter(newValue);
  }
  onClearActionClick() {
    this.props.actions.setReleaseFilter('');
  }
  render() {
    return (
      <Widget id="RCReleaseFilterWidget" title={_('Release')}>
        <FormGroup>
          <Checkbox
            label={_('Closed releases')}
            checked={this.props.filters.get('includeClosedReleases')}
            onChange={this.onIncludeClosedReleasesCheckboxChanged}
          />
          <Select
            placeholder={_('-- choose --')}
            value={this.props.filters.get('release', '')}
            onChange={this.onReleaseSelectChange}
          >
            {underscore.map(this.props.releases.toJS(), release => {
              return (
                <Select.Option key={release.id} value={release.name}>
                  {release.name}
                </Select.Option>
              );
            })}
          </Select>
        </FormGroup>
        <p className="filter-actions">
          <a onClick={this.onClearActionClick}>&times; {_('clear')}</a>
        </p>
      </Widget>
    );
  }
}

ReleaseWidgetView.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  releases: PropTypes.instanceOf(List).isRequired
};

const mapStateToProps = (state, props) => {
  const filters = state.rcDashboard.get('filters');

  let releases;
  if (filters.get('application') !== '') {
    releases = state.rcDashboard.get('releases');
  } else {
    releases = state.rcDashboard.get('allReleases');
  }
  return {
    filters: filters,
    releases: releases
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      setIncludeClosedReleasesFilter:
        RCDashboardActions.setIncludeClosedReleasesFilter,
      setReleaseFilter: RCDashboardActions.setReleaseFilter
    },
    dispatch
  );

  actions.loadReleases = autoDispatchActionCreator(
    RCDashboardActions.loadReleases,
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReleaseWidgetView);

export default reduxContainer;
