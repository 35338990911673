/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ClassName from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import underscore from 'underscore';

import { AppTable } from 'src/components';
import {
  DEFAULT_ENV_STATUS,
  NO_VALUE_PLACEHOLDER,
  STATUS_TO_LABEL_STYLE
} from 'src/lib/defs';

export class ReleaseOrderStatusCell extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick(event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.props.env && this.props.order) {
      this.props.onClick(this.props.env, this.props.order);
    }
  }
  render() {
    const className = ClassName('release-order-status-cell', {
      expanded: this.props.expanded
    });

    const order = this.props.order || {};
    const env = this.props.order || {};
    const hasOnClick = !underscore.isEmpty(env) && !underscore.isEmpty(order);

    return (
      <AppTable.LabelCell
        className={className}
        labelStyle={STATUS_TO_LABEL_STYLE[order.status] || DEFAULT_ENV_STATUS}
        title={order.status}
        value={order.name || NO_VALUE_PLACEHOLDER}
        onClick={hasOnClick ? this.onClick : undefined}
      />
    );
  }
}

ReleaseOrderStatusCell.propTypes = {
  env: PropTypes.object,
  expanded: PropTypes.bool,
  order: PropTypes.object,
  onClick: PropTypes.func
};
