/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Map } from 'immutable';
import React from 'react';
import underscore from 'underscore';

import { NO_VALUE_PLACEHOLDER } from 'src/lib/defs';
import _ from 'src/lib/i18n';

import { AppTable } from 'src/components';

export const StatusesRow = props => {
  return (
    <AppTable.Row>
      <AppTable.RowHeaderCell>{_('Statuses')}</AppTable.RowHeaderCell>

      {props.envs.size === 0 && (
        <AppTable.Cell columnClassName="empty">&nbsp;</AppTable.Cell>
      )}

      {props.envs.size > 0 && (
        <AppTable.ScrollView>
          {underscore.map(underscore.range(0, props.envs.size), index => {
            const env = props.envs.get(index).get('name');
            const envCounts = props.releaseStatusCounts.get(env, Map({}));

            if (envCounts.size > 0) {
              return (
                <AppTable.SegmentedControlCell key={index}>
                  <span className="text-success">
                    {envCounts.get('status', Map()).get('success', 0)}
                  </span>
                  <span className="text-danger">
                    {envCounts.get('status', Map()).get('failure', 0)}
                  </span>
                  <span className="text-muted">
                    {envCounts.get('status', Map()).get('unknown', 0)}
                  </span>
                </AppTable.SegmentedControlCell>
              );
            } else {
              return (
                <AppTable.Cell key={index}>
                  {NO_VALUE_PLACEHOLDER}
                </AppTable.Cell>
              );
            }
          })}
        </AppTable.ScrollView>
      )}

      <AppTable.Actions />
    </AppTable.Row>
  );
};
