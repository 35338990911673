/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Map } from 'immutable';

import { AUTH_ACTIONS } from '../defs';

const DEFAULT_STATE = Map({
  login: undefined,
  ready: false,
  token: undefined,
  verifying: false
});

const authReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case AUTH_ACTIONS.GET_AUTH:
    case AUTH_ACTIONS.SET_AUTH:
      return state.merge({
        login: action.login,
        token: action.token,
        ready: false,
        verifying: false
      });

    case AUTH_ACTIONS.REMOVE_AUTH:
      return DEFAULT_STATE;

    case AUTH_ACTIONS.VERIFY_AUTH_START:
      return state.merge({
        ready: false,
        verifying: true
      });

    case AUTH_ACTIONS.VERIFY_AUTH_ERROR:
      return state.merge({
        ready: false,
        verifying: false
      });

    case AUTH_ACTIONS.VERIFY_AUTH_SUCCESS:
      return state.merge({
        ready: true,
        verifying: false
      });

    default:
      return state;
  }
};

export default authReducer;
