/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import underscore from 'underscore';

export class Response {
  constructor(xhr) {
    this._body = xhr.responseText;
    this._headers = {};
    this._statusCode = xhr.status;
    this._statusText = xhr.statusText;
    this._url = xhr.responseURL;

    let rawHeaders = xhr.getAllResponseHeaders();
    if (rawHeaders) {
      rawHeaders = rawHeaders.trim().split(/[\r\n]+/);

      for (let i = 0; i < rawHeaders.length; i++) {
        const header = rawHeaders[i].split(':', 2);
        this._headers[header[0].trim()] = header[1].trim();
      }
    }
  }
  get body() {
    return this._body;
  }
  get headers() {
    return this._headers;
  }
  get statusCode() {
    return this._statusCode;
  }
  get statusText() {
    return this._statusText;
  }
  get url() {
    return this._url;
  }
}

export class APIResponse extends Response {
  constructor(xhr) {
    super(xhr);
    this._json = undefined;
  }
  get json() {
    if (underscore.isUndefined(this._json)) {
      try {
        this._json = JSON.parse(this.body);
      } catch (error) {
        if (!this.body) {
          this._json = '';
        } else {
          this._json = this.body;
        }
      }
    }

    return this._json;
  }
  get ok() {
    return this.statusCode && this.statusCode >= 200 && this.statusCode < 400;
  }
}
