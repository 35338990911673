/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import underscore from 'underscore';
import URI from 'urijs';

import Config from 'src/config';
import { get, post } from 'src/lib/requests';
import { applyFilters } from 'src/lib/urilib';

export const create = (name, onSuccess, onError) => {
  let requestURL = new URI(Config.get('api_base_url'));
  requestURL = URI.joinPaths(requestURL, '/application');

  const body = { name: name };
  return post(requestURL, body, onSuccess, onError);
};

export const list = (include, onSuccess, onError) => {
  let requestURL = new URI(Config.get('api_base_url'));
  requestURL = URI.joinPaths(requestURL, '/application');

  if (include && include.length > 0) {
    requestURL.setSearch({ include: include.join(',') });
  }

  return get(requestURL, onSuccess, onError);
};

export const addEnvs = (name, envs, onSuccess, onError) => {
  let requestURL = new URI(Config.get('api_base_url'));
  requestURL = URI.joinPaths(requestURL, '/application', name, 'envs');

  const body = underscore.map(envs, (envName, index) => {
    return { name: envName };
  });

  return post(requestURL, body, onSuccess, onError);
};

export const listEnvs = (name, onSuccess, onError) => {
  let requestURL = new URI(Config.get('api_base_url'));
  requestURL = URI.joinPaths(requestURL, '/application', name, 'envs');

  return get(requestURL, onSuccess, onError);
};

export const getReleasesByEnv = (name, onSuccess, onError) => {
  let requestURL = new URI(Config.get('api_base_url'));
  requestURL = URI.joinPaths(
    requestURL,
    '/application',
    name,
    'results',
    'by_env'
  );

  return get(requestURL, onSuccess, onError);
};

export const getUpcomingReleases = (name, onSuccess, onError) => {
  let requestURL = new URI(Config.get('api_base_url'));
  requestURL = URI.joinPaths(
    requestURL,
    '/application',
    name,
    'orders',
    'upcoming'
  );

  return get(requestURL, onSuccess, onError);
};

export const getOrders = (filters, include, onSuccess, onError) => {
  let requestURL = new URI(Config.get('api_base_url'));

  requestURL = URI.joinPaths(
    requestURL,
    '/application',
    filters.application,
    'orders'
  );

  if (include && include.length > 0) {
    requestURL.setSearch({ include: include.join(',') });
  }

  requestURL = applyFilters(requestURL, filters);
  return get(requestURL, onSuccess, onError);
};

export const getOrdersForEnv = (filters, onSuccess, onError) => {
  let requestURL = new URI(Config.get('api_base_url'));

  requestURL = URI.joinPaths(
    requestURL,
    '/application',
    filters.application,
    'envs',
    filters.env,
    'orders'
  );

  requestURL = applyFilters(requestURL, filters);
  return get(requestURL, onSuccess, onError);
};
