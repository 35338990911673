/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { SETUP_WIZARD_ACTIONS } from '../defs';
import * as SetupWizardDataSources from '../data_sources';

export const enableBack = () => {
  return {
    type: SETUP_WIZARD_ACTIONS.ENABLE_BACK
  };
};

export const disableBack = () => {
  return {
    type: SETUP_WIZARD_ACTIONS.DISABLE_BACK
  };
};

export const enableNext = () => {
  return {
    type: SETUP_WIZARD_ACTIONS.ENABLE_NEXT
  };
};

export const disableNext = () => {
  return {
    type: SETUP_WIZARD_ACTIONS.DISABLE_NEXT
  };
};

export const setBackTitle = title => {
  return {
    type: SETUP_WIZARD_ACTIONS.SET_BACK_TITLE,
    title: title
  };
};

export const setNextTitle = title => {
  return {
    type: SETUP_WIZARD_ACTIONS.SET_NEXT_TITLE,
    title: title
  };
};

export const setAppName = name => {
  return {
    type: SETUP_WIZARD_ACTIONS.SET_APP_NAME,
    name: name
  };
};

export const addEnv = (name, user, awsTags, mode) => {
  return {
    type: SETUP_WIZARD_ACTIONS.ADD_ENV,
    name: name,
    user: user,
    awsTags: awsTags,
    mode: mode
  };
};

export const removeEnv = index => {
  return {
    type: SETUP_WIZARD_ACTIONS.REMOVE_ENV,
    index: index
  };
};

export const setEnvName = (index, name) => {
  return {
    type: SETUP_WIZARD_ACTIONS.SET_ENV_NAME,
    index: index,
    name: name
  };
};

export const setEnvUser = (index, user) => {
  return {
    type: SETUP_WIZARD_ACTIONS.SET_ENV_USER,
    index: index,
    user: user
  };
};

export const setEnvAWSTags = (index, awsTags) => {
  return {
    type: SETUP_WIZARD_ACTIONS.SET_ENV_AWS_TAGS,
    index: index,
    awsTags: awsTags
  };
};

export const setEnvMode = (index, mode) => {
  return {
    type: SETUP_WIZARD_ACTIONS.SET_ENV_MODE,
    index: index,
    mode: mode
  };
};

export const addHost = (env, name, ip) => {
  return {
    type: SETUP_WIZARD_ACTIONS.ADD_HOST,
    env: env,
    ip: ip,
    name: name
  };
};

export const removeHost = index => {
  return {
    type: SETUP_WIZARD_ACTIONS.REMOVE_HOST,
    index: index
  };
};

export const setHostEnv = (index, env) => {
  return {
    type: SETUP_WIZARD_ACTIONS.SET_HOST_ENV,
    index: index,
    env: env
  };
};

export const setHostIP = (index, ip) => {
  return {
    type: SETUP_WIZARD_ACTIONS.SET_HOST_IP,
    index: index,
    ip: ip
  };
};

export const setHostName = (index, name) => {
  return {
    type: SETUP_WIZARD_ACTIONS.SET_HOST_NAME,
    index: index,
    name: name
  };
};

export const applySettingsStart = () => {
  return {
    type: SETUP_WIZARD_ACTIONS.APPLY_SETTINGS_START
  };
};

export const onApplySettingsSuccess = data => {
  return {
    type: SETUP_WIZARD_ACTIONS.APPLY_SETTINGS_SUCCESS
  };
};

export const onApplySettingsError = error => {
  return {
    type: SETUP_WIZARD_ACTIONS.APPLY_SETTINGS_ERROR,
    error: error
  };
};

export const apply = (dispatch, application, envs, hosts, mode) => {
  const dataSource = new SetupWizardDataSources.SetupWizardDataSource(
    dispatch,
    application,
    envs,
    hosts,
    mode
  );
  dataSource.apply();

  return {
    type: SETUP_WIZARD_ACTIONS.APPLY_START
  };
};

export const onApplySuccess = () => {
  return {
    type: SETUP_WIZARD_ACTIONS.APPLY_SUCCESS
  };
};

export const onApplyError = (error, errors = null) => {
  return {
    type: SETUP_WIZARD_ACTIONS.APPLY_ERROR,
    error: error,
    errors: errors
  };
};

export const cleanup = () => {
  return {
    type: SETUP_WIZARD_ACTIONS.CLEANUP
  };
};
