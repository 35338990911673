/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export default [
  {
    created_at: '2017-04-18T20:09:12.667Z',
    envs: [
      {
        id: 1,
        name: 'dev'
      },
      {
        id: 2,
        name: 'uat'
      },
      {
        id: 3,
        name: 'prod'
      },
      {
        id: 4,
        name: '4'
      },
      {
        id: 5,
        name: '5'
      },
      {
        id: 6,
        name: '6'
      },
      {
        id: 7,
        name: '7'
      },
      {
        id: 8,
        name: '8'
      },
      {
        id: 9,
        name: '9'
      }
    ],
    id: 1,
    name: 'WEBv1',
    updated_at: '2017-04-18T20:09:12.667Z'
  },
  {
    created_at: '2017-04-18T20:09:12.706Z',
    envs: [
      {
        id: 10,
        name: 'dev'
      },
      {
        id: 11,
        name: 'uat'
      },
      {
        id: 12,
        name: 'prod'
      }
    ],
    id: 2,
    name: 'TEST APPLICATION',
    updated_at: '2017-04-18T20:09:12.706Z'
  },
  {
    created_at: '2017-04-18T20:09:12.706Z',
    envs: [],
    id: 3,
    name: 'git',
    updated_at: '2017-04-18T20:09:12.706Z'
  },
  {
    created_at: '2017-04-18T20:09:12.706Z',
    envs: [],
    id: 4,
    name: 'aws_deploy',
    updated_at: '2017-04-18T20:09:12.706Z'
  },
  {
    created_at: '2017-04-18T20:09:12.706Z',
    envs: [],
    id: 5,
    name: 'local_deploy',
    updated_at: '2017-04-18T20:09:12.706Z'
  }
];
