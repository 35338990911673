/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import underscore from 'underscore';

import { AppTable } from 'src/components';
import * as types from 'src/types';

import { EnvStatusCell } from './EnvStatusCell';

interface Props {
  application: types.Application;
  loadEnvStatus: (applicationName: string, envName: string) => void;
  onApplicationButtonClick: (application: types.Application) => void;
}

export class ApplicationTableRow extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.onAddEnvClick = this.onAddEnvClick.bind(this);
    this.onApplicationButtonClick = this.onApplicationButtonClick.bind(this);
  }

  onAddEnvClick() {
    throw new Error('Not Implemented');
  }
  onActionButtonPlusClick() {
    throw new Error('Not Implemented');
  }
  onApplicationButtonClick() {
    this.props.onApplicationButtonClick(this.props.application);
  }
  render() {
    return (
      <>
        <AppTable.Row>
          <AppTable.AppNameCell onClick={this.onApplicationButtonClick}>
            {this.props.application.name}
          </AppTable.AppNameCell>

          {this.props.application.envs.length === 0 && (
            <AppTable.Cell columnClassName="empty">&nbsp;</AppTable.Cell>
          )}

          {this.props.application.envs.length > 0 && (
            <>
              <AppTable.ScrollViewWithButtons
                onActionButtonPlusClick={this.onActionButtonPlusClick}
              >
                {underscore.map(this.props.application.envs, (env, index) => {
                  return (
                    <EnvStatusCell
                      key={env.id}
                      applicationName={this.props.application.name}
                      envName={env.name}
                      status={env.status}
                      loadError={env.load_error}
                      loadEnvStatus={this.props.loadEnvStatus}
                    />
                  );
                })}
              </AppTable.ScrollViewWithButtons>
            </>
          )}
        </AppTable.Row>
      </>
    );
  }
}
