/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import URI from 'urijs';
import underscore from 'underscore';

const KNOWN_FILTERS = [
  'application',
  'startDate',
  'endDate',
  'includeClosedReleases',
  'release',
  'searchString'
];

const FILTER_TO_QUERY = {
  endDate: 'end_date',
  startDate: 'start_date',
  searchString: 'q'
};

const processFilters = (filters, skipApplication) => {
  let result = {};

  underscore.forEach(underscore.keys(filters), (key, index) => {
    if (key === 'application' && skipApplication) {
      return true;
    }

    const value = filters[key];
    if (underscore.isObject(value)) {
      result = underscore.extendOwn(
        {},
        result,
        processFilters(value, skipApplication)
      );
    } else {
      if (value && KNOWN_FILTERS.indexOf(key) !== -1) {
        result[FILTER_TO_QUERY[key] || key] = filters[key];
      }
    }
  });

  return result;
};

export const applyFilters = (uri, filters, skipApplication = true) => {
  const query = processFilters(filters, skipApplication);

  return new URI(uri).addQuery(query);
};
