/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const DEFAULT_ENV_STATUS = 'default';
export const NO_VALUE_PLACEHOLDER = '----';

export const STATUS_TO_LABEL_STYLE = {
  approved: 'info',
  closed: 'default',
  deployed: 'success',
  failure: 'danger',
  failed: 'danger',
  in_deployment: 'warning',
  success: 'success',
  unknown: 'default',
  waiting_for_approvals: 'info',
  working: 'warning'
};
