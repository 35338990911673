/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ActionTypes } from 'src/lib/redux';

export const SETTINGS_ACTIONS = ActionTypes('SETTINGS', [
  'INIT_I18N',
  'SET_LANG',
  'LOAD_MESSAGES_START',
  'LOAD_MESSAGES_SUCCESS',
  'LOAD_MESSAGES_ERROR',
  'LOAD_SETTINGS_START',
  'LOAD_SETTINGS_SUCCESS',
  'LOAD_SETTINGS_ERROR',
  'DISABLE_SETUP_WIZARD',
  'RESET'
]);

export const SETTINGS_DEFAULTS = {
  needsSetupWizard: true
};

export const SETTINGS_LOCAL_STORAGE_KEYS = {
  LANG: 'lang'
};
