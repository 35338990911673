/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ClassName from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import underscore from 'underscore';

import BoostrapAlert from 'react-bootstrap/lib/Alert';

export class Alert extends React.PureComponent {
  render() {
    const wrapperClassname = ClassName('alert-wrapper', {
      'alert-wrapper-success': this.props.bsStyle === 'success',
      'alert-wrapper-warning': this.props.bsStyle === 'warning',
      'alert-wrapper-danger': this.props.bsStyle === 'danger',
      'alert-wrapper-info':
        underscore.isUndefined(this.props.bsStyle) ||
        this.props.bsStyle === 'info'
    });

    return (
      <div className={wrapperClassname}>
        <div className="alert-left"></div>
        <div className="alert-right"></div>
        <BoostrapAlert {...this.props}>{this.props.children}</BoostrapAlert>
      </div>
    );
  }
}

Alert.propTypes = {
  bsClass: PropTypes.string,
  bsStyle: PropTypes.string,
  closeLabel: PropTypes.string,
  onDismiss: PropTypes.func
};
