/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export default [
  {
    id: 3,
    start_date: '2019-03-27T20:30:35.000Z',
    end_date: '2019-04-01T20:30:35.000Z',
    description: 'Future release order',
    status: 'working',
    name: 'Future release order',
    release_order_results: [],
    applications_with_versions: [
      {
        application_with_version: {
          id: 2,
          name: 'git',
          artifacts: [
            {
              id: 6,
              name: 'artifact-from-git',
              version: 'v0.0.2'
            }
          ],
          version: 'v0.0.2'
        },
        envs: [
          {
            id: 4,
            name: 'prod'
          }
        ]
      }
    ],
    approvers: [],
    release: {
      id: 1,
      name: 'Release 1',
      status: 'open',
      dependent_releases: []
    }
  },
  {
    id: 4,
    start_date: '2019-02-15T20:30:35.000Z',
    end_date: '2019-02-25T20:30:35.000Z',
    description: 'Past release order',
    status: 'working',
    name: 'Past release order',
    release_order_results: [],
    applications_with_versions: [
      {
        application_with_version: {
          id: 1,
          name: 'static files',
          artifacts: [
            {
              id: 1,
              name: 'index',
              version: '1.0.0'
            },
            {
              id: 3,
              name: 'other3',
              version: '1.0.0'
            }
          ],
          version: '1.0.0'
        },
        envs: []
      }
    ],
    approvers: [],
    release: {
      id: 1,
      name: 'Release 1',
      status: 'open',
      dependent_releases: []
    }
  },
  {
    id: 5,
    start_date: '2019-02-15T20:30:35.000Z',
    end_date: '2019-03-17T20:30:35.000Z',
    description: 'Dont deploy me',
    status: 'working',
    name: 'Dont deploy me',
    release_order_results: [],
    applications_with_versions: [
      {
        application_with_version: {
          id: 1,
          name: 'static files',
          artifacts: [
            {
              id: 1,
              name: 'index',
              version: '1.0.0'
            },
            {
              id: 3,
              name: 'other3',
              version: '1.0.0'
            }
          ],
          version: '1.0.0'
        },
        envs: [
          {
            id: 1,
            name: 'test'
          }
        ]
      }
    ],
    approvers: [],
    release: {
      id: 1,
      name: 'Release 1',
      status: 'open',
      dependent_releases: []
    }
  },
  {
    id: 7,
    start_date: '2019-02-15T20:30:35.000Z',
    end_date: '2019-03-17T20:30:35.000Z',
    description: 'AWS by tags deployment only',
    status: 'deployed',
    name: 'Release order 4',
    release_order_results: [
      {
        id: 2,
        application_with_version_id: 4,
        version: '2.1.0',
        env: 'staging',
        status: 'success',
        deployment_date: '2019-03-07T20:31:20.053Z',
        has_log: true
      }
    ],
    applications_with_versions: [
      {
        application_with_version: {
          id: 4,
          name: 'aws_deploy',
          artifacts: [
            {
              id: 2,
              name: 'other',
              version: '1.0.0'
            }
          ],
          version: '2.1.0'
        },
        envs: [
          {
            id: 8,
            name: 'staging'
          }
        ]
      }
    ],
    approvers: [],
    release: {
      id: 1,
      name: 'Release 1',
      status: 'open',
      dependent_releases: []
    }
  },
  {
    id: 1,
    start_date: '2019-03-05T20:30:35.000Z',
    end_date: '2019-03-09T20:30:35.000Z',
    description: 'New release order',
    status: 'deployed',
    name: 'Release order 1',
    release_order_results: [
      {
        id: 3,
        application_with_version_id: 1,
        version: '1.0.0',
        env: 'test',
        status: 'success',
        deployment_date: '2019-03-07T20:31:42.162Z',
        has_log: true
      },
      {
        id: 4,
        application_with_version_id: 1,
        version: '1.0.0',
        env: 'prod',
        status: 'success',
        deployment_date: '2019-03-07T20:31:58.756Z',
        has_log: true
      },
      {
        id: 5,
        application_with_version_id: 2,
        version: 'v0.0.2',
        env: 'test',
        status: 'success',
        deployment_date: '2019-03-07T20:32:11.634Z',
        has_log: true
      },
      {
        id: 6,
        application_with_version_id: 2,
        version: 'v0.0.2',
        env: 'prod',
        status: 'success',
        deployment_date: '2019-03-07T20:32:23.753Z',
        has_log: true
      }
    ],
    applications_with_versions: [
      {
        application_with_version: {
          id: 1,
          name: 'static files',
          artifacts: [
            {
              id: 1,
              name: 'index',
              version: '1.0.0'
            },
            {
              id: 3,
              name: 'other3',
              version: '1.0.0'
            }
          ],
          version: '1.0.0'
        },
        envs: [
          {
            id: 1,
            name: 'test'
          },
          {
            id: 2,
            name: 'prod'
          }
        ]
      },
      {
        application_with_version: {
          id: 2,
          name: 'git',
          artifacts: [
            {
              id: 6,
              name: 'artifact-from-git',
              version: 'v0.0.2'
            }
          ],
          version: 'v0.0.2'
        },
        envs: [
          {
            id: 3,
            name: 'test'
          },
          {
            id: 4,
            name: 'prod'
          }
        ]
      }
    ],
    approvers: [],
    release: {
      id: 1,
      name: 'Release 1',
      status: 'open',
      dependent_releases: []
    }
  },
  {
    id: 2,
    start_date: '2019-03-05T20:30:35.000Z',
    end_date: '2019-03-09T20:30:35.000Z',
    description: 'New release order 2',
    status: 'deployed',
    name: 'Release order 2',
    release_order_results: [
      {
        id: 7,
        application_with_version_id: 2,
        version: 'v0.0.2',
        env: 'prod',
        status: 'failure',
        deployment_date: '2019-03-07T20:33:08.472Z',
        has_log: false
      }
    ],
    applications_with_versions: [
      {
        application_with_version: {
          id: 2,
          name: 'git',
          artifacts: [
            {
              id: 6,
              name: 'artifact-from-git',
              version: 'v0.0.2'
            }
          ],
          version: 'v0.0.2'
        },
        envs: [
          {
            id: 4,
            name: 'prod'
          }
        ]
      }
    ],
    approvers: [],
    release: {
      id: 1,
      name: 'Release 1',
      status: 'open',
      dependent_releases: []
    }
  },
  {
    id: 6,
    start_date: '2019-02-15T20:30:35.000Z',
    end_date: '2019-03-17T20:30:35.000Z',
    description: 'Local deployment only',
    status: 'deployed',
    name: 'Release order 3',
    release_order_results: [
      {
        id: 1,
        application_with_version_id: 3,
        version: '1.1.0',
        env: 'test',
        status: 'success',
        deployment_date: '2019-03-07T20:31:04.858Z',
        has_log: true
      }
    ],
    applications_with_versions: [
      {
        application_with_version: {
          id: 3,
          name: 'local_deploy',
          artifacts: [
            {
              id: 5,
              name: 'local_file',
              version: '1.1.0'
            }
          ],
          version: '1.1.0'
        },
        envs: [
          {
            id: 5,
            name: 'test'
          }
        ]
      }
    ],
    approvers: [],
    release: {
      id: 1,
      name: 'Release 1',
      status: 'open',
      dependent_releases: []
    }
  },
  {
    id: 6,
    name: 'Release order for second release',
    start_date: '2018-09-14T09:55:40.502Z',
    end_date: '2018-09-16T09:55:40.502Z',
    description: 'Release order for second mocked release',
    applications_with_versions: [
      {
        application_with_version: {
          id: 2,
          name: 'WEBv1',
          artifacts: [
            {
              id: 3,
              name: 'index',
              version: '3.0.0'
            },
            {
              id: 4,
              name: 'other',
              version: '1.4.1'
            }
          ],
          version: '2.0.0'
        },
        envs: [
          {
            id: 3,
            name: 'prod'
          }
        ]
      }
    ],
    approvers: [],
    status: 'working',
    release: {
      id: 2,
      name: 'Second release',
      status: 'closed',
      dependent_releases: []
    }
  }
];
