/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import API from 'src/lib/api';
import * as LocalStorage from 'src/lib/local_storage';

import * as requests from 'src/lib/requests';

import { AUTH_ACTIONS, AUTH_LOCAL_STORAGE_KEYS } from '../defs';

export const getAuth = () => {
  const token = LocalStorage.getItem(AUTH_LOCAL_STORAGE_KEYS.TOKEN);
  if (token) {
    requests.APIRequest.setAuthToken(token);
  }

  return {
    type: AUTH_ACTIONS.GET_AUTH,
    token: token,
    login: LocalStorage.getItem(AUTH_LOCAL_STORAGE_KEYS.LOGIN)
  };
};

export const setAuth = (newToken, newLogin) => {
  LocalStorage.setItem(AUTH_LOCAL_STORAGE_KEYS.TOKEN, newToken);
  LocalStorage.setItem(AUTH_LOCAL_STORAGE_KEYS.LOGIN, newLogin);
  requests.APIRequest.setAuthToken(newToken);

  return {
    type: AUTH_ACTIONS.SET_AUTH,
    token: newToken,
    login: newLogin
  };
};

export const removeAuth = () => {
  LocalStorage.removeItem(AUTH_LOCAL_STORAGE_KEYS.TOKEN);
  LocalStorage.removeItem(AUTH_LOCAL_STORAGE_KEYS.LOGIN);
  requests.APIRequest.setAuthToken(undefined);

  return {
    type: AUTH_ACTIONS.REMOVE_AUTH
  };
};

export const verifyAuth = dispatch => {
  API.Auth.verify(
    data => {
      dispatch(onVerifyAuthSuccess(dispatch));
    },
    error => {
      dispatch(onVerifyAuthError(dispatch, error));
    }
  );

  return {
    type: AUTH_ACTIONS.VERIFY_AUTH_START
  };
};

export const onVerifyAuthSuccess = () => {
  return {
    type: AUTH_ACTIONS.VERIFY_AUTH_SUCCESS
  };
};

export const onVerifyAuthError = (dispatch, error) => {
  dispatch(removeAuth());

  return {
    type: AUTH_ACTIONS.VERIFY_AUTH_ERROR
  };
};
