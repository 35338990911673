/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push as pushRoute } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { setDocumentTitle } from 'redux-doctitle';

import { FullWindowLoader } from 'src/components';
import _ from 'src/lib/i18n.js';
import { autoDispatchActionCreator } from 'src/lib/redux';

import * as AuthActions from '../actions/auth';
import * as LogoutActions from '../actions/logout';

export class LogoutView extends React.Component {
  componentDidMount() {
    this.props.actions.setDocumentTitle(_('Log out'));

    this.props.actions.logoutStart(
      this.props.auth.get('login'),
      this.props.auth.get('token')
    );
  }
  componentDidUpdate(prevProps) {
    if (prevProps.done !== this.props.done) {
      if (this.props.done) {
        this.props.actions.removeAuth();
        this.props.actions.pushRoute('/auth/login');
      }
    }
  }
  componentWillUnmount() {
    this.props.actions.cleanup();
  }
  render() {
    return <FullWindowLoader text={_('Logging out...')} />;
  }
}

LogoutView.propTypes = {
  actions: PropTypes.shape({
    cleanup: PropTypes.func.isRequired,
    logoutStart: PropTypes.func.isRequired,
    pushRoute: PropTypes.func.isRequired,
    removeAuth: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired
  }),
  auth: PropTypes.instanceOf(Map).isRequired,
  done: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    done: state.logout.get('done')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      cleanup: LogoutActions.cleanup,
      pushRoute: pushRoute,
      removeAuth: AuthActions.removeAuth,
      setDocumentTitle: setDocumentTitle
    },
    dispatch
  );

  actions.logoutStart = autoDispatchActionCreator(
    LogoutActions.start,
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(mapStateToProps, mapDispatchToProps)(LogoutView);

export default reduxContainer;
