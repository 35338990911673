/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { List, Map } from 'immutable';

import { NOTIFICATIONS_ACTIONS } from '../defs';

let nextId = 0;

const DEFAULT_STATE = List();

const presentedNotification = notification => {
  const result = Map(notification);
  nextId += 1;

  return result.set('identifier', nextId);
};

const notificationsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case NOTIFICATIONS_ACTIONS.PRESENT:
      return state.push(
        presentedNotification({
          text: action.text,
          kind: action.kind,
          title: action.title,
          subtitle: action.subtitle
        })
      );

    case NOTIFICATIONS_ACTIONS.DISMISS:
      return state.filter(notification => {
        return notification.get('identifier') !== action.identifier;
      });

    case NOTIFICATIONS_ACTIONS.CLEANUP:
      return DEFAULT_STATE;

    default:
      return state;
  }
};

export default notificationsReducer;
