/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push as pushRoute } from 'react-router-redux';
import { bindActionCreators } from 'redux';

import { historyShape } from 'src/lib/defs';
import { autoDispatchActionCreator } from 'src/lib/redux';

import * as SettingsActions from '../actions/settings';

export class SettingsProvider extends React.Component {
  componentDidMount() {
    if (this.props.authReady) {
      this.props.actions.loadSettings();
    }

    this.routeToSetupWizardIfNeeded();
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.authReady !== nextProps.authReady) {
      if (nextProps.authReady) {
        this.props.actions.loadSettings();
      } else {
        this.props.actions.resetSettings();
      }
    }
  }
  componentDidUpdate() {
    this.routeToSetupWizardIfNeeded();
  }
  routeToSetupWizardIfNeeded() {
    if (this.props.authReady && this.props.settingsLoaded) {
      const needsSetupWizard =
        this.props.needsSetupWizard &&
        this.props.history.getCurrentLocation().pathname !== '/setup';

      if (needsSetupWizard) {
        this.props.actions.pushRoute('/setup');
      }
    }
  }
  render() {
    return this.props.children;
  }
}

SettingsProvider.propTypes = {
  actions: PropTypes.shape({
    loadSettings: PropTypes.func.isRequired,
    pushRoute: PropTypes.func.isRequired,
    resetSettings: PropTypes.func.isRequired
  }),
  authReady: PropTypes.bool.isRequired,
  history: historyShape,
  needsSetupWizard: PropTypes.bool.isRequired,
  settingsLoaded: PropTypes.bool
};

const mapStateToProps = (state, props) => {
  return {
    authReady: state.auth.get('ready'),
    history: props.history,
    needsSetupWizard: state.settings.get('needsSetupWizard'),
    settingsLoaded: state.settings.get('settingsLoaded')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      pushRoute: pushRoute,
      resetSettings: SettingsActions.reset
    },
    dispatch
  );

  actions.loadSettings = autoDispatchActionCreator(
    SettingsActions.loadSettings,
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsProvider);

export default reduxContainer;
