/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import underscore from 'underscore';

import { get, post, put } from './shorthands';

export class RequestsQueue {
  constructor(failOnError = true) {
    this.failOnError = failOnError;
    this.onRequestError = this.onRequestError.bind(this);
    this.onRequestSuccess = this.onRequestSuccess.bind(this);

    this._onError = null;
    this._onSuccess = null;
    this._currentRequest = null;
    this._requests = [];
  }
  cancel() {
    this._currentRequest = null;
    this._requests = [];
  }
  next() {
    if (this._requests.length > 0) {
      this._currentRequest = this._requests.shift();
      this.sendRequest(this._currentRequest);
    } else {
      if (underscore.isFunction(this._onSuccess)) {
        this._onSuccess();
      }
    }
  }
  onRequestError(error, request) {
    if (underscore.isFunction(this._onError)) {
      this._onError(error, request);
    }

    if (!this.failOnError) {
      this.onRequestSuccess(undefined);
    }
  }
  onRequestSuccess(data) {
    if (
      this._currentRequest &&
      underscore.isFunction(this._currentRequest[3])
    ) {
      this._currentRequest[3](data);
    }

    this.next();
  }
  sendRequest(request) {
    let requestURL = request[1];
    if (underscore.isFunction(requestURL)) {
      requestURL = requestURL();
    }

    let requestData = request[2];
    if (underscore.isFunction(requestData)) {
      requestData = requestData();
    }

    switch (request[0]) {
      case 'GET':
        get(requestURL, this.onRequestSuccess, this.onRequestError);
        break;

      case 'POST':
        post(
          requestURL,
          requestData,
          this.onRequestSuccess,
          this.onRequestError
        );
        break;

      case 'PUT':
        put(
          requestURL,
          requestData,
          this.onRequestSuccess,
          this.onRequestError
        );
        break;

      default:
        break;
    }
  }
  get(url, responseProcessor) {
    this._requests.push(['GET', url, null, responseProcessor]);
  }
  post(url, body, responseProcessor) {
    this._requests.push(['POST', url, body, responseProcessor]);
  }
  put(url, body, responseProcessor) {
    this._requests.push(['PUT', url, body, responseProcessor]);
  }
  onError(callback) {
    this._onError = callback;
  }
  onSuccess(callback) {
    this._onSuccess = callback;
  }
  start() {
    this.next();
  }
}
