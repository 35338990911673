/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Enum from 'es6-enum';
import { List, Map } from 'immutable';

export const DEFAULT_ERRORS_STATE = Map({
  application: List([]),
  envs: List([]),
  hosts: List([]),
  credentials: List([])
});

export const DEFAULT_ENV_VALIDATION_STATE = Map({
  name: true,
  user: true,
  aws_tags: true,
  mode: true
});

export const DEFAULT_HOST_VALIDATION_STATE = Map({
  env: true,
  ip: true,
  name: true
});

export const ENV_MODES = {
  HOST: 'ENV_MODES/HOST',
  NONE: 'ENV_MODES/NONE',
  TAGS: 'ENV_MODES/TAGS'
};

export const PAGES_APP_WIZARD = [
  'app',
  'envs',
  'hosts',
  'summary',
  'apply',
  'done_app_wizard'
];

export const PAGES_SETUP_WIZARD = [
  'welcome',
  'app',
  'envs',
  'hosts',
  'summary',
  'apply',
  'done'
];

export const SETUP_WIZARD_DONE_PAGES = ['done', 'done_app_wizard'];

export const SETUP_WIZARD_MODAL_MODE = Enum('APP_WIZARD', 'SETUP_WIZARD');
