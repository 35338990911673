/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDocumentTitle } from 'redux-doctitle';
import { push as pushRoute } from 'react-router-redux';
import { autoDispatchActionCreator } from 'src/lib/redux';
import * as RCDashboardActions from '../actions/rc_dashboard';
import * as RCDashboardSelectors from '../selectors/rc_dashboard';

import _ from 'src/lib/i18n.js';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
  Alert,
  AppTable,
  Button,
  Col,
  FormControl,
  FormGroup,
  Loader,
  ControlLabel,
  InputGroup,
  RequiredControlLabel,
  RequiredFormInput
} from 'src/components';

import { enGB } from 'date-fns/locale';
import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import { format, add } from 'date-fns';
import { ReleaseInputAutocomplete } from '../components/ReleaseInputAutocomplete';
import { ReleaseAddChangeJiraPopup } from '../components/ReleaseAddChangeJiraPopup';

export class ReleaseAddChangeView extends React.Component {
  constructor(props) {
    super(props);
    this.downshift = null;
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.deleteApprover = this.deleteApprover.bind(this);
    this.onSelectedApplication = this.onSelectedApplication.bind(this);
    this.onSelectedRelease = this.onSelectedRelease.bind(this);
    this.onAppVersionSelected = this.onAppVersionSelected.bind(this);
    this.onAppEnvSelected = this.onAppEnvSelected.bind(this);
    this.onAddApplication = this.onAddApplication.bind(this);
    this.deleteApplication = this.deleteApplication.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onJiraClick = this.onJiraClick.bind(this);
    this.onHideJiraPopupClick = this.onHideJiraPopupClick.bind(this);

    this.applicationVersions = [];
    this.applicationEnvs = [];

    this.state = {
      jiraPopupVisible: false,
      name: '',
      changeName: '',
      startDate: null,
      endDate: null,
      approver: '',
      approvers: [],
      applicationName: '',
      applicationVersion: '',
      applicationEnv: '',
      applications: [],
      newChangeStatus: '',
      jiraProject: '',
      jiraVersion: '',
      description: ''
    };
  }
  componentDidMount() {
    this.props.actions.setDocumentTitle(_('Add New Change | Release'));
    this.props.actions.loadApplications();
    this.props.actions.loadReleases();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.newChangeStatus &&
      nextProps.newChangeStatus.newChangeStatus &&
      nextProps.newChangeStatus.newChangeStatus.status
    ) {
      this.setState({
        newChangeStatus: nextProps.newChangeStatus.newChangeStatus.status
      });
    }
  }

  onSaveClick() {
    this.props.actions.addNewChangeStart(this.state);
  }

  onCancelClick() {
    this.props.actions.pushRoute(`/releases`);
  }

  onStartDateChange(event) {
    this.setState({ startDate: event });
  }

  onEndDateChange(event) {
    this.setState({ endDate: event });
  }

  onSelectedRelease(release) {
    if (!release) return;
    this.setState({ name: release.name });
  }

  onSelectedApplication(app) {
    if (!app) return;
    this.applicationEnvs = app.envs;
    this.applicationVersions = app.versions;
    this.setState({
      applicationName: app.name,
      applicationEnv: app.envs[0] ? app.envs[0].name : '',
      applicationVersion: app.versions[0] ? app.versions[0] : ''
    });
  }

  onAppVersionSelected(event) {
    const version = event.target.value;
    this.setState({ applicationVersion: version });
  }

  onAppEnvSelected(event) {
    const env = event.target.value;
    this.setState({ applicationEnv: env });
  }

  onAddApplication() {
    if (
      this.state.applications.findIndex(
        a =>
          a.name === this.state.applicationName &&
          a.version === this.state.applicationVersion &&
          a.env === this.state.applicationEnv
      ) !== -1
    ) {
      alert(_('Application with those parameters already added.'));
      return;
    }
    const applications = this.state.applications;
    const application = {
      name: this.state.applicationName,
      env: this.state.applicationEnv,
      version: this.state.applicationVersion
    };
    applications.push(application);
    this.setState({
      applications: applications,
      applicationName: '',
      applicationEnv: '',
      applicationVersion: ''
    });
    this.downshift.clearSelection();
    this.applicationEnvs = [];
    this.applicationVersions = [];
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      const newValue = event.target.value;
      this.setState({
        approvers: [...this.state.approvers, newValue],
        approver: ''
      });
    }
  }

  deleteApprover(approver) {
    const newState = this.state.approvers.filter(a => {
      return a !== approver;
    });
    this.setState({ approvers: newState });
  }

  deleteApplication(application) {
    const newState = this.state.applications.filter(a => a !== application);
    this.setState({ applications: newState });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  onJiraClick() {
    this.setState({ jiraPopupVisible: true });
  }
  onHideJiraPopupClick() {
    this.setState({ jiraPopupVisible: false });
    // this.props.onPopupOverlayClick();
  }
  render() {
    return (
      <div className="ReleaseAddNewChange">
        <BreadcrumbsItem to="/#/releases">{_('Releases')}</BreadcrumbsItem>
        <BreadcrumbsItem to={`/#/releases/addnewchange`}>
          {_('Changes')}
        </BreadcrumbsItem>
        <AppTable.Table>
          <div className="header">
            <AppTable.Row>
              <Col md={3} className="name">
                {_('Add new change')}
              </Col>
              <Col md={9} className="buttons-cell">
                <div className="buttons pull-right">
                  <Button
                    className="btn-white-short"
                    onClick={this.onCancelClick}
                  >
                    <span className="delete" />
                  </Button>

                  <Button
                    className="btn-default-short"
                    disabled={
                      !this.state.applications.length > 0 ||
                      !this.state.changeName ||
                      !this.state.name
                    }
                    onClick={this.onSaveClick}
                  >
                    <span className="save" />
                  </Button>
                </div>{' '}
              </Col>
            </AppTable.Row>

            {this.props.loading && <Loader />}

            {!this.props.loading &&
              this.props.error &&
              this.props.error.statusCode === 409 && (
                <Alert bsStyle="danger">
                  <b>{_('Submit error.')}</b>{' '}
                  {_('Release Order with given "Change name" exists.')}
                </Alert>
              )}

            {!this.props.loading &&
              !this.props.error &&
              this.state.newChangeStatus === 'ok' && (
                <Alert bsStyle="success">
                  <b>{_('Success.')}</b> {_('Release Order added succesfully.')}
                </Alert>
              )}

            <form className="release-add-change">
              <AppTable.Row>
                <Col md={3}>
                  <FormGroup controlId="name">
                    <RequiredControlLabel isValid={!!this.state.name}>
                      {_('Release name')}
                    </RequiredControlLabel>
                    <RequiredFormInput
                      requiredText={_('required field')}
                      isValid={!!this.state.name}
                    >
                      <ReleaseInputAutocomplete
                        onSelectedItem={this.onSelectedRelease}
                        itemsList={this.props.releases}
                        inputPlaceholder={_('Enter the Release name')}
                      />
                    </RequiredFormInput>
                  </FormGroup>
                </Col>
                <Col md={3} mdOffset={1}>
                  <span className="grey-text">{_('Application details')}</span>
                </Col>
              </AppTable.Row>
              <AppTable.Row>
                <Col md={3}>
                  <FormGroup controlId="changeName">
                    <RequiredControlLabel isValid={!!this.state.changeName}>
                      {_('Change name')}
                    </RequiredControlLabel>
                    <RequiredFormInput
                      requiredText={_('required field')}
                      isValid={!!this.state.changeName}
                    >
                      <FormControl
                        id="changeName"
                        name="changeName"
                        type="text"
                        placeholder={_('Enter the Change name')}
                        value={this.state.changeName}
                        onChange={this.handleInputChange}
                      />
                    </RequiredFormInput>
                  </FormGroup>
                </Col>
                <Col mdOffset={1} md={2}>
                  <FormGroup controlId="applicationName">
                    <RequiredControlLabel
                      isValid={this.state.applications.length > 0}
                    >
                      {_('Application name')}
                    </RequiredControlLabel>
                    <RequiredFormInput
                      requiredText={_('required field')}
                      isValid={this.state.applications.length > 0}
                    >
                      <ReleaseInputAutocomplete
                        onSelectedItem={this.onSelectedApplication}
                        itemsList={this.props.applicationsWithVersionsAndEnvs}
                        ref={downshift => (this.downshift = downshift)}
                        inputPlaceholder={_('Enter application name')}
                      />
                    </RequiredFormInput>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup controlId="applicationVersion">
                    <ControlLabel>{_('Application version')}</ControlLabel>
                    <RequiredFormInput
                      requiredText={_('required field')}
                      isValid={this.state.applications.length > 0}
                    >
                      <select
                        required
                        className="form-control"
                        name="applicationVersion"
                        onChange={this.onAppVersionSelected}
                        value={this.state.applicationVersion}
                      >
                        <option value="" disabled hidden>
                          {_('Enter application version')}
                        </option>
                        {this.applicationVersions.map(version => {
                          return <option key={version}>{version}</option>;
                        })}
                      </select>
                    </RequiredFormInput>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup controlId="applicationEnvironment">
                    <ControlLabel>{_('Application environment')}</ControlLabel>
                    <RequiredFormInput
                      requiredText={_('required field')}
                      isValid={this.state.applications.length > 0}
                    >
                      <select
                        required
                        className="form-control"
                        name="applicationEnvironment"
                        onChange={this.onAppEnvSelected}
                        value={this.state.applicationEnv}
                      >
                        <option value="" disabled hidden>
                          {_('Enter application environment')}
                        </option>
                        {this.applicationEnvs.map(env => {
                          return <option key={env.id}>{env.name}</option>;
                        })}
                      </select>
                    </RequiredFormInput>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <Button
                    wrapperClassName="add-application"
                    onClick={this.onAddApplication}
                    disabled={
                      !this.state.applicationName ||
                      !this.state.applicationEnv ||
                      !this.state.applicationVersion
                    }
                  >
                    {_('Add application')}
                  </Button>
                </Col>
              </AppTable.Row>

              {this.state.applications.length > 0 && (
                <AppTable.Row className="applications-list text-bold">
                  <ul>
                    {this.state.applications.map((application, index) => {
                      return (
                        <div key={index}>
                          <Col mdOffset={4} md={2}>
                            <li>
                              <span
                                className="delete"
                                onClick={() =>
                                  this.deleteApplication(application)
                                }
                              />
                              <span className="text">{application.name}</span>
                            </li>
                          </Col>
                          <Col md={2}>{application.version}</Col>
                          <Col md={2}>{application.env}</Col>
                        </div>
                      );
                    })}
                  </ul>
                </AppTable.Row>
              )}

              <AppTable.Row className="date-row">
                <Col md={3}>
                  <FormGroup controlId="date">
                    <ControlLabel>
                      {_('Start date')} - {_('End date')}
                    </ControlLabel>
                    <DateRangePicker
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onStartDateChange={this.onStartDateChange}
                      onEndDateChange={this.onEndDateChange}
                      minimumLength={1}
                      format="yyyy/MM/dd"
                      locale={enGB}
                    >
                      {({ startDateInputProps, endDateInputProps, focus }) => (
                        <div className="date-range">
                          <InputGroup>
                            <FormControl
                              wrapperClassName="start-date"
                              className={
                                'input' +
                                (focus === START_DATE ? ' -focused' : '')
                              }
                              {...startDateInputProps}
                              placeholder={format(new Date(), 'yyyy/MM/dd')}
                            />
                            <FormControl
                              wrapperClassName="end-date"
                              className={
                                'input' +
                                (focus === END_DATE ? ' -focused' : '')
                              }
                              {...endDateInputProps}
                              placeholder={format(
                                add(new Date(), { months: 1 }),
                                'yyyy/MM/dd'
                              )}
                            />
                            <Button className="btn-white-short date-button">
                              <span className="date-picker" />
                            </Button>
                          </InputGroup>
                        </div>
                      )}
                    </DateRangePicker>
                  </FormGroup>
                </Col>
              </AppTable.Row>

              <AppTable.Row>
                <Col md={3}>
                  <FormGroup controlId="approver">
                    <ControlLabel>{_('Approvers')}</ControlLabel>
                    <FormControl
                      id="approver"
                      name="approver"
                      type="text"
                      placeholder={_('Enter the approver email')}
                      value={this.state.approver}
                      onChange={this.handleInputChange}
                      onKeyPress={this.handleKeyPress}
                    />
                  </FormGroup>
                </Col>
                <Col mdOffset={1} md={3}>
                  <span className="grey-text">{_('Jira Release')}</span>
                </Col>
              </AppTable.Row>

              {this.state.approvers.length > 0 && (
                <AppTable.Row className="approvers-list">
                  <ul>
                    {this.state.approvers.map((approver, index) => {
                      return (
                        <li key={index}>
                          <span
                            className="delete"
                            onClick={() => this.deleteApprover(approver)}
                          />
                          <span className="text">{approver}</span>
                        </li>
                      );
                    })}
                  </ul>
                </AppTable.Row>
              )}

              <AppTable.Row>
                <Col md={3}></Col>
                <Col mdOffset={1} md={2}>
                  <FormGroup controlId="jiraProject">
                    <ControlLabel>{_('Jira project')}</ControlLabel>
                    <FormControl
                      id="jiraProject"
                      name="jiraProject"
                      type="text"
                      placeholder={_('Enter jira project')}
                      value={this.state.jiraProject}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup controlId="jiraVersion">
                    <ControlLabel>{_('Jira version')}</ControlLabel>
                    <FormControl
                      id="jiraVersion"
                      name="jiraVersion"
                      type="text"
                      placeholder={_('Enter jira version')}
                      value={this.state.jiraVersion}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>

                <Col md={2}>
                  <Button
                    className="btn-white-short "
                    wrapperClassName="jira-button"
                    onClick={this.onJiraClick}
                  >
                    <span className="jira" />
                  </Button>
                </Col>
              </AppTable.Row>

              <AppTable.Row className="empty-row" />

              <AppTable.Row className="description-row">
                <Col md={3}></Col>
                <Col mdOffset={1} md={7}>
                  <FormGroup controlId="description">
                    <ControlLabel>{_('Description')}</ControlLabel>
                    <FormControl
                      id="description"
                      name="description"
                      componentClass="textarea"
                      placeholder={_('Add your description here...')}
                      value={this.state.description}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </AppTable.Row>
            </form>
          </div>
        </AppTable.Table>
        {this.state.jiraPopupVisible && (
          <ReleaseAddChangeJiraPopup onHide={this.onHideJiraPopupClick} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const applicationsWithVersionsAndEnvs = RCDashboardSelectors.getApplicationWithVersionsAndEnvs(
    state
  );
  const releases = state.rcDashboard.get('allReleases').toJS();

  return {
    error: state.rcDashboard.get('error'),
    newChangeStatus: state.rcDashboard.get('newChangeStatus'),
    loading: state.rcDashboard.get('loading'),
    applicationsWithVersionsAndEnvs,
    releases
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      pushRoute: pushRoute,
      setDocumentTitle: setDocumentTitle
    },
    dispatch
  );

  actions.loadApplications = autoDispatchActionCreator(
    RCDashboardActions.loadApplications,
    dispatch
  );

  actions.loadReleases = autoDispatchActionCreator(
    RCDashboardActions.loadReleases,
    dispatch
  );

  actions.addNewChangeStart = autoDispatchActionCreator(
    RCDashboardActions.addNewChange,
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReleaseAddChangeView);

export default reduxContainer;
