/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Route, IndexRoute, createRoutes } from 'react-router';

import { NotFoundView } from 'src/blueprints/core/views';

import CreateNewPasswordView from './views/CreateNewPasswordView';
import LoginView from './views/LoginView';
import LogoutView from './views/LogoutView';
import ResetPasswordView from './views/ResetPasswordView';
import SignUpView from './views/SignUpView';

const routes = createRoutes([
  <Route path="/auth">
    <IndexRoute component={NotFoundView} />
    <Route path="login" component={LoginView} />
    <Route path="logout" component={LogoutView} />
    <Route path="reset_password" component={ResetPasswordView} />
    <Route
      path="create_new_password/:nonce"
      component={CreateNewPasswordView}
    />
    <Route path="signup" component={SignUpView} />
  </Route>
]);

export default routes;
