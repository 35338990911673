/*eslint no-console: ["error", { allow: ["log"] }] */
/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDocumentTitle } from 'redux-doctitle';
import underscore from 'underscore';

import { AppTable, Breadcrumb, Glyphicon } from 'src/components';

const formatItem = item => {
  if (item > 9) {
    return '' + item;
  }

  return '0' + item;
};

class DevAppTableView extends React.PureComponent {
  componentDidMount() {
    this.props.actions.setDocumentTitle('Dev AppTable');
  }
  render() {
    return (
      <div id="DevAppTableView">
        <Breadcrumb>
          <Breadcrumb.Item active>Dev AppTable</Breadcrumb.Item>
        </Breadcrumb>

        <AppTable.Table>
          <AppTable.Head>
            <AppTable.AppNameHeader>Spam</AppTable.AppNameHeader>
            <AppTable.ScrollView>
              {underscore.map(underscore.range(1, 10), item => {
                return (
                  <AppTable.Header key={item}>
                    Header {formatItem(item)}
                  </AppTable.Header>
                );
              })}
            </AppTable.ScrollView>
          </AppTable.Head>

          <AppTable.Row>
            <AppTable.AppNameCell>App Name</AppTable.AppNameCell>
            <AppTable.ScrollView>
              <AppTable.Cell>Plain</AppTable.Cell>
              <AppTable.GlyphiconCell glyph="cog" value="Glyphicon" />
              <AppTable.LabelCell labelStyle="success" value="Label" />
              <AppTable.RowHeaderCell>Row Header</AppTable.RowHeaderCell>
              <AppTable.SegmentedControlCell>
                <span className="text-success">16</span>
                <span className="text-danger">0</span>
                <span className="text-muted">0</span>
              </AppTable.SegmentedControlCell>
              <AppTable.LoaderCell />
              <AppTable.Cell>Plain</AppTable.Cell>
              <AppTable.Cell>Plain</AppTable.Cell>
              <AppTable.Cell>Plain</AppTable.Cell>
            </AppTable.ScrollView>

            <AppTable.Actions>
              <AppTable.ActionButton
                onClick={() => {
                  console.log('ActiobButton.onClick()');
                }}
              >
                <Glyphicon glyph="plus" />
              </AppTable.ActionButton>
            </AppTable.Actions>
          </AppTable.Row>

          {underscore.map(underscore.range(1, 10), row => {
            return (
              <AppTable.Row key={row}>
                <AppTable.RowHeaderCell>Row Header</AppTable.RowHeaderCell>
                <AppTable.ScrollView>
                  {underscore.map(underscore.range(1, 10), cell => {
                    return (
                      <AppTable.Cell key={cell}>
                        Cell {formatItem(cell)}
                      </AppTable.Cell>
                    );
                  })}
                </AppTable.ScrollView>

                <AppTable.Actions />
              </AppTable.Row>
            );
          })}
        </AppTable.Table>
      </div>
    );
  }
}

DevAppTableView.propTypes = {
  actions: PropTypes.shape({
    setDocumentTitle: PropTypes.func.isRequired
  })
};

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      setDocumentTitle: setDocumentTitle
    },
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DevAppTableView);

export default reduxContainer;
