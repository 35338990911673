/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import underscore from 'underscore';

import _ from 'src/lib/i18n';

import { DEFAULT_ENV_STATUS, NO_VALUE_PLACEHOLDER } from 'src/lib/defs';
import { AppTable } from 'src/components';

export const VersionsRow = props => {
  return (
    <AppTable.Row>
      <AppTable.RowHeaderCell>{_('Version')}</AppTable.RowHeaderCell>
      {props.statuses.size === 0 && (
        <AppTable.Cell columnClassName="empty">&nbsp;</AppTable.Cell>
      )}

      {props.statuses.size > 0 && (
        <AppTable.ScrollView>
          {underscore.map(underscore.range(0, props.statuses.size), index => {
            const status = props.statuses.get(index);

            return (
              <AppTable.LabelCell
                key={index}
                labelStyle={status.get('style', DEFAULT_ENV_STATUS)}
                value={status.get('version', NO_VALUE_PLACEHOLDER)}
              />
            );
          })}
        </AppTable.ScrollView>
      )}

      <AppTable.Actions />
    </AppTable.Row>
  );
};
