/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import underscore from 'underscore';

import { FormGroup, Select, Widget } from 'src/components';
import _ from 'src/lib/i18n';
import { autoDispatchActionCreator } from 'src/lib/redux';

import * as RCDashboardActions from '../actions/rc_dashboard';
import * as RCDashboardSelectors from '../selectors/rc_dashboard';

export class ApplicationWidgetView extends React.Component {
  constructor(props) {
    super(props);
    this.onApplicationSelectChange = this.onApplicationSelectChange.bind(this);
    this.onEnvSelectChange = this.onEnvSelectChange.bind(this);
    this.onClearActionClick = this.onClearActionClick.bind(this);
  }
  componentDidMount() {
    this.props.actions.loadApplications();
  }
  onApplicationSelectChange(newValue) {
    this.props.actions.setApplicationFilter(newValue);
  }
  onEnvSelectChange(newValue) {
    this.props.actions.setEnvFilter(newValue);
  }
  onClearActionClick() {
    this.props.actions.setApplicationFilter('');
    this.props.actions.setEnvFilter('');
  }
  render() {
    return (
      <Widget
        id="RCDashboardApplicationFilterWidget"
        title={_('Application')}
        disabled={this.props.applications.size === 0}
      >
        <FormGroup>
          <Select
            placeholder={_('-- choose --')}
            value={this.props.filters.get('application', '')}
            onChange={this.onApplicationSelectChange}
          >
            {underscore.map(this.props.applications, (application, index) => {
              return (
                <Select.Option key={index} value={application}>
                  {application}
                </Select.Option>
              );
            })}
          </Select>
          <Select
            placeholder={_('-- choose --')}
            value={this.props.filters.get('env', '')}
            onChange={this.onEnvSelectChange}
            disabled={this.props.envs.length === ''}
          >
            {underscore.map(this.props.envs, (env, index) => {
              return (
                <Select.Option key={index} value={env}>
                  {env}
                </Select.Option>
              );
            })}
          </Select>
        </FormGroup>
        <p className="filter-actions">
          <a onClick={this.onClearActionClick}>&times; {_('clear')}</a>
        </p>
      </Widget>
    );
  }
}

ApplicationWidgetView.propTypes = {
  actions: PropTypes.shape({
    loadApplications: PropTypes.func.isRequired,
    setApplicationFilter: PropTypes.func.isRequired,
    setEnvFilter: PropTypes.func.isRequired
  }),
  filters: PropTypes.instanceOf(Map).isRequired,
  applications: PropTypes.instanceOf(List).isRequired,
  envs: PropTypes.instanceOf(List).isRequired
};

const mapStateToProps = (state, props) => {
  const applications = RCDashboardSelectors.getApplicationNames(state);
  const envs = RCDashboardSelectors.getEnvsForApplication(state);
  return {
    filters: state.rcDashboard.get('filters'),
    applications: applications,
    envs: envs
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      setApplicationFilter: RCDashboardActions.setApplicationFilter,
      setEnvFilter: RCDashboardActions.setEnvFilter
    },
    dispatch
  );

  actions.loadApplications = autoDispatchActionCreator(
    RCDashboardActions.loadApplications,
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationWidgetView);

export default reduxContainer;
