/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { SidebarWidgets } from 'src/blueprints/core/components';

import RCDashboardWidgets from '../widgets';

export class RCDashboardSidebarWidgetsView extends React.Component {
  render() {
    return (
      <SidebarWidgets
        className="blueprint"
        hidden={this.props.loading}
        widgets={RCDashboardWidgets}
      />
    );
  }
}

RCDashboardSidebarWidgetsView.propTypes = {
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    loading: state.rcDashboard.get('loading')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RCDashboardSidebarWidgetsView);

export default reduxContainer;
