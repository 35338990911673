/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { autoDispatchActionCreator } from 'src/lib/redux';

import * as SettingsActions from '../actions/settings';

export class I18NProvider extends React.Component {
  componentDidMount() {
    this.props.actions.initI18N();
  }
  componentDidUpdate(prevProps) {
    if (this.props.lang && prevProps.lang !== this.props.lang) {
      this.props.actions.loadMessages(this.props.lang);
    }
  }
  render() {
    return this.props.children;
  }
}

I18NProvider.propTypes = {
  actions: PropTypes.shape({
    initI18N: PropTypes.func.isRequired,
    loadMessages: PropTypes.func.isRequired
  }),
  lang: PropTypes.string
};

const mapStateToProps = (state, props) => {
  return {
    lang: state.settings.get('lang')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = {
    initI18N: autoDispatchActionCreator(SettingsActions.initI18N, dispatch),
    loadMessages: autoDispatchActionCreator(
      SettingsActions.loadMessages,
      dispatch
    )
  };

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(I18NProvider);

export default reduxContainer;
