/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'src/lib/i18n.js';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { AppTable, Button, Modal, SlashDateFormatter } from 'src/components';

export class ReleaseAddChangeJiraPopup extends React.Component {
  render() {
    return (
      <>
        <BreadcrumbsItem to={`/#/releases/addnewchange`}>
          {_('Changes')}
        </BreadcrumbsItem>
        <BreadcrumbsItem to={`/#/releases/addnewchange/#`}>
          {_('Jira issues')}
        </BreadcrumbsItem>

        <Modal
          show={true}
          onHide={this.props.onHide}
          dialogClassName="putit-release-jira-popup"
        >
          <Modal.Body>
            <AppTable.Table>
              <div className="header">
                <AppTable.Row>
                  <AppTable.Cell columnClassName="triplewidth">
                    {_('Open issues in Jira')}
                  </AppTable.Cell>
                  <AppTable.Cell />

                  <AppTable.Cell>{_('Total')}</AppTable.Cell>
                  <AppTable.Cell columnClassName="buttons-cell">
                    <div className="buttons pull-right">
                      <Button className="btn-white-short">
                        <span className="delete" />
                      </Button>

                      <Button className="btn-default-short">
                        <span className="save" />
                      </Button>
                    </div>{' '}
                  </AppTable.Cell>
                </AppTable.Row>

                <div className="header-details header details no-border">
                  <AppTable.Row>
                    <AppTable.Cell className="grey-text">
                      {_('Key')}
                    </AppTable.Cell>
                    <AppTable.Cell className="grey-text">
                      {_('T')}
                    </AppTable.Cell>
                    <AppTable.Cell className="grey-text">
                      {_('Created')}
                    </AppTable.Cell>
                    <AppTable.Cell className="grey-text">
                      {_('Updated')}
                    </AppTable.Cell>
                    <AppTable.Cell className="grey-text">
                      {_('Assignee')}
                    </AppTable.Cell>
                    <AppTable.Cell className="grey-text">
                      {_('Status')}
                    </AppTable.Cell>
                  </AppTable.Row>
                </div>

                <div className="data">
                  <AppTable.Row>
                    <AppTable.Cell className="grey-text">
                      {_('Test')}
                    </AppTable.Cell>
                    <AppTable.Cell className="grey-text">
                      <span className="jira-avatar" />
                    </AppTable.Cell>
                    <AppTable.Cell className="grey-text">
                      <SlashDateFormatter value={new Date()} />
                    </AppTable.Cell>
                    <AppTable.Cell className="grey-text">
                      <SlashDateFormatter value={new Date()} />
                    </AppTable.Cell>
                    <AppTable.Cell className="grey-text">
                      {_('Test')}
                    </AppTable.Cell>
                    <AppTable.Cell className="grey-text">
                      <span className="jira-status">{_('Test')}</span>
                    </AppTable.Cell>
                  </AppTable.Row>
                </div>
              </div>
            </AppTable.Table>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

ReleaseAddChangeJiraPopup.propTypes = {
  onHide: PropTypes.func.isRequired
};
