/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { I18NMessage } from 'src/components';
import _ from 'src/lib/i18n';

import * as SettingsActions from 'src/blueprints/core/actions/settings';

import * as SetupWizardActions from '../actions/setup_wizard';

export class DonePageView extends React.Component {
  componentDidMount() {
    this.props.actions.cleanup();
    this.props.actions.disableSetupWizard();

    this.props.actions.disableBack();
    this.props.actions.disableNext();
  }
  render() {
    return (
      <div>
        <h4>{_('All done!')}</h4>
        <p>{_('Your setup has been applied by Putit.')}</p>
        <I18NMessage
          element="p"
          // eslint-disable-next-line max-len
          message={_(
            "You can learn more about using Putit from the <a href='https://docs.putit.io/'>Documentation</a>."
          )}
        />
      </div>
    );
  }
}

DonePageView.propTypes = {
  actions: PropTypes.shape({
    cleanup: PropTypes.func.isRequired,
    disableBack: PropTypes.func.isRequired,
    disableNext: PropTypes.func.isRequired,
    disableSetupWizard: PropTypes.func.isRequired
  })
};

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      cleanup: SetupWizardActions.cleanup,
      disableBack: SetupWizardActions.disableBack,
      disableNext: SetupWizardActions.disableNext,
      disableSetupWizard: SettingsActions.disableSetupWizard
    },
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DonePageView);

export default reduxContainer;
