/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import authReducer from './auth';
import createNewPasswordReducer from './create_new_password';
import loginReducer from './login';
import logoutReducer from './logout';
import resetPasswordReducer from './reset_password';
import signupReducer from './signup';

const reducers = {
  auth: authReducer,
  createNewPassword: createNewPasswordReducer,
  login: loginReducer,
  logout: logoutReducer,
  resetPassword: resetPasswordReducer,
  signup: signupReducer
};

export default reducers;
