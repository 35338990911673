/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import URI from 'urijs';

import Config from 'src/config';
import { delete_, get, post, put } from 'src/lib/requests';

export const login = (login, password, onSuccess, onError) => {
  const requestURL = new URI(Config.get('auth_base_url'));
  requestURL.filename('sign_in');

  const body = {
    user: {
      email: login,
      password: password
    }
  };

  return post(requestURL, body, onSuccess, onError);
};

export const verify = (onSuccess, onError) => {
  const requestURL = new URI(Config.get('auth_base_url'));
  requestURL.filename('check').setSearch({
    v: new Date().getTime()
  });

  return get(requestURL, onSuccess, onError);
};

export const signup = (email, password, onSuccess, onError) => {
  const requestURL = new URI(Config.get('auth_base_url'));

  const body = {
    user: {
      email: email,
      password: password,
      password_confirmation: password
    }
  };

  return post(requestURL, body, onSuccess, onError);
};

export const resetPassword = (email, onSuccess, onError) => {
  const requestURL = new URI(Config.get('auth_base_url'));
  requestURL.filename('password');

  const body = {
    user: {
      email: email
    }
  };

  return post(requestURL, body, onSuccess, onError);
};

export const logout = (login, token, onSuccess, onError) => {
  const requestURL = new URI(Config.get('auth_base_url'));
  requestURL.filename('sign_out');

  const body = {
    user: {
      email: login,
      token: token
    }
  };

  return delete_(requestURL, body, onSuccess, onError);
};

export const createNewPassword = (nonce, password, onSuccess, onError) => {
  const requestURL = new URI(Config.get('auth_base_url'));
  requestURL.filename('password');

  const body = {
    user: {
      password: password,
      password_confirmation: password,
      reset_password_token: nonce
    }
  };

  return put(requestURL, body, onSuccess, onError);
};
