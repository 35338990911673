/*eslint no-console: ["error", { allow: ["log", "error"] }] */
/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import underscore from 'underscore';

import { APIResponse, Response } from './response';

const API_AUTH_ERROR_HANDLERS = [];
const API_PARAMS = {};

const deregisterGlobalOnAuthError = index => {
  API_AUTH_ERROR_HANDLERS.splice(index, 1, null);
};

export class Request {
  constructor(method, url) {
    this._onXHRError = this._onXHRError.bind(this);
    this._onXHRLoad = this._onXHRLoad.bind(this);

    this._method = method;
    this._body = undefined;
    this._url = url.toString();

    this._xhr = new XMLHttpRequest();
    this._xhr.addEventListener('error', this._onXHRError);
    this._xhr.addEventListener('load', this._onXHRLoad);

    this._xhr.open(this._method, this._url);

    this._handlers = {
      error: [],
      success: []
    };
  }
  fireEventHandlers(event, args) {
    underscore.forEach(this._handlers[event], handler => {
      if (underscore.isFunction(handler)) {
        handler.apply(this, args);
      }
    });
  }
  _onXHRError(event) {
    this.fireEventHandlers('error', ['XHR Error', this._xhr]);
  }
  _onXHRLoad() {
    const response = new Response(this._xhr);
    this.fireEventHandlers('success', [response]);
  }
  body(body) {
    this._body = body;
    return this;
  }
  header(header, content) {
    this._xhr.setRequestHeader(header, content);
    return this;
  }
  success(handler) {
    this._handlers.success.push(handler);
    return this;
  }
  error(handler) {
    this._handlers.error.push(handler);
    return this;
  }
  send() {
    /// #if development
    console.log('Requests.Request.send()', this._method, this._url);
    /// #endif

    try {
      this._xhr.send(this._body);
    } catch (error) {
      /// #if development
      console.error(`RequestsError: '${error.message}'`);
      /// #endif
      this.fireEventHandlers('error', [error.message, null]);
    }

    return this;
  }
}

export class APIRequest extends Request {
  constructor(method, url) {
    super(method, url);
    this.header('Accept', 'application/json');

    if (API_PARAMS.authToken) {
      this.header('Authorization', `Bearer ${API_PARAMS.authToken}`);
    }
  }
  static deregisterAllAuthErrorHandlers() {
    API_AUTH_ERROR_HANDLERS.splice(0, API_AUTH_ERROR_HANDLERS.length);
  }
  static registerAuthErrorHandler(handler) {
    const newLength = API_AUTH_ERROR_HANDLERS.push(handler);

    return () => {
      deregisterGlobalOnAuthError(newLength - 1);
    };
  }
  static setAuthToken(authToken) {
    /// #if development
    console.log('Requests.APIRequest.setAuthToken()', authToken);
    /// #endif

    API_PARAMS.authToken = authToken;
  }
  _handleAuthError(response) {
    underscore.forEach(API_AUTH_ERROR_HANDLERS, handler => {
      if (underscore.isFunction(handler)) {
        handler.apply(this, [response.statusText, response.statusCode]);
      }
    });
  }
  _onXHRLoad() {
    const response = new APIResponse(this._xhr);
    if (response.ok) {
      this.fireEventHandlers('success', [response.json]);
    } else {
      this.fireEventHandlers('error', [response.statusText, response]);

      if (response.statusCode === 401) {
        this._handleAuthError(response);
      }

      /// #if development
      console.error(
        `RequestsError: ${this._method} ${this._url}: '${response.statusCode}' '${response.statusText}'`
      );
      /// #endif
    }
  }
  json(payload) {
    this.header('Content-Type', 'application/json');
    this.body(JSON.stringify(payload));

    return this;
  }
}
