/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import underscore from 'underscore';

import { Glyphicon } from 'src/components';
import _ from 'src/lib/i18n';

export const HostsError = props => {
  if (props.errors.size === 0) {
    return null;
  }

  return (
    <p>
      <b>{_('Hosts')}</b>:
      {underscore.map(props.errors.toJS(), (error, index) => {
        const hostName = props.hosts.getIn([index, 'name']);

        return (
          <span key={index} className="error" field={hostName}>
            <i>{hostName}</i>:&nbsp;
            <span className="text-danger">{error.error}</span>
          </span>
        );
      })}
    </p>
  );
};

export const ApplyError = props => {
  return (
    <span className="danger apply-error">
      <Glyphicon bsStyle="danger" glyph="exclamation-sign" /> {_('Error')}
      <HostsError hosts={props.hosts} errors={props.errors.get('hosts')} />
    </span>
  );
};
