/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import underscore from 'underscore';

import { NO_VALUE_PLACEHOLDER } from 'src/lib/defs';
import _ from 'src/lib/i18n';

import { AppTable } from 'src/components';

export const ArtifactsRow = props => {
  return (
    <AppTable.Row>
      <AppTable.RowHeaderCell>{_('Artifacts')}</AppTable.RowHeaderCell>

      {props.envs.size === 0 && (
        <AppTable.Cell columnClassName="empty">&nbsp;</AppTable.Cell>
      )}

      {props.envs.size > 0 && (
        <AppTable.ScrollView>
          {underscore.map(underscore.range(0, props.envs.size), index => {
            const status = props.statuses.get(index);
            const version = status.get('version');
            let value = undefined;

            if (version) {
              value = props.artifacts.get(version);
            }

            if (value) {
              return (
                <AppTable.Cell key={index} className="icon-cell">
                  <span>{value}</span>
                  <span className="action-button-cog"></span>
                </AppTable.Cell>
              );
            } else {
              return (
                <AppTable.Cell key={index}>
                  {NO_VALUE_PLACEHOLDER}
                </AppTable.Cell>
              );
            }
          })}
        </AppTable.ScrollView>
      )}

      <AppTable.Actions />
    </AppTable.Row>
  );
};
