/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Route, IndexRoute, createRoutes } from 'react-router';

import { AppView } from 'src/blueprints/core/views';

import RCDashboardView from './views/RCDashboardView';
import RCDashboardSidebarWidgetsView from './views/RCDashboardSidebarWidgetsView';
import ReleaseAddChangeView from './views/ReleaseAddChangeView';

const routes = createRoutes([
  <Route path="/releases" component={AppView}>
    <IndexRoute
      components={{
        mainView: RCDashboardView,
        sidebarWidgetsView: RCDashboardSidebarWidgetsView
      }}
    />
    <Route
      components={{
        mainView: RCDashboardView,
        sidebarWidgetsView: RCDashboardSidebarWidgetsView
      }}
      path=":envName/:releaseOrderName"
    />
    <Route
      components={{
        mainView: ReleaseAddChangeView
      }}
      path="AddNewChange"
    />
  </Route>
]);

export default routes;
