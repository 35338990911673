/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ClassName from 'classnames';
import NavbarLoginView from 'src/blueprints/auth/views/NavbarLoginView';
import PropTypes from 'prop-types';
import React from 'react';
import { routerShape } from 'react-router';
import underscore from 'underscore';

import {
  Breadcrumb as BootstrapBreadcrumb,
  Col,
  FormControl,
  Glyphicon,
  Modal,
  ModalButton,
  Nav,
  Navbar as NavbarComponent,
  NavItem,
  Row
} from 'src/components';
import _ from 'src/lib/i18n';

import { navItemsShape } from '../defs';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';

export class NavbarNav extends React.Component {
  getChildContext() {
    return {
      router: this.props.router
    };
  }
  render() {
    return <Nav pullRight={true}>{this.props.children}</Nav>;
  }
}

NavbarNav.childContextTypes = {
  router: routerShape
};

NavbarNav.propTypes = {
  router: routerShape
};

export class NavbarNavLink extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }
  onClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.context.router.push(this.props.to);
  }
  render() {
    const className = ClassName({
      active: this.context.router.isActive(this.props.to, this.props.indexOnly)
    });

    return (
      <li role="presentation" className={className}>
        <a role="button" onClick={this.onClick}>
          {this.props.children}
        </a>
      </li>
    );
  }
}

NavbarNavLink.contextTypes = {
  router: routerShape
};

NavbarNavLink.propTypes = {
  indexOnly: PropTypes.bool,
  to: PropTypes.string.isRequired
};

export class NavbarSearch extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onTermInputChange = this.onTermInputChange.bind(this);

    this.state = {
      expanded: false,
      term: ''
    };
  }
  onClick() {
    this.setState({
      expanded: !this.state.expanded,
      term: ''
    });
  }
  onTermInputChange(event) {
    this.setState({ term: event.target.value });
  }
  render() {
    const className = ClassName('putit-nav-search', {
      expanded: this.state.expanded
    });

    return (
      <div className={className}>
        <ul className="nav navbar-nav navbar-right">
          <li role="presentation">
            <a role="button" onClick={this.onClick}>
              <Glyphicon glyph="search" />
            </a>
          </li>
        </ul>

        <form className="navbar-form navbar-right">
          <div className="form-group">
            <FormControl
              type="text"
              placeholder={_('Search')}
              value={this.state.term}
              onChange={this.onTermInputChange}
            />
          </div>
        </form>
      </div>
    );
  }
}

export class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.onLogoutModalNoClick = this.onLogoutModalNoClick.bind(this);
    this.onLogoutModalYesClick = this.onLogoutModalYesClick.bind(this);

    this.state = {
      showLogoutModal: false
    };
  }
  onLogoutClick(event) {
    event.stopPropagation();
    event.preventDefault();

    this.setState({ showLogoutModal: true });
  }
  onLogoutModalNoClick(event) {
    this.setState({ showLogoutModal: false });
  }
  onLogoutModalYesClick(event) {
    this.props.router.push('/auth/logout');
  }
  render() {
    return (
      <React.Fragment>
        <NavbarComponent fixedTop={true} fluid={true} id="Navbar">
          <Row>
            <Col md={4}>
              <Breadcrumbs
                container={BootstrapBreadcrumb}
                separator={'>'}
                finalProps={{
                  className: 'text-bold'
                }}
              />
            </Col>
            <Col md={8}>
              <NavbarNav router={this.props.router}>
                {underscore.map(this.context.navItems, (item, index) => {
                  return (
                    <NavbarNavLink
                      key={index}
                      to={item.to}
                      indexOnly={item.indexOnly}
                    >
                      {item.title()}
                    </NavbarNavLink>
                  );
                })}
                <NavItem id="LogoutButton" onClick={this.onLogoutClick}>
                  {_('Log Out')}
                </NavItem>
                <NavItem id="NavbarLogin">
                  <NavbarLoginView />
                </NavItem>
              </NavbarNav>
            </Col>
          </Row>
        </NavbarComponent>

        <Modal show={this.state.showLogoutModal}>
          <Modal.Body>
            <div>
              <h4>{_('Do you really wish to log out?')}</h4>
              <p>
                <b>{_('All unsaved changes will be lost.')}</b>
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ModalButton onClick={this.onLogoutModalYesClick}>
              {_('Yes')}
            </ModalButton>
            <ModalButton right onClick={this.onLogoutModalNoClick}>
              {_('No')}
            </ModalButton>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

Navbar.contextTypes = {
  navItems: navItemsShape
};

export default Navbar;
