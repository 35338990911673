/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Checkbox, Widget } from 'src/components';
import _ from 'src/lib/i18n';

import * as RCDashboardActions from '../actions/rc_dashboard';

export class TimelineWidgetView extends React.Component {
  constructor(props) {
    super(props);
    this.onCheckboxChangeFactory = this.onCheckboxChangeFactory.bind(this);
    this.onClearActionClick = this.onClearActionClick.bind(this);
  }
  onCheckboxChangeFactory(value) {
    return newFlag => {
      if (newFlag) {
        this.props.actions.setTimelineFilter(value);
      } else {
        this.props.actions.setTimelineFilter('');
      }
    };
  }
  onClearActionClick() {
    this.props.actions.setTimelineFilter('');
  }
  render() {
    return (
      <Widget id="RCDashboardTimelineFilterWidget" title={_('Timeline')}>
        <Checkbox
          label={_('1 week')}
          checked={this.props.filters.getIn(['timeline', 'value']) === '1w'}
          onChange={this.onCheckboxChangeFactory('1w')}
        />

        <Checkbox
          label={_('2 weeks')}
          checked={this.props.filters.getIn(['timeline', 'value']) === '2w'}
          onChange={this.onCheckboxChangeFactory('2w')}
        />

        <Checkbox
          label={_('3 weeks')}
          checked={this.props.filters.getIn(['timeline', 'value']) === '3w'}
          onChange={this.onCheckboxChangeFactory('3w')}
        />

        <Checkbox
          label={_('4 weeks')}
          checked={this.props.filters.getIn(['timeline', 'value']) === '4w'}
          onChange={this.onCheckboxChangeFactory('4w')}
        />
        <p className="filter-actions">
          <a onClick={this.onClearActionClick}>&times; {_('clear')}</a>
        </p>
      </Widget>
    );
  }
}

TimelineWidgetView.propTypes = {
  actions: PropTypes.shape({
    setTimelineFilter: PropTypes.func.isRequired
  }),
  filters: PropTypes.instanceOf(Map).isRequired
};

const mapStateToProps = (state, props) => {
  return {
    filters: state.rcDashboard.get('filters')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      setTimelineFilter: RCDashboardActions.setTimelineFilter
    },
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineWidgetView);

export default reduxContainer;
