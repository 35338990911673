/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDocumentTitle } from 'redux-doctitle';

import { FullWindowContainer } from 'src/components';
import _ from 'src/lib/i18n.js';

const NotFoundView = props => {
  props.actions.setDocumentTitle(_('Not Found'));

  return (
    <FullWindowContainer>
      <h2 className="text-center">{_('Not Found')}</h2>
      <p className="lead text-center">
        {_("This isn't the page you were looking for.")}
      </p>
    </FullWindowContainer>
  );
};

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      setDocumentTitle: setDocumentTitle
    },
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotFoundView);

export default reduxContainer;
