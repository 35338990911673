/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { ScrollerButton } from './ScrollerButton';
import { ScrollView } from './ScrollView';
import { Actions, ActionButton } from './Actions';

export class ScrollViewWithButtons extends React.Component {
  constructor(props) {
    super(props);
    this.scrollview = null;
    this.checkOverflow = this.checkOverflow.bind(this);
    this.checkButtonsVisibility = this.checkButtonsVisibility.bind(this);
    this.state = {
      leftButtonVisible: false,
      rightButtonVisible: false,
      overflown: false
    };
  }

  componentDidMount() {
    this.checkButtonsVisibility();
    this.scrollview.element.addEventListener(
      'scroll',
      this.checkButtonsVisibility
    );
    window.addEventListener('resize', this.checkButtonsVisibility);
  }

  componentWillUnmount() {
    this.scrollview.element.removeEventListener(
      'scroll',
      this.checkButtonsVisibility
    );
    window.removeEventListener('resize', this.checkButtonsVisibility);
  }

  checkButtonsVisibility() {
    this.checkOverflow();
    if (!this.state.overflown) {
      this.scrollview.context.scrollLeft = 0;
    }
    const scrollViewWidth = this.scrollview.element.getBoundingClientRect()
      .width;
    const elementsWidth = this.scrollview.element.scrollWidth;

    const leftButtonVisible = this.scrollview.element.scrollLeft > 0;
    const rightButtonVisible =
      this.scrollview.element.scrollLeft + scrollViewWidth < elementsWidth;
    this.setState({ leftButtonVisible, rightButtonVisible });
  }

  checkOverflow() {
    const scrollViewWidth = this.scrollview.element.getBoundingClientRect()
      .width;
    const elementsWidth = this.scrollview.element.scrollWidth;
    const overflown = elementsWidth > scrollViewWidth;
    this.setState({ overflown });
  }
  render() {
    return (
      <>
        {this.state.leftButtonVisible && this.state.overflown && (
          <div className={'action-button-left-arrow-wrapper'}>
            <ScrollerButton type="left" className="scroll-left">
              <span className="action-button-left-arrow"></span>
            </ScrollerButton>
          </div>
        )}
        <ScrollView ref={scrollview => (this.scrollview = scrollview)}>
          {this.props.children}
        </ScrollView>
        <Actions>
          <ActionButton onClick={this.props.onActionButtonPlusClick}>
            <span className="action-button-plus"></span>
          </ActionButton>
        </Actions>
        {this.state.rightButtonVisible && this.state.overflown && (
          <div className={'action-button-right-arrow-wrapper'}>
            <ScrollerButton type="right" className="scroll-right">
              <span className="action-button-right-arrow"></span>
            </ScrollerButton>
          </div>
        )}
      </>
    );
  }
}

ScrollViewWithButtons.propTypes = {
  onActionButtonPlusClick: PropTypes.func
};
