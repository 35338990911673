/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import underscore from 'underscore';

const EMPTY_ARRAY = [];

export const getApplicationNames = state =>
  underscore.pluck(state.rcDashboard.get('applications').toJS(), 'name');

export const getEnvsForApplication = state => {
  const filters = state.rcDashboard.get('filters');
  const application = filters.get('application');

  if (!application) {
    return EMPTY_ARRAY;
  }

  const applicationForEnv = underscore.find(
    state.rcDashboard.get('applications').toJS(),
    { name: application }
  );

  return (
    (applicationForEnv &&
      applicationForEnv.envs &&
      underscore.pluck(applicationForEnv.envs, 'name')) ||
    EMPTY_ARRAY
  );
};

export const getApplicationWithVersionsAndEnvs = state => {
  const applications = state.rcDashboard.get('applications').toJS();
  return applications.map(application => {
    return {
      id: application.id,
      name: application.name,
      envs: application.envs.map(env => {
        return { id: env.id, name: env.name };
      }),
      versions: application.versions
    };
  });
};
