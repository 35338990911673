/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { APIRequest } from './request';

export const get = (url, onSuccess, onError) => {
  const request = new APIRequest('GET', url).error(onError).success(onSuccess);

  return request.send();
};

export const post = (url, body, onSuccess, onError) => {
  const request = new APIRequest('POST', url)
    .error(onError)
    .json(body)
    .success(onSuccess);

  return request.send();
};

export const put = (url, body, onSuccess, onError) => {
  const request = new APIRequest('PUT', url)
    .error(onError)
    .json(body)
    .success(onSuccess);

  return request.send();
};

export const delete_ = (url, body, onSuccess, onError) => {
  let request = new APIRequest('DELETE', url).error(onError).success(onSuccess);

  if (body) {
    request = request.json(body);
  }

  return request.send();
};
