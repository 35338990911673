/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Modal, ModalButton } from 'src/components';
import { requiredSymbolPropType } from 'src/lib/defs';
import _ from 'src/lib/i18n.js';

import * as SetupWizardActions from '../actions/setup_wizard';
import {
  ENV_MODES,
  PAGES_APP_WIZARD,
  PAGES_SETUP_WIZARD,
  SETUP_WIZARD_DONE_PAGES,
  SETUP_WIZARD_MODAL_MODE
} from '../defs';
import AppPageView from './AppPageView';
import ApplyPageView from './ApplyPageView';
import AppWizardDonePageView from './AppWizardDonePageView';
import DonePageView from './DonePageView';
import EnvsPageView from './EnvsPageView';
import HostsPageView from './HostsPageView';
import SummaryPageView from './SummaryPageView';
import WelcomePageView from './WelcomePageView';

export class ModalView extends React.Component {
  constructor(props) {
    super(props);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
    this.onCloseButtonClick = this.onCloseButtonClick.bind(this);
    this.onNextButtonClick = this.onNextButtonClick.bind(this);

    this.pages = PAGES_SETUP_WIZARD;
    if (this.isAppWizard()) {
      this.pages = PAGES_APP_WIZARD;
    }

    this.state = {
      page: 0
    };
  }
  isAppWizard() {
    return this.props.mode === SETUP_WIZARD_MODAL_MODE.APP_WIZARD;
  }
  componentDidMount() {
    this.updateBackTitle();
    this.updateNextTitle();
  }
  componentDidUpdate(prevProps, prevState) {
    this.updateBackTitle();
    this.updateNextTitle();
  }
  currentPage() {
    return this.pages[this.state.page];
  }
  updateBackTitle() {
    if (this.state.page === 0 && this.isAppWizard()) {
      this.props.actions.setBackTitle(_('Cancel'));
    } else {
      this.props.actions.setBackTitle(_('Back'));
    }
  }
  updateNextTitle() {
    switch (this.currentPage()) {
      case 'summary':
        this.props.actions.setNextTitle(_('Apply'));
        break;

      case 'apply':
        this.props.actions.setNextTitle(_('Finish'));
        break;

      default:
        this.props.actions.setNextTitle(_('Next'));
    }
  }
  onBackButtonClick() {
    if (this.state.page > 0) {
      let nextPage = this.state.page - 1;
      if (this.currentPage() === 'summary') {
        const hasHostEnvs = this.props.envs.some((env, index) => {
          return env.get('mode') === ENV_MODES.HOST;
        });

        if (!hasHostEnvs) {
          nextPage = this.state.page - 2;
        }
      }

      this.setState({ page: nextPage });
    } else if (this.state.page === 0 && this.isAppWizard()) {
      this.onCloseButtonClick();
    }
  }
  onCloseButtonClick() {
    this.props.onClose();
  }
  onNextButtonClick() {
    if (this.state.page < this.pages.length - 1) {
      let nextPage = this.state.page + 1;
      if (this.currentPage() === 'envs') {
        const hasHostEnvs = this.props.envs.some((env, index) => {
          return env.get('mode') === ENV_MODES.HOST;
        });

        if (!hasHostEnvs) {
          nextPage = this.state.page + 2;
        }
      }

      this.setState({ page: nextPage });
    }
  }
  render() {
    return (
      <Modal
        autoFocus={this.props.autoFocus}
        backdrop={this.props.static ? 'static' : undefined}
        show={this.props.show}
      >
        <Modal.Body>
          {this.currentPage() === 'welcome' && <WelcomePageView />}

          {this.currentPage() === 'app' && <AppPageView />}

          {this.currentPage() === 'envs' && <EnvsPageView />}

          {this.currentPage() === 'hosts' && <HostsPageView />}

          {this.currentPage() === 'summary' && <SummaryPageView />}

          {this.currentPage() === 'apply' && (
            <ApplyPageView mode={this.props.mode} />
          )}

          {this.currentPage() === 'done' && <DonePageView />}

          {this.currentPage() === 'done_app_wizard' && (
            <AppWizardDonePageView />
          )}
        </Modal.Body>
        <Modal.Footer>
          {this.props.backEnabled && (
            <ModalButton onClick={this.onBackButtonClick}>
              {this.props.backTitle}
            </ModalButton>
          )}
          {this.props.nextEnabled && (
            <ModalButton right onClick={this.onNextButtonClick}>
              {this.props.nextTitle}
            </ModalButton>
          )}
          {SETUP_WIZARD_DONE_PAGES.indexOf(this.currentPage()) !== -1 && (
            <ModalButton right onClick={this.onCloseButtonClick}>
              {_('Close')}
            </ModalButton>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

ModalView.propTypes = {
  actions: PropTypes.shape({
    setBackTitle: PropTypes.func.isRequired,
    setNextTitle: PropTypes.func.isRequired
  }),
  autoFocus: PropTypes.bool,
  backEnabled: PropTypes.bool.isRequired,
  backTitle: PropTypes.string.isRequired,
  envs: PropTypes.instanceOf(List).isRequired,
  mode: requiredSymbolPropType,
  nextEnabled: PropTypes.bool.isRequired,
  nextTitle: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  static: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    autoFocus: props.autoFocus,
    backEnabled: state.setupWizard.get('backEnabled'),
    backTitle: state.setupWizard.get('backTitle'),
    envs: state.setupWizard.get('envs'),
    nextEnabled: state.setupWizard.get('nextEnabled'),
    nextTitle: state.setupWizard.get('nextTitle'),
    mode: props.mode,
    show: props.show,
    static: props.static,
    onClose: props.onClose
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      setBackTitle: SetupWizardActions.setBackTitle,
      setNextTitle: SetupWizardActions.setNextTitle
    },
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(mapStateToProps, mapDispatchToProps)(ModalView);

export default reduxContainer;
