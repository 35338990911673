/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import URI from 'urijs';

import Config from 'src/config';
import { get } from 'src/lib/requests';

export const list = (filters, onSuccess, onError) => {
  let requestURL = new URI(Config.get('api_base_url'));
  requestURL = URI.joinPaths(requestURL, '/release');

  if (filters && filters.includeClosedReleases) {
    requestURL.setSearch({ includeClosedReleases: true });
  }

  return get(requestURL, onSuccess, onError);
};
