/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push as pushRoute } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { setDocumentTitle } from 'redux-doctitle';
import underscore from 'underscore';

import {
  Alert,
  Button,
  FormControl,
  FormGroup,
  FullWindowContainer
} from 'src/components';
import _ from 'src/lib/i18n.js';
import { autoDispatchActionCreator } from 'src/lib/redux';

import * as CreateNewPasswordActions from '../actions/create_new_password';

export class CreateNewPasswordView extends React.Component {
  constructor(props) {
    super(props);
    this.onLoginButtonClick = this.onLoginButtonClick.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onRepeatPasswordChange = this.onRepeatPasswordChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      password: '',
      passwordValid: true,
      repeatPassword: '',
      repeatPasswordValid: true
    };
  }
  componentDidMount() {
    this.props.actions.setDocumentTitle(_('Create new password'));
  }
  componentWillUnmount() {
    this.props.actions.cleanup();
  }
  validatePassword(value) {
    let result = true;
    result = result && value.length > 0;

    return { passwordValid: result };
  }
  validateRepeatPassword(value, otherValue) {
    let result = true;
    result = result && value.length > 0 && value === otherValue;

    return { repeatPasswordValid: result };
  }
  validateAll() {
    const newState = {};
    underscore.extendOwn(
      newState,
      this.validatePassword(this.state.password),
      this.validateRepeatPassword(
        this.state.repeatPassword,
        this.state.password
      )
    );

    return newState;
  }
  onLoginButtonClick(event) {
    this.props.actions.pushRoute('/auth/login');
  }
  onPasswordChange(event) {
    const newValue = event.target.value;
    const newState = { password: newValue };
    underscore.extendOwn(
      newState,
      this.validatePassword(newValue),
      this.validateRepeatPassword(this.state.repeatPassword, newValue)
    );

    this.setState(newState);
  }
  onRepeatPasswordChange(event) {
    const newValue = event.target.value;
    const newState = { repeatPassword: newValue };
    underscore.extendOwn(
      newState,
      this.validatePassword(this.state.password),
      this.validateRepeatPassword(newValue, this.state.password)
    );

    this.setState(newState);
  }
  onSubmit(event) {
    event.stopPropagation();
    event.preventDefault();

    const validationResult = this.validateAll();

    if (underscore.all(underscore.values(validationResult))) {
      this.props.actions.createNewPasswordStart(
        this.props.nonce,
        this.state.password
      );
    } else {
      this.setState(validationResult);
    }
  }
  render() {
    return (
      <FullWindowContainer cols={4} id="CreateNewPasswordView">
        {this.props.error && (
          <Alert bsStyle="danger">
            <b>{_('Create new password error.')}</b>
            &nbsp;
            {_('Please check the details and try again.')}
          </Alert>
        )}

        {!this.props.success && (
          <form onSubmit={this.onSubmit}>
            <FormGroup
              controlId="password"
              validationState={this.state.passwordValid ? null : 'error'}
            >
              <FormControl
                id="password"
                type="password"
                placeholder={_('Password')}
                disabled={this.props.submitting}
                value={this.state.password}
                onChange={this.onPasswordChange}
              />
            </FormGroup>
            <FormGroup
              controlId="repeatPassword"
              validationState={this.state.repeatPasswordValid ? null : 'error'}
            >
              <FormControl
                id="repeatPassword"
                type="password"
                placeholder={_('Repeat Password')}
                disabled={this.props.submitting}
                value={this.state.repeatPassword}
                onChange={this.onRepeatPasswordChange}
              />
            </FormGroup>
            <div className="text-center">
              <Button
                bsStyle="primary"
                type="submit"
                disabled={this.props.submitting}
              >
                {this.props.submitting ? _('Saving...') : _('Save')}
              </Button>
            </div>
          </form>
        )}

        {this.props.success && (
          <React.Fragment>
            <Alert bsStyle="success">
              {_('Your new password has been saved.')}
            </Alert>
            <div className="text-center">
              <Button bsStyle="primary" onClick={this.onLoginButtonClick}>
                {_('Log in')}
              </Button>
            </div>
          </React.Fragment>
        )}
      </FullWindowContainer>
    );
  }
}

CreateNewPasswordView.propTypes = {
  actions: PropTypes.shape({
    cleanup: PropTypes.func.isRequired,
    pushRoute: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired,
    createNewPasswordStart: PropTypes.func.isRequired
  }),
  error: PropTypes.string,
  nonce: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    error: state.createNewPassword.get('error'),
    nonce: props.params.nonce,
    submitting: state.createNewPassword.get('submitting'),
    success: state.createNewPassword.get('success')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      cleanup: CreateNewPasswordActions.cleanup,
      pushRoute: pushRoute,
      setDocumentTitle: setDocumentTitle
    },
    dispatch
  );

  actions.createNewPasswordStart = autoDispatchActionCreator(
    CreateNewPasswordActions.start,
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewPasswordView);

export default reduxContainer;
