/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import API from 'src/lib/api';

import { RC_DASHBOARD_ACTIONS } from '../defs';

export const cleanup = () => {
  return {
    type: RC_DASHBOARD_ACTIONS.CLEANUP
  };
};

export const loadData = (dispatch, filters) => {
  const onSuccess = data => {
    dispatch(onLoadDataSuccess(data));
  };

  const onError = error => {
    dispatch(onLoadDataError(error));
  };

  if (filters.application && !filters.env) {
    API.Application.getOrders(
      filters,
      ['release_order_results'],
      onSuccess,
      onError
    );
  } else if (filters.application && filters.env) {
    API.Application.getOrdersForEnv(filters, onSuccess, onError);
  } else {
    API.Orders.list(filters, ['release_order_results'], onSuccess, onError);
  }

  return {
    type: RC_DASHBOARD_ACTIONS.LOAD_DATA_START
  };
};

export const onLoadDataSuccess = data => {
  return {
    type: RC_DASHBOARD_ACTIONS.LOAD_DATA_SUCCESS,
    orders: data
  };
};

export const onLoadDataError = error => {
  return {
    type: RC_DASHBOARD_ACTIONS.LOAD_DATA_ERROR,
    error: error
  };
};

export const setApplicationFilter = application => {
  return {
    type: RC_DASHBOARD_ACTIONS.SET_APPLICATION_FILTER,
    application: application
  };
};

export const setEnvFilter = env => {
  return {
    type: RC_DASHBOARD_ACTIONS.SET_ENV_FILTER,
    env: env
  };
};

export const setTimelineFilter = timeline => {
  return {
    type: RC_DASHBOARD_ACTIONS.SET_TIMELINE_FILTER,
    timeline: timeline
  };
};

export const loadApplications = dispatch => {
  API.Application.list(
    ['envs'],
    data => {
      dispatch(onLoadApplicationsSuccess(data));
    },
    error => {
      dispatch(onLoadApplicationsError(error));
    }
  );

  return {
    type: RC_DASHBOARD_ACTIONS.LOAD_APPLICATIONS_START
  };
};

export const onLoadApplicationsSuccess = data => {
  return {
    type: RC_DASHBOARD_ACTIONS.LOAD_APPLICATIONS_SUCCESS,
    applications: data
  };
};

export const onLoadApplicationsError = error => {
  return {
    type: RC_DASHBOARD_ACTIONS.LOAD_APPLICATIONS_ERROR,
    error: error
  };
};

export const setIncludeClosedReleasesFilter = includeClosedReleases => {
  return {
    type: RC_DASHBOARD_ACTIONS.SET_INCLUDE_CLOSED_RELEASES,
    includeClosedReleases: includeClosedReleases
  };
};

export const loadReleases = (dispatch, filters) => {
  API.Release.list(
    filters,
    data => {
      dispatch(onLoadReleasesSuccess(data));
    },
    error => {
      dispatch(onLoadReleasesError(error));
    }
  );

  return {
    type: RC_DASHBOARD_ACTIONS.LOAD_RELEASES_START
  };
};

export const onLoadReleasesSuccess = data => {
  return {
    type: RC_DASHBOARD_ACTIONS.LOAD_RELEASES_SUCCESS,
    releases: data
  };
};

export const onLoadReleasesError = error => {
  return {
    type: RC_DASHBOARD_ACTIONS.LOAD_RELEASES_ERROR,
    error: error
  };
};

export const setReleaseFilter = data => {
  return {
    type: RC_DASHBOARD_ACTIONS.SET_RELEASE_FILTER,
    release: data
  };
};

export const setSearchString = searchString => {
  return {
    type: RC_DASHBOARD_ACTIONS.SET_SEARCH_STRING,
    searchString: searchString
  };
};

export const addNewChange = (dispatch, params) => {
  API.Orders.addNewChange(
    params,
    data => {
      dispatch(onAddNewChangeSuccess(data, params));
      dispatch(addApplicationsToChange(dispatch, params));
      dispatch(addApproversToChange(dispatch, params));
    },
    (error, response) => {
      dispatch(onAddNewChangeError(error, response.statusCode));
    }
  );

  return {
    type: RC_DASHBOARD_ACTIONS.ADD_NEW_CHANGE_START
  };
};

export const onAddNewChangeSuccess = data => {
  return {
    type: RC_DASHBOARD_ACTIONS.ADD_NEW_CHANGE_SUCCESS,
    newChangeStatus: data
  };
};

export const onAddNewChangeError = (error, statusCode) => {
  return {
    type: RC_DASHBOARD_ACTIONS.ADD_NEW_CHANGE_ERROR,
    error: { error, statusCode }
  };
};

export const addApplicationsToChange = (dispatch, params) => {
  API.Orders.addApplicationsToChange(
    params,
    data => {
      dispatch(onAddApplicationsToChangeSuccess(data, params));
      dispatch(addEnvsToApplication(dispatch, params));
    },
    (error, response) => {
      dispatch(onAddApplicationsToChangeError(error, response.statusCode));
    }
  );

  return {
    type: RC_DASHBOARD_ACTIONS.ADD_APPLICATIONS_TO_CHANGE_START
  };
};

export const onAddApplicationsToChangeSuccess = data => {
  return {
    type: RC_DASHBOARD_ACTIONS.ADD_APPLICATIONS_TO_CHANGE_SUCCESS,
    newChangeStatus: data
  };
};

export const onAddApplicationsToChangeError = (error, statusCode) => {
  return {
    type: RC_DASHBOARD_ACTIONS.ADD_APPLICATIONS_TO_CHANGE_ERROR,
    error: { error, statusCode }
  };
};

export const addEnvsToApplication = (dispatch, params) => {
  if (!params || !params.applications) return;

  params.applications.forEach(app => {
    const data = {
      name: params.name,
      changeName: params.changeName,
      appName: app.name,
      appVersion: app.version,
      envName: app.env
    };
    dispatch(addEnvToApplication(dispatch, data));
  });

  return {
    type: RC_DASHBOARD_ACTIONS.ADD_ENVS_TO_CHANGE_START
  };
};

export const addEnvToApplication = (dispatch, params) => {
  API.Orders.addEnvToApplication(
    params,
    data => {
      dispatch(onAddEnvToApplicationSuccess(data));
    },
    (error, response) => {
      dispatch(onAddEnvToApplicationError(error, response.statusCode));
    }
  );

  return {
    type: RC_DASHBOARD_ACTIONS.ADD_ENV_TO_APPLICATION_START
  };
};

export const onAddEnvToApplicationSuccess = data => {
  return {
    type: RC_DASHBOARD_ACTIONS.ADD_ENV_TO_APPLICATION_SUCCESS,
    newChangeStatus: data
  };
};

export const onAddEnvToApplicationError = (error, statusCode) => {
  return {
    type: RC_DASHBOARD_ACTIONS.ADD_ENV_TO_APPLICATION_ERROR,
    error: { error, statusCode }
  };
};

export const addApproversToChange = (dispatch, params) => {
  API.Orders.addApproversToChange(
    params,
    data => {
      dispatch(onAddApproversToChangeSuccess(data));
    },
    (error, response) => {
      dispatch(onAddApproversToChangeError(error, response.statusCode));
    }
  );

  return {
    type: RC_DASHBOARD_ACTIONS.ADD_APPROVER_TO_CHANGE_START
  };
};

export const onAddApproversToChangeSuccess = data => {
  return {
    type: RC_DASHBOARD_ACTIONS.ADD_APPROVER_TO_CHANGE_SUCCESS,
    newChangeStatus: data
  };
};

export const onAddApproversToChangeError = (error, statusCode) => {
  return {
    type: RC_DASHBOARD_ACTIONS.ADD_APPROVER_TO_CHANGE_ERROR,
    error: { error, statusCode }
  };
};
