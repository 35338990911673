/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Enum from 'es6-enum';

import { ActionTypes } from 'src/lib/redux';

export const SETUP_WIZARD_ACTIONS = ActionTypes('SETUP_WIZARD', [
  'ENABLE_BACK',
  'DISABLE_BACK',
  'ENABLE_NEXT',
  'DISABLE_NEXT',
  'SET_BACK_TITLE',
  'SET_NEXT_TITLE',
  'SET_APP_NAME',
  'ADD_ENV',
  'REMOVE_ENV',
  'SET_ENV_NAME',
  'SET_ENV_USER',
  'SET_ENV_AWS_TAGS',
  'SET_ENV_MODE',
  'ADD_HOST',
  'REMOVE_HOST',
  'SET_HOST_ENV',
  'SET_HOST_IP',
  'SET_HOST_NAME',
  'APPLY_START',
  'APPLY_SUCCESS',
  'APPLY_ERROR',
  'APPLY_SETTINGS_START',
  'APPLY_SETTINGS_SUCCESS',
  'APPLY_SETTINGS_ERROR',
  'APPLY_START',
  'CLEANUP'
]);

export const SETUP_WIZARD_APPLY_STATUS = Enum(
  'WAITING',
  'APPLYING',
  'SUCCESS',
  'ERROR',
  'CONFLICT'
);

export const SETUP_WIZARD_SETTINGS_UPDATE = [
  { key: 'needs_setup_wizard', value: false }
];
