import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Redux from 'redux';
import * as ReduxDoctitle from 'redux-doctitle';
import * as ReactRouter from 'react-router';
import * as ReactRouterRedux from 'react-router-redux';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import App from './App';

export default class Putit {
  constructor() {
    this.history = null;
    this.store = null;

    this.blueprints = [];
    this.navItems = [];
    this.reducers = {};
    this.routes = [];
    this.widgets = {
      sidebar: []
    };
  }
  initHistory() {
    this.history = ReactRouterRedux.syncHistoryWithStore(
      ReactRouter.hashHistory,
      this.store
    );
  }
  initStore() {
    const REACT_ROUTER_MIDDLEWARE = ReactRouterRedux.routerMiddleware(
      ReactRouter.hashHistory
    );
    const DOCUMENT_TITLE_MIDDLEWARE = ReduxDoctitle.documentTitleMiddlewareFactory(
      ['Putit']
    );
    const reduxMiddleware = Redux.applyMiddleware(
      DOCUMENT_TITLE_MIDDLEWARE,
      REACT_ROUTER_MIDDLEWARE
    );

    /// #if development
    //if (REDUX_DEVTOOLS_EXTENSION) {
    //  reduxMiddleware = Redux.compose(
    //    reduxMiddleware,
    //    REDUX_DEVTOOLS_EXTENSION
    //  );
    //}
    /// #endif

    const reducers = Redux.combineReducers(
      Object.assign({}, this.reducers, {
        routing: ReactRouterRedux.routerReducer
      })
    );

    this.store = Redux.createStore(reducers, undefined, reduxMiddleware);
  }
  registerBlueprint(blueprint) {
    this.blueprints.push(blueprint);

    this.reducers = Object.assign({}, this.reducers, blueprint.reducers || {});

    this.routes = this.routes.concat(blueprint.routes || []);

    if (blueprint.navItems) {
      this.navItems = this.navItems.concat(blueprint.navItems);
    }

    if (blueprint.widgets) {
      this.widgets.sidebar = this.widgets.sidebar.concat(
        blueprint.widgets.sidebar || []
      );
    }
  }
  run(container) {
    this.initStore();
    this.initHistory();

    ReactDOM.render(
      <BreadcrumbsProvider>
        <App
          history={this.history}
          navItems={this.navItems}
          routes={this.routes}
          store={this.store}
          widgets={this.widgets}
        />
      </BreadcrumbsProvider>,
      container
    );
  }
}
