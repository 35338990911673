/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Map } from 'immutable';

import { ERRORS_ACTIONS } from '../defs';

const DEFAULT_STATE = Map({
  event: undefined
});

const errorsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ERRORS_ACTIONS.REPORT:
      return state.set('event', action.event);

    case ERRORS_ACTIONS.CLEAR:
      return state.set('event', undefined);

    default:
      return state;
  }
};

export default errorsReducer;
