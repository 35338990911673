/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ClassName from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export class ActionButton extends React.Component {
  render() {
    return (
      <span className="action-button" onClick={this.props.onClick}>
        {this.props.children}
      </span>
    );
  }
}

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export const Actions = props => {
  const className = ClassName('actions', props.className, {
    'text-center': props.center
  });

  return <div className={className}>{props.children}</div>;
};
