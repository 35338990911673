/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import RCP from '@bthlabs/react-custom-popup';
import ClassName from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import underscore from 'underscore';

export const Popup = props => {
  const popupProps = underscore.extendOwn(
    {},
    underscore.omit(props, ['children']),
    {
      className: ClassName('putit-popup', props.className, {
        'putit-modal': props.modal
      })
    }
  );

  if (props.anchor && props.anchor.current) {
    let actualAnchor = props.anchor;
    if (!(props.anchor.current instanceof HTMLElement)) {
      actualAnchor = {
        current: ReactDOM.findDOMNode(props.anchor.current)
      };
    }

    popupProps.anchor = actualAnchor;
  }

  return <RCP {...popupProps}>{props.children}</RCP>;
};
