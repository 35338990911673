/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as Actions from './Actions';
import * as Cells from './Cells';
import { Column } from './Column';
import { Head } from './Head';
import * as Headers from './Headers';
import { Row } from './Row';
import { ScrollerButton } from './ScrollerButton';
import { ScrollView } from './ScrollView';
import { ScrollViewWithButtons } from './ScrollViewWithButtons';
import { Table } from './Table';

export default {
  ActionButton: Actions.ActionButton,
  Actions: Actions.Actions,
  AppNameCell: Cells.AppNameCell,
  AppNameHeader: Headers.AppNameHeader,
  Cell: Cells.Cell,
  Column: Column,
  GlyphiconCell: Cells.GlyphiconCell,
  Head: Head,
  Header: Headers.Header,
  LabelCell: Cells.LabelCell,
  LoaderCell: Cells.LoaderCell,
  Row: Row,
  RowHeaderCell: Cells.RowHeaderCell,
  ScrollerButton: ScrollerButton,
  ScrollView: ScrollView,
  ScrollViewWithButtons,
  SegmentedControlCell: Cells.SegmentedControlCell,
  Table: Table
};
