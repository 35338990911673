/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push as pushRoute } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { setDocumentTitle } from 'redux-doctitle';
import underscore from 'underscore';

import {
  Alert,
  Button,
  FormControl,
  FormGroup,
  FullWindowContainer,
  SegmentedControl
} from 'src/components';
import _ from 'src/lib/i18n.js';
import { autoDispatchActionCreator } from 'src/lib/redux';

import * as LoginActions from '../actions/login';

export class LoginView extends React.Component {
  constructor(props) {
    super(props);
    this.onLoginChange = this.onLoginChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onResetPasswordButtonClick = this.onResetPasswordButtonClick.bind(
      this
    );
    this.onSignUpButtonClick = this.onSignUpButtonClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      login: '',
      loginValid: true,
      password: '',
      passwordValid: true
    };
  }
  componentDidMount() {
    this.props.actions.setDocumentTitle(_('Log in'));
  }
  componentWillUnmount() {
    this.props.actions.cleanup();
  }
  validateLogin(value) {
    let result = true;
    result = result && value.length > 0;

    return { loginValid: result };
  }
  validatePassword(value) {
    let result = true;
    result = result && value.length > 0;

    return { passwordValid: result };
  }
  validateAll() {
    const newState = {};
    underscore.extendOwn(
      newState,
      this.validateLogin(this.state.login),
      this.validatePassword(this.state.password)
    );

    return newState;
  }
  onLoginChange(event) {
    const newValue = event.target.value;
    const newState = { login: newValue };
    underscore.extendOwn(newState, this.validateLogin(newValue));

    this.setState(newState);
  }
  onPasswordChange(event) {
    const newValue = event.target.value;
    const newState = { password: newValue };
    underscore.extendOwn(newState, this.validatePassword(newValue));

    this.setState(newState);
  }
  onResetPasswordButtonClick(event) {
    this.props.actions.pushRoute('/auth/reset_password/');
  }
  onSignUpButtonClick(event) {
    this.props.actions.pushRoute('/auth/signup');
  }
  onSubmit(event) {
    event.stopPropagation();
    event.preventDefault();

    const validationResult = this.validateAll();

    if (validationResult.loginValid && validationResult.passwordValid) {
      this.props.actions.loginStart(this.state.login, this.state.password);
    } else {
      this.setState(validationResult);
    }
  }
  render() {
    return (
      <FullWindowContainer cols={4} id="LoginView">
        {this.props.error && (
          <Alert bsStyle="danger">
            <b>{_('Login error.')}</b>{' '}
            {_('Please check the details and try again.')}
          </Alert>
        )}

        <form onSubmit={this.onSubmit}>
          <FormGroup
            controlId="login"
            validationState={this.state.loginValid ? null : 'error'}
          >
            <FormControl
              id="login"
              type="text"
              placeholder={_('Login')}
              disabled={this.props.submitting}
              value={this.state.login}
              onChange={this.onLoginChange}
            />
          </FormGroup>
          <FormGroup
            controlId="password"
            validationState={this.state.passwordValid ? null : 'error'}
          >
            <FormControl
              id="password"
              type="password"
              placeholder={_('Password')}
              disabled={this.props.submitting}
              value={this.state.password}
              onChange={this.onPasswordChange}
            />
          </FormGroup>
          <FormGroup>
            <div className="text-center">
              <Button
                bsStyle="default"
                type="submit"
                disabled={this.props.submitting}
              >
                {this.props.submitting ? _('Logging in...') : _('Log in')}
              </Button>
            </div>
          </FormGroup>
          <FormGroup>
            <SegmentedControl>
              <Button
                id="sign_up"
                bsStyle="link"
                disabled={this.props.submitting}
                onClick={this.onSignUpButtonClick}
              >
                {_('Sign up')}
              </Button>
              <Button
                id="reset_password"
                bsStyle="link"
                disabled={this.props.submitting}
                onClick={this.onResetPasswordButtonClick}
              >
                {_('Reset password')}
              </Button>
            </SegmentedControl>
          </FormGroup>
        </form>
      </FullWindowContainer>
    );
  }
}

LoginView.propTypes = {
  actions: PropTypes.shape({
    cleanup: PropTypes.func.isRequired,
    loginStart: PropTypes.func.isRequired,
    pushRoute: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired
  }),
  error: PropTypes.string,
  submitting: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    error: state.login.get('error'),
    submitting: state.login.get('submitting')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      cleanup: LoginActions.cleanup,
      pushRoute: pushRoute,
      setDocumentTitle: setDocumentTitle
    },
    dispatch
  );

  actions.loginStart = autoDispatchActionCreator(LoginActions.start, dispatch);

  return {
    actions: actions
  };
};

const reduxContainer = connect(mapStateToProps, mapDispatchToProps)(LoginView);

export default reduxContainer;
