/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ClassName from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import underscore from 'underscore';

import _ from 'src/lib/i18n.js';
import { ReactComponent as LoaderBig } from 'src/resources/loader_big.svg';
import { ReactComponent as LoaderSmall } from 'src/resources/loader_small.svg';

const SmallLoader = props => {
  return (
    <React.Fragment>
      <p>{props.text}</p>
      <LoaderSmall className={props.loaderClassName} />
    </React.Fragment>
  );
};

const BigLoader = props => {
  return (
    <React.Fragment>
      <LoaderBig />
      <p className="lead text-center">{props.text}</p>
    </React.Fragment>
  );
};

export class Loader extends React.Component {
  render() {
    const text = this.props.text || _('Loading...');
    const className = ClassName('putit-loader', {
      animate: underscore.isBoolean(this.props.animate)
        ? this.props.animate
        : true,
      'variant-small': this.props.small
    });

    return (
      <div className={className}>
        {!this.props.small && <BigLoader text={text} />}
        {this.props.small && <SmallLoader text={text} />}
      </div>
    );
  }
}

Loader.propTypes = {
  animate: PropTypes.bool,
  small: PropTypes.bool,
  text: PropTypes.string
};
