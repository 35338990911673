/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ActionTypes } from 'src/lib/redux';

export const RC_DASHBOARD_ACTIONS = ActionTypes('RC_DASHBOARD', [
  'LOAD_DATA_START',
  'LOAD_DATA_SUCCESS',
  'LOAD_DATA_ERROR',
  'LOAD_APPLICATIONS_START',
  'LOAD_APPLICATIONS_SUCCESS',
  'LOAD_APPLICATIONS_ERROR',
  'SET_APPLICATION_FILTER',
  'SET_TIMELINE_FILTER',
  'SET_INCLUDE_CLOSED_RELEASES',
  'SET_ENV_FILTER',
  'LOAD_RELEASES_START',
  'LOAD_RELEASES_SUCCESS',
  'LOAD_RELEASES_ERROR',
  'SET_RELEASE_FILTER',
  'SET_SEARCH_STRING',
  'CLEANUP',
  'ADD_NEW_CHANGE_START',
  'ADD_NEW_CHANGE_SUCCESS',
  'ADD_NEW_CHANGE_ERROR',
  'ADD_APPLICATIONS_TO_CHANGE_START',
  'ADD_APPLICATIONS_TO_CHANGE_SUCCESS',
  'ADD_APPLICATIONS_TO_CHANGE_ERROR',
  'ADD_ENVS_TO_CHANGE_START',
  'ADD_ENV_TO_APPLICATION_START',
  'ADD_ENV_TO_APPLICATION_SUCCESS',
  'ADD_ENV_TO_APPLICATION_ERROR',
  'ADD_APPROVER_TO_CHANGE_START',
  'ADD_APPROVER_TO_CHANGE_SUCCESS',
  'ADD_APPROVER_TO_CHANGE_ERROR'
]);
