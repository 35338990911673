/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import underscore from 'underscore';

import { Col, Grid, Row } from 'src/components/Bootstrap';
import { Loader } from 'src/components/Loader';

const DEFAULT_COLS = 6;
const GRID_COLUMNS = 12;

export const FullWindowContainer = props => {
  const cols = underscore.isUndefined(props.cols) ? DEFAULT_COLS : props.cols;

  const offset = (GRID_COLUMNS - cols) / 2;

  return (
    <div id={props.id} className="full-window-container">
      <h1>Putit</h1>
      <Grid>
        <Row>
          <Col md={cols} mdOffset={offset}>
            {props.children}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export const FullWindowLoader = props => {
  return (
    <div id={props.id} className="full-window-container full-window-loader">
      <h1>Putit</h1>

      <Loader />

      {props.text && <p className="lead text-center">{props.text}</p>}
    </div>
  );
};
