/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import underscore from 'underscore';

const COLUMN_WIDTH = 250;

export class Table extends React.Component {
  constructor(props) {
    super(props);
    this.scrollViewRef = this.scrollViewRef.bind(this);
    this.onScrollLeft = this.onScrollLeft.bind(this);

    this.scrollView = null;
    this.state = {
      scrollLeft: 0
    };
  }
  getChildContext() {
    return {
      scrollLeft: this.state.scrollLeft,
      scrollViewRef: this.scrollViewRef,
      onScrollLeft: this.onScrollLeft
    };
  }
  scrollViewRef(element) {
    this.scrollView = element;
  }
  onScrollLeft(direction) {
    if (this.scrollView) {
      const scrollViewNode = ReactDOM.findDOMNode(this.scrollView);
      const scrollViewWidth = scrollViewNode.getBoundingClientRect().width;

      const totalScrollViewWidth = underscore.reduce(
        underscore.map(scrollViewNode.childNodes, function(childNode) {
          return childNode.getBoundingClientRect().width;
        }),
        function(totalWidth, width) {
          return totalWidth + width;
        },
        0
      );

      const maxScrollLeft = totalScrollViewWidth - scrollViewWidth;

      if (totalScrollViewWidth > scrollViewWidth) {
        let newScrollLeft = this.state.scrollLeft + COLUMN_WIDTH * direction;

        if (newScrollLeft < 0) {
          newScrollLeft = 0;
        } else if (newScrollLeft > maxScrollLeft) {
          newScrollLeft = maxScrollLeft;
        } else {
          if (newScrollLeft % COLUMN_WIDTH !== 0) {
            newScrollLeft = newScrollLeft - (newScrollLeft % COLUMN_WIDTH);
          }
        }

        this.setState({
          scrollLeft: newScrollLeft
        });
      }
    }
  }
  render() {
    return (
      <div className={`putit-table ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }
}

Table.childContextTypes = {
  scrollLeft: PropTypes.number,
  scrollViewRef: PropTypes.func,
  onScrollLeft: PropTypes.func
};
