/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';

import { Alert } from 'src/components';

export class NotificationCenterAlert extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dismiss = this.dismiss.bind(this);
    this.onClick = this.onClick.bind(this);

    this.autoDismissTimeout = null;
  }
  componentDidMount() {
    this.autoDismissTimeout = window.setTimeout(this.dismiss, 3000);
  }
  componentWillUnmount() {
    window.clearTimeout(this.autoDismissTimeout);
  }
  dismiss() {
    window.clearTimeout(this.autoDismissTimeout);
    this.autoDismissTimeout = null;

    this.props.dismiss(this.props.identifier);
  }
  onClick(event) {
    event.stopPropagation();
    event.preventDefault();

    this.dismiss();
  }
  render() {
    return (
      <Alert bsStyle={this.props.kind || 'info'} onClick={this.onClick}>
        {this.props.title && (
          <p className="title">
            <strong>{this.props.title}</strong>
          </p>
        )}
        {this.props.subtitle && (
          <p className="subtitle">
            <small>{this.props.subtitle}</small>
          </p>
        )}
        <p className="text">{this.props.text}</p>
      </Alert>
    );
  }
}

NotificationCenterAlert.propTypes = {
  dismiss: PropTypes.func.isRequired,
  identifier: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  kind: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string
};
