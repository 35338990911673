/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDocumentTitle } from 'redux-doctitle';

import { historyShape } from 'src/lib/defs';

import * as WelcomeActions from '../actions/welcome';
import { Welcome } from '../components';

export class WelcomeProvider extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.welcomeVisible !== nextProps.welcomeVisible) {
      if (nextProps.welcomeVisible) {
        this.props.actions.setDocumentTitle('');
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.history.getCurrentLocation().pathname === '/auth/login') {
      if (!this.props.verifyingAuth) {
        this.props.actions.hideWelcomeView();
      } else {
        this.props.actions.showWelcomeView();
      }
    } else if (
      this.props.history.getCurrentLocation().pathname.startsWith('/auth/')
    ) {
      this.props.actions.hideWelcomeView();
    } else {
      const shouldShowWelcomeView =
        this.props.messagesLoaded !== true ||
        this.props.settingsLoaded !== true;

      if (!shouldShowWelcomeView && this.props.welcomeVisible) {
        this.props.actions.hideWelcomeView();
      } else if (shouldShowWelcomeView && !this.props.welcomeVisible) {
        this.props.actions.showWelcomeView();
      }
    }
  }
  render() {
    if (this.props.welcomeVisible) {
      return <Welcome />;
    }

    return this.props.children;
  }
}

WelcomeProvider.propTypes = {
  actions: PropTypes.shape({
    hideWelcomeView: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired,
    showWelcomeView: PropTypes.func.isRequired
  }),
  history: historyShape,
  messagesLoaded: PropTypes.bool,
  settingsLoaded: PropTypes.bool,
  verifyingAuth: PropTypes.bool.isRequired,
  welcomeVisible: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    history: props.history,
    messagesLoaded: state.settings.get('messagesLoaded'),
    settingsLoaded: state.settings.get('settingsLoaded'),
    verifyingAuth: state.auth.get('verifying'),
    welcomeVisible: state.welcome.get('visible')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      hideWelcomeView: WelcomeActions.hide,
      setDocumentTitle: setDocumentTitle,
      showWelcomeView: WelcomeActions.show
    },
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WelcomeProvider);

export default reduxContainer;
