/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { requiredSymbolPropType } from 'src/lib/defs';
import _ from 'src/lib/i18n';
import { autoDispatchActionCreator } from 'src/lib/redux';

import * as SetupWizardActions from '../actions/setup_wizard';
import { ApplyStatus } from '../components';
import { SETUP_WIZARD_APPLY_STATUS, SETUP_WIZARD_MODAL_MODE } from '../defs';

export class ApplyPageView extends React.Component {
  componentDidMount() {
    this.props.actions.disableBack();
    this.props.actions.disableNext();

    this.props.actions.apply(
      this.props.setupWizard.get('appName'),
      this.props.setupWizard.get('envs').toJS(),
      this.props.setupWizard.get('hosts').toJS(),
      this.props.mode
    );
  }
  componentDidUpdate(prevProps) {
    const enableNext = this.props.setupWizard
      .get('applyStatus')
      .every((applyStatus, key) => {
        return applyStatus === SETUP_WIZARD_APPLY_STATUS.SUCCESS;
      });

    if (enableNext) {
      this.props.actions.enableNext();
    }
  }
  render() {
    return (
      <div id="ApplyPageView">
        <h4>{_('Applying setup')}</h4>
        <p>{_('Your setup is being applied by Putit.')}</p>

        <dl className="dl-horizontal">
          <dt>{_('Setup')}</dt>
          <dd>
            <ApplyStatus
              conflicts={this.props.setupWizard.get('applyConflicts')}
              envs={this.props.setupWizard.get('envs')}
              hosts={this.props.setupWizard.get('hosts')}
              errors={this.props.setupWizard.get('applyErrors')}
              status={this.props.setupWizard.getIn(['applyStatus', 'setup'])}
            />
          </dd>
          {this.props.mode === SETUP_WIZARD_MODAL_MODE.SETUP_WIZARD && (
            <React.Fragment>
              <dt>{_('Final settings')}</dt>
              <dd>
                <ApplyStatus
                  status={this.props.setupWizard.getIn([
                    'applyStatus',
                    'settings'
                  ])}
                />
              </dd>
            </React.Fragment>
          )}
        </dl>
      </div>
    );
  }
}

ApplyPageView.propTypes = {
  actions: PropTypes.shape({
    apply: PropTypes.func.isRequired,
    disableBack: PropTypes.func.isRequired,
    disableNext: PropTypes.func.isRequired,
    enableNext: PropTypes.func.isRequired
  }),
  mode: requiredSymbolPropType,
  setupWizard: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    mode: props.mode,
    setupWizard: state.setupWizard
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      disableBack: SetupWizardActions.disableBack,
      disableNext: SetupWizardActions.disableNext,
      enableNext: SetupWizardActions.enableNext
    },
    dispatch
  );

  actions.apply = autoDispatchActionCreator(SetupWizardActions.apply, dispatch);

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplyPageView);

export default reduxContainer;
