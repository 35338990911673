/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ClassName from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import BootrapFormControl from 'react-bootstrap/lib/FormControl';
import underscore from 'underscore';

import { Popup } from 'src/components/Popup';
import Browser from 'src/lib/browser';
import { ControlLabel } from '.';

const HAS_PLACEHOLDER = Browser.hasPlaceholder();

export const FakePlaceholder = props => {
  return (
    <span className="fake-placeholder" onClick={props.onClick}>
      {props.children}
    </span>
  );
};

export class FormControl extends React.PureComponent {
  constructor(props) {
    super(props);
    this.input = null;
    this.onControlBlur = this.onControlBlur.bind(this);
    this.onControlFocus = this.onControlFocus.bind(this);
    this.onFakePlaceholderClick = this.onFakePlaceholderClick.bind(this);

    this.state = {
      focus: false
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.disabled === true) {
      this.setState({ focus: false });
    }
  }
  onControlBlur() {
    this.setState({ focus: false });

    if (underscore.isFunction(this.props.onBlur)) {
      this.props.onBlur();
    }
  }
  onControlFocus(event) {
    this.setState({ focus: true });

    if (underscore.isFunction(this.props.onFocus)) {
      this.props.onFocus(event);
    }
  }
  onFakePlaceholderClick() {
    if (!this.state.focus && !this.props.disabled) {
      this.input.focus();
    }
  }
  render() {
    const isTextarea = this.props.componentClass === 'textarea';
    const wrapperClassname = ClassName(this.props.wrapperClassName, {
      'input-wrapper': underscore.isUndefined(this.props.componentClass),
      'textarea-wrapper': isTextarea,
      disabled: this.props.disabled,
      focus: this.state.focus,
      'has-value':
        !underscore.isUndefined(this.props.value) && this.props.value !== ''
    });

    const controlProps = underscore.clone(this.props);
    controlProps.inputRef = element => {
      this.input = element;
    };
    controlProps.onBlur = this.onControlBlur;
    controlProps.onFocus = this.onControlFocus;
    delete controlProps.wrapperClassName;

    const hasFakePlaceholder =
      !HAS_PLACEHOLDER &&
      (isTextarea ||
        underscore.contains(['text', 'password'], this.props.type));

    if (hasFakePlaceholder) {
      controlProps.placeholder = undefined;
    }

    return (
      <div className={wrapperClassname}>
        <BootrapFormControl {...controlProps} />
        {hasFakePlaceholder && this.props.placeholder && (
          <FakePlaceholder onClick={this.onFakePlaceholderClick}>
            {this.props.placeholder}
          </FakePlaceholder>
        )}
        {this.props.icon && <span className={this.props.icon}></span>}
      </div>
    );
  }
}

export class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick(event) {
    event.stopPropagation();
    event.preventDefault();

    if (!this.props.disabled && underscore.isFunction(this.props.onChange)) {
      this.props.onChange(!this.props.checked);
    }
  }
  render() {
    const controlClassName = ClassName('control', {
      disabled: this.props.disabled,
      checked: this.props.checked
    });

    return (
      <div onClick={this.onClick} className="checkbox putit-checkbox">
        <label>
          <span className={controlClassName}></span>
          <span className="checkbox-label">{this.props.label}</span>
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export class Select extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onOptionSelect = this.onOptionSelect.bind(this);
    this.onPopupLayout = this.onPopupLayout.bind(this);
    this.onPopupOverlayClick = this.onPopupOverlayClick.bind(this);

    this.refSelf = React.createRef();

    this.state = {
      expanded: false
    };
  }
  onClick(event) {
    event.stopPropagation();
    event.preventDefault();

    if (!this.props.disabled) {
      this.setState({ expanded: true });
    }
  }
  onOptionSelect(newValue) {
    if (!this.props.disabled) {
      this.props.onChange(newValue);
      this.setState({ expanded: false });
    }
  }
  onPopupLayout(layout) {
    if (this.refSelf && this.refSelf.current) {
      const selfRect = this.refSelf.current.getBoundingClientRect();
      return [layout[0], layout[1], selfRect.width];
    }

    return layout;
  }
  onPopupOverlayClick(event) {
    event.stopPropagation();
    event.preventDefault();

    this.setState({ expanded: false });
  }
  render() {
    const className = ClassName('putit-select', {
      disabled: this.props.disabled,
      expanded: this.state.expanded
    });

    let selectedOption = null;
    React.Children.forEach(this.props.children, option => {
      if (option && option.props.value === this.props.value) {
        selectedOption = option;
      }
    });

    return (
      <div className={className} onClick={this.onClick} ref={this.refSelf}>
        <div className="form-control">
          {selectedOption ? selectedOption : this.props.placeholder || ''}
        </div>
        <div className="select-caret"></div>
        <Popup
          anchor={this.refSelf}
          visible={this.state.expanded}
          onLayout={this.onPopupLayout}
          onOverlayClick={this.onPopupOverlayClick}
        >
          <div className="putit-select-dropdown">
            <ul>
              {React.Children.map(this.props.children, option => {
                if (!option) {
                  return null;
                }

                return (
                  <li>
                    {React.cloneElement(option, {
                      onSelect: this.onOptionSelect
                    })}
                  </li>
                );
              })}
            </ul>
          </div>
        </Popup>
      </div>
    );
  }
}

Select.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired
};

class SelectOption extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick(event) {
    if (underscore.isFunction(this.props.onSelect)) {
      event.stopPropagation();
      event.preventDefault();

      this.props.onSelect(this.props.value);
    }
  }
  render() {
    return <span onClick={this.onClick}>{this.props.children}</span>;
  }
}

SelectOption.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSelect: PropTypes.func
};

Select.Option = SelectOption;

export const InputGroup = props => {
  return <div className="putit-input-group">{props.children}</div>;
};

export const InputAddon = props => {
  const className = ClassName('putit-input-addon', props.className, {
    before: props.before
  });

  return (
    <div className={className} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export const InputAddonButton = props => {
  const className = ClassName('button', props.className);

  return (
    <InputAddon
      before={props.before}
      className={className}
      onClick={props.onClick}
    >
      {props.children}
    </InputAddon>
  );
};

export class RequiredControlLabel extends React.Component {
  render() {
    const symbolClassName = ClassName(this.props.className, 'required-symbol', {
      invalid: !this.props.isValid
    });
    return (
      <div className="putit-required-label">
        <span className={symbolClassName} />
        <ControlLabel>{this.props.children}</ControlLabel>
      </div>
    );
  }
}

RequiredControlLabel.propTypes = {
  isValid: PropTypes.bool
};

export class RequiredFormInput extends React.Component {
  render() {
    return (
      <div className="putit-required-input">
        {this.props.children}
        {!this.props.isValid && (
          <span className="required-text">{this.props.requiredText}</span>
        )}
      </div>
    );
  }
}

RequiredFormInput.propTypes = {
  isValid: PropTypes.bool,
  requiredText: PropTypes.string
};
