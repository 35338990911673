/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Col,
  FormControl,
  FormGroup,
  Glyphicon,
  InputAddon,
  InputAddonButton,
  InputGroup,
  Row,
  Select
} from 'src/components';
import _ from 'src/lib/i18n';

import { DEFAULT_ENV_VALIDATION_STATE, ENV_MODES } from '../defs';

export class EnvEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onNameChange = this.onNameChange.bind(this);
    this.onUserChange = this.onUserChange.bind(this);
    this.onAWSTagsChange = this.onAWSTagsChange.bind(this);
    this.onModeChange = this.onModeChange.bind(this);
    this.onRemoveButtonClick = this.onRemoveButtonClick.bind(this);
  }
  onNameChange(event) {
    this.props.setEnvName(this.props.index, event.target.value);
  }
  onUserChange(event) {
    this.props.setEnvUser(this.props.index, event.target.value);
  }
  onAWSTagsChange(event) {
    this.props.setEnvAWSTags(this.props.index, event.target.value);
  }
  onModeChange(newMode) {
    this.props.setEnvMode(this.props.index, newMode);
  }
  onRemoveButtonClick(event) {
    this.props.removeEnv(this.props.index);
  }
  render() {
    const awsTagsDisabled = this.props.mode !== ENV_MODES.TAGS;
    const userDisabled = this.props.mode === ENV_MODES.NONE;
    const validationState =
      this.props.validationState || DEFAULT_ENV_VALIDATION_STATE;

    return (
      <Row>
        <Col md={3}>
          <FormGroup
            validationState={validationState.get('name') ? null : 'error'}
          >
            <FormControl
              type="text"
              placeholder={_('Environment name')}
              value={this.props.name}
              onChange={this.onNameChange}
            />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup
            validationState={validationState.get('mode') ? null : 'error'}
          >
            <Select value={this.props.mode} onChange={this.onModeChange}>
              <Select.Option value={ENV_MODES.HOST}>
                {_('Deploy to hosts')}
              </Select.Option>
              <Select.Option value={ENV_MODES.TAGS}>
                {_('Deploy to AWS tags')}
              </Select.Option>
              <Select.Option value={ENV_MODES.NONE}>
                {_("Don't deploy")}
              </Select.Option>
            </Select>
          </FormGroup>
        </Col>

        <Col md={2}>
          <FormGroup
            validationState={
              userDisabled || validationState.get('user') ? null : 'error'
            }
          >
            <FormControl
              disabled={userDisabled}
              type="text"
              placeholder={_('User name')}
              value={userDisabled ? _('User name') : this.props.user}
              onChange={this.onUserChange}
            />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup
            validationState={
              awsTagsDisabled || validationState.get('aws_tags')
                ? null
                : 'error'
            }
          >
            <InputGroup>
              <FormControl
                disabled={awsTagsDisabled}
                type="text"
                placeholder={_('AWS Tags')}
                value={awsTagsDisabled ? _('AWS Tags') : this.props.awsTags}
                onChange={this.onAWSTagsChange}
              />

              {this.props.canRemove && (
                <InputAddonButton onClick={this.onRemoveButtonClick}>
                  <Glyphicon bsStyle="danger" glyph="remove" />
                </InputAddonButton>
              )}
              {!this.props.canRemove && <InputAddon />}
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

EnvEditor.propTypes = {
  awsTags: PropTypes.string.isRequired,
  canRemove: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  removeEnv: PropTypes.func.isRequired,
  setEnvAWSTags: PropTypes.func.isRequired,
  setEnvMode: PropTypes.func.isRequired,
  setEnvName: PropTypes.func.isRequired,
  setEnvUser: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
  validationState: PropTypes.instanceOf(Map)
};
