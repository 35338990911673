/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import underscore from 'underscore';

const parseBool = value => {
  if (underscore.isBoolean(value) || underscore.isUndefined(value)) {
    return value;
  } else if (underscore.isString(value)) {
    if (value === 'true') {
      return true;
    } else if (value === 'false') {
      return false;
    }
  }

  throw new Error(`ValueError: ${value} is not valid bool-like value`);
};

export const SettingsProcessor = settings => {
  return underscore.extendOwn({}, settings, {
    needs_setup_wizard: parseBool(settings.needs_setup_wizard)
  });
};
