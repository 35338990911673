/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push as pushRoute } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { setDocumentTitle } from 'redux-doctitle';

import { Alert, AppTable, Loader, NoDataPlaceholder } from 'src/components';
import _ from 'src/lib/i18n.js';
import { autoDispatchActionCreator } from 'src/lib/redux';

import * as AppDashboardActions from '../actions/app_dashboard';
import {
  ArtifactsRow,
  AWSTagsRow,
  CredentialsRow,
  DeploymentsRow,
  NextReleaseDateRow,
  StatusesRow,
  TableHead,
  UpcomingReleasesRow,
  VersionsRow
} from '../components';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

export class AppDashboardView extends React.Component {
  constructor(props) {
    super(props);
    this.onDashboardBreadcrumbItemClick = this.onDashboardBreadcrumbItemClick.bind(
      this
    );
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.application !== nextProps.application) {
      this.props.actions.setDocumentTitle(nextProps.application);
      this.props.actions.loadData(nextProps.application);
    }
  }
  componentDidMount() {
    this.props.actions.setDocumentTitle(this.props.application);
    this.props.actions.loadData(this.props.application);
  }
  componentWillUnmount() {
    this.props.actions.cleanup();
  }
  onDashboardBreadcrumbItemClick(event) {
    this.props.actions.pushRoute('/');
  }
  render() {
    return (
      <div id="AppDashboardView">
        <BreadcrumbsItem to="/#">{_('Dashboard')}</BreadcrumbsItem>
        <BreadcrumbsItem to={`/#/applications/${this.props.application}`}>
          {this.props.application}
        </BreadcrumbsItem>

        {this.props.loading && <Loader />}

        {!this.props.loading && this.props.error && (
          <Alert bsStyle="danger">
            <b>{_('Fetch error.')}</b> {_('Please try again later.')}
          </Alert>
        )}

        {!this.props.loading &&
          !this.props.error &&
          this.props.envs.size === 0 && <NoDataPlaceholder />}

        {!this.props.loading && !this.props.error && this.props.envs.size > 0 && (
          <AppTable.Table>
            <TableHead
              application={this.props.application}
              envs={this.props.envs}
            />

            <VersionsRow statuses={this.props.statuses} />

            <ArtifactsRow
              artifacts={this.props.artifacts}
              envs={this.props.envs}
              statuses={this.props.statuses}
            />

            <DeploymentsRow releaseCounts={this.props.releaseCounts} />

            <StatusesRow
              envs={this.props.envs}
              releaseStatusCounts={this.props.releaseStatusCounts}
            />

            <UpcomingReleasesRow
              envs={this.props.envs}
              upcomingReleases={this.props.upcomingReleases}
            />

            <CredentialsRow credentials={this.props.credentials} />

            <AWSTagsRow envs={this.props.envs} />

            <NextReleaseDateRow
              envs={this.props.envs}
              upcomingReleases={this.props.upcomingReleases}
            />
          </AppTable.Table>
        )}
      </div>
    );
  }
}

AppDashboardView.propTypes = {
  actions: PropTypes.shape({
    cleanup: PropTypes.func.isRequired,
    loadData: PropTypes.func.isRequired,
    pushRoute: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired
  }),
  application: PropTypes.string.isRequired,
  artifacts: PropTypes.instanceOf(Map).isRequired,
  credentials: PropTypes.instanceOf(List).isRequired,
  envs: PropTypes.instanceOf(List).isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  releaseCounts: PropTypes.instanceOf(List).isRequired,
  releaseStatusCounts: PropTypes.instanceOf(Map).isRequired,
  statuses: PropTypes.instanceOf(List).isRequired,
  upcomingReleases: PropTypes.instanceOf(Map).isRequired
};

const mapStateToProps = (state, props) => {
  return {
    application: props.routeParams.application,
    artifacts: state.appDashboard.get('artifacts'),
    credentials: state.appDashboard.get('credentials'),
    envs: state.appDashboard.get('envs'),
    error: state.appDashboard.get('error'),
    loading: state.appDashboard.get('loading'),
    releaseCounts: state.appDashboard.get('releaseCounts'),
    releaseStatusCounts: state.appDashboard.get('releaseStatusCounts'),
    statuses: state.appDashboard.get('statuses'),
    upcomingReleases: state.appDashboard.get('upcomingReleases')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      cleanup: AppDashboardActions.cleanup,
      pushRoute: pushRoute,
      setDocumentTitle: setDocumentTitle
    },
    dispatch
  );

  actions.loadData = autoDispatchActionCreator(
    AppDashboardActions.loadData,
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppDashboardView);

export default reduxContainer;
