/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import underscore from 'underscore';

import Config from 'src/config';
import { SETTINGS_LOCAL_STORAGE_KEYS } from 'src/blueprints/core/defs';
import { FALLBACK_DEFAULT_LANG } from 'src/lib/defs';
import * as LocalStorage from 'src/lib/local_storage';

let CURRENT_LANG = undefined;
let MESSAGES = {};

export const init = () => {
  let lang = LocalStorage.getItem(SETTINGS_LOCAL_STORAGE_KEYS.LANG);
  if (!lang) {
    lang = Config.get('default_lang', FALLBACK_DEFAULT_LANG);
  }

  CURRENT_LANG = lang;
  return CURRENT_LANG;
};

export const gettext = (msgId, values) => {
  const message = MESSAGES[msgId] || msgId;

  if (message && values) {
    const template = underscore.template(message);

    return template(values || {});
  }

  return message;
};

export const clearLang = () => {
  LocalStorage.removeItem(SETTINGS_LOCAL_STORAGE_KEYS.LANG);
  setMessages();
};

export const setLang = newLang => {
  LocalStorage.setItem(SETTINGS_LOCAL_STORAGE_KEYS.LANG, newLang);
  setMessages();
};

export const setMessages = newMessages => {
  MESSAGES = underscore.extendOwn({}, newMessages || {});
};

export default gettext;
