/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push as pushRoute } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { setDocumentTitle } from 'redux-doctitle';
import underscore from 'underscore';

import { AppWizardView } from 'src/blueprints/setup_wizard';
import {
  Alert,
  AppTable,
  Button,
  Col,
  Glyphicon,
  Loader,
  NoDataPlaceholder,
  Row
} from 'src/components';
import _ from 'src/lib/i18n.js';
import { autoDispatchActionCreator } from 'src/lib/redux';

import * as DashboardActions from '../actions/dashboard';
import { ApplicationTableRow } from '../components';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

export class DashboardView extends React.Component {
  constructor(props) {
    super(props);
    this.onAddApplicationButtonClick = this.onAddApplicationButtonClick.bind(
      this
    );
    this.onApplicationButtonClick = this.onApplicationButtonClick.bind(this);
    this.onAppWizardModalClose = this.onAppWizardModalClose.bind(this);

    this.state = {
      showAppWizardModal: false
    };
  }
  componentDidMount() {
    this.props.actions.setDocumentTitle(_('Dashboard'));
    this.props.actions.listApplications();
  }
  componentWillUnmount() {
    this.props.actions.cleanup();
  }
  onAddApplicationButtonClick() {
    this.props.actions.setDocumentTitle(_('Add an application'));
    this.setState({ showAppWizardModal: true });
  }
  onApplicationButtonClick(application) {
    this.props.actions.pushRoute('/applications/' + application.name);
  }
  onAppWizardModalClose() {
    this.props.actions.setDocumentTitle(_('Dashboard'));
    this.setState({ showAppWizardModal: false });
    this.props.actions.listApplications();
  }
  render() {
    return (
      <div id="DashboardView">
        <BreadcrumbsItem to="/#">{_('Dashboard')}</BreadcrumbsItem>
        <Row>
          <Col md={2} mdOffset={10} className="text-center">
            <Button onClick={this.onAddApplicationButtonClick}>
              <Glyphicon glyph="plus" /> New App
            </Button>
          </Col>
        </Row>
        {this.props.loading && <Loader />}
        {!this.props.loading && this.props.error && (
          <Alert bsStyle="danger">
            <b>{_('Fetch error.')}</b> {_('Please try again later.')}
          </Alert>
        )}
        {!this.props.loading &&
          !this.props.error &&
          this.props.applications.length === 0 && <NoDataPlaceholder />}
        {!this.props.loading &&
          !this.props.error &&
          this.props.applications &&
          underscore.map(this.props.applications, (application, index) => {
            return (
              <AppTable.Table key={application.id}>
                <AppTable.Head>
                  <AppTable.Header />

                  {application.envs.length === 0 && (
                    <AppTable.Header columnClassName="empty">
                      &nbsp;
                    </AppTable.Header>
                  )}

                  {application.envs.length > 0 && (
                    <AppTable.ScrollView>
                      {underscore.map(application.envs, (env, index) => {
                        return (
                          <AppTable.Header key={env.id}>
                            {env.name}
                          </AppTable.Header>
                        );
                      })}
                    </AppTable.ScrollView>
                  )}
                </AppTable.Head>

                <ApplicationTableRow
                  application={application}
                  loadEnvStatus={this.props.actions.loadEnvStatus}
                  onApplicationButtonClick={this.onApplicationButtonClick}
                />
              </AppTable.Table>
            );
          })}
        <AppWizardView
          show={this.state.showAppWizardModal}
          onClose={this.onAppWizardModalClose}
        />
      </div>
    );
  }
}

DashboardView.propTypes = {
  applications: PropTypes.array.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    cleanup: PropTypes.func.isRequired,
    listApplications: PropTypes.func.isRequired,
    loadEnvStatus: PropTypes.func.isRequired,
    pushRoute: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired
  })
};

const mapStateToProps = (state, props) => {
  return {
    applications: state.dashboard.get('applications').toJS(),
    loading: state.dashboard.get('loading'),
    error: state.dashboard.get('error')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      pushRoute: pushRoute,
      setDocumentTitle: setDocumentTitle
    },
    dispatch
  );

  actions.cleanup = autoDispatchActionCreator(
    DashboardActions.cleanup,
    dispatch
  );

  actions.listApplications = autoDispatchActionCreator(
    DashboardActions.listApplications,
    dispatch
  );

  actions.loadEnvStatus = autoDispatchActionCreator(
    DashboardActions.loadEnvStatus,
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardView);

export default reduxContainer;
