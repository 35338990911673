/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Form, FormControl, FormGroup } from 'src/components';
import { RE_APP_ENV_NAME } from 'src/lib/defs';
import _ from 'src/lib/i18n';

import * as SetupWizardActions from '../actions/setup_wizard';

export class AppPageView extends React.Component {
  constructor(props) {
    super(props);
    this.onAppNameChange = this.onAppNameChange.bind(this);

    this.state = {
      appNameValid: true
    };
  }
  componentDidMount() {
    this.props.actions.enableBack();

    if (this.validateAppName(this.props.appName)) {
      this.props.actions.enableNext();
    } else {
      this.props.actions.disableNext();
    }
  }
  componentWillReceiveProps(nextProps) {
    const appNameValid = this.validateAppName(nextProps.appName);
    if (appNameValid) {
      this.props.actions.enableNext();
    } else {
      this.props.actions.disableNext();
    }

    this.setState({ appNameValid: appNameValid });
  }
  onAppNameChange(event) {
    this.props.actions.setAppName(event.target.value);
  }
  validateAppName(appName) {
    return typeof appName === 'string' && RE_APP_ENV_NAME.test(appName);
  }
  render() {
    return (
      <div>
        <h4>{_('Set up an application')}</h4>
        <p>
          {_(
            'Enter name of the application in the field below and click Next.'
          )}
        </p>
        <Form>
          <FormGroup
            controlId="appName"
            validationState={this.state.appNameValid ? null : 'error'}
          >
            <FormControl
              id="appName"
              type="text"
              placeholder={_('Application name')}
              value={this.props.appName}
              onChange={this.onAppNameChange}
            />
          </FormGroup>
        </Form>
      </div>
    );
  }
}

AppPageView.propTypes = {
  actions: PropTypes.shape({
    disableNext: PropTypes.func.isRequired,
    enableBack: PropTypes.func.isRequired,
    enableNext: PropTypes.func.isRequired,
    setAppName: PropTypes.func.isRequired
  }),
  appName: PropTypes.string
};

const mapStateToProps = (state, props) => {
  return {
    appName: state.setupWizard.get('appName')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      disableNext: SetupWizardActions.disableNext,
      enableBack: SetupWizardActions.enableBack,
      enableNext: SetupWizardActions.enableNext,
      setAppName: SetupWizardActions.setAppName
    },
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppPageView);

export default reduxContainer;
