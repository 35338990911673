/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import underscore from 'underscore';
import URI from 'urijs';

import { FALLBACK_DEFAULT_LANG } from './defs';

const windowURL = new URI(window.location)
  .pathname('/')
  .fragment('')
  .href();

let apiBaseURL;
let authBaseURL;
if (process.env.REACT_APP_REAL_BACKEND) {
  const currentUrl = window.location.origin;
  apiBaseURL = new URI(currentUrl).pathname('/api/');
  authBaseURL = new URI(currentUrl).pathname('/users/');
} else {
  apiBaseURL = new URI('http://localhost:3000/').pathname('/');
  authBaseURL = new URI('http://localhost:3000/').pathname('/users/');
}

const DEFAULT_CONFIG = {
  api_base_url: apiBaseURL.toString(),
  auth_base_url: authBaseURL.toString(),
  build: '?',
  default_lang: FALLBACK_DEFAULT_LANG,
  version: '1.0.0',
  window_url: windowURL
};

const config = {
  ...DEFAULT_CONFIG,
  ...window.PUTIT_CONFIG
};

const get = (key, def = undefined) => {
  const result = config[key];
  if (underscore.isUndefined(result)) {
    return def;
  }

  return result;
};

const update = newConfig => {
  if (newConfig) {
    underscore.extendOwn(config, newConfig);
  }
};

const appVersion = (full = true) => {
  let build = get('build');

  if (!full) {
    build = build.substring(0, 6);
  }

  return `Putit ${get('version')} (${build})`;
};

export default {
  appVersion: appVersion,
  get: get,
  update: update
};
