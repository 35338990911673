/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import underscore from 'underscore';

import {
  Col,
  FormControl,
  FormGroup,
  Glyphicon,
  InputAddon,
  InputAddonButton,
  InputGroup,
  Row,
  Select
} from 'src/components';
import _ from 'src/lib/i18n';

import { DEFAULT_HOST_VALIDATION_STATE, ENV_MODES } from '../defs';

export class HostEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onEnvChange = this.onEnvChange.bind(this);
    this.onIPChange = this.onIPChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onRemoveButtonClick = this.onRemoveButtonClick.bind(this);
  }
  onEnvChange(env) {
    this.props.setHostEnv(this.props.index, env);
  }
  onIPChange(event) {
    this.props.setHostIP(this.props.index, event.target.value);
  }
  onNameChange(event) {
    this.props.setHostName(this.props.index, event.target.value);
  }
  onRemoveButtonClick(event) {
    this.props.removeHost(this.props.index);
  }
  render() {
    const validationState =
      this.props.validationState || DEFAULT_HOST_VALIDATION_STATE;

    return (
      <Row>
        <Col md={3}>
          <FormGroup
            validationState={validationState.get('env') ? null : 'error'}
          >
            <Select onChange={this.onEnvChange} value={this.props.env}>
              <Select.Option value={-1}>{_('-- choose --')}</Select.Option>
              {underscore.map(this.props.envs, (env, index) => {
                if (env.mode !== ENV_MODES.HOST) {
                  return null;
                }

                return (
                  <Select.Option key={index} value={index}>
                    {env.name}
                  </Select.Option>
                );
              })}
            </Select>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup
            validationState={validationState.get('ip') ? null : 'error'}
          >
            <FormControl
              type="text"
              placeholder="127.0.0.1"
              value={this.props.ip}
              onChange={this.onIPChange}
            />
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup
            validationState={validationState.get('name') ? null : 'error'}
          >
            <InputGroup>
              <FormControl
                type="text"
                placeholder="host.env.app.example.com"
                value={this.props.name}
                onChange={this.onNameChange}
              />

              {this.props.canRemove && (
                <InputAddonButton onClick={this.onRemoveButtonClick}>
                  <Glyphicon bsStyle="danger" glyph="remove" />
                </InputAddonButton>
              )}
              {!this.props.canRemove && <InputAddon />}
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

HostEditor.propTypes = {
  canRemove: PropTypes.bool.isRequired,
  env: PropTypes.number.isRequired,
  envs: PropTypes.instanceOf(Array).isRequired,
  index: PropTypes.number.isRequired,
  ip: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  removeHost: PropTypes.func.isRequired,
  setHostIP: PropTypes.func.isRequired,
  setHostEnv: PropTypes.func.isRequired,
  setHostName: PropTypes.func.isRequired,
  validationState: PropTypes.instanceOf(Map)
};
