/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDocumentTitle } from 'redux-doctitle';
import underscore from 'underscore';

import {
  Alert,
  Button,
  FormControl,
  FormGroup,
  FullWindowContainer
} from 'src/components';
import _ from 'src/lib/i18n.js';
import { autoDispatchActionCreator } from 'src/lib/redux';

import * as ResetPasswordActions from '../actions/reset_password';

export class ResetPasswordView extends React.Component {
  constructor(props) {
    super(props);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      email: '',
      emailValid: true
    };
  }
  componentDidMount() {
    this.props.actions.setDocumentTitle(_('Reset password'));
  }
  componentWillUnmount() {
    this.props.actions.cleanup();
  }
  validateEmail(value) {
    let result = true;
    result = result && value.length > 0;

    return { emailValid: result };
  }
  validateAll() {
    const newState = {};
    underscore.extendOwn(newState, this.validateEmail(this.state.email));

    return newState;
  }
  onEmailChange(event) {
    const newValue = event.target.value;
    const newState = { email: newValue };
    underscore.extendOwn(newState, this.validateEmail(newValue));

    this.setState(newState);
  }
  onSubmit(event) {
    event.stopPropagation();
    event.preventDefault();

    const validationResult = this.validateAll();

    if (underscore.all(underscore.values(validationResult))) {
      this.props.actions.resetPasswordStart(this.state.email);
    } else {
      this.setState(validationResult);
    }
  }
  render() {
    return (
      <FullWindowContainer cols={4} id="ResetPasswordView">
        {this.props.error && (
          <Alert bsStyle="danger">
            <b>{_('Password reset error.')}</b>
            &nbsp;
            {_('Please check the details and try again.')}
          </Alert>
        )}

        {!this.props.success && (
          <form onSubmit={this.onSubmit}>
            <FormGroup
              controlId="email"
              validationState={this.state.emailValid ? null : 'error'}
            >
              <FormControl
                id="email"
                type="text"
                placeholder={_('E-mail')}
                disabled={this.props.submitting}
                value={this.state.email}
                onChange={this.onEmailChange}
              />
            </FormGroup>
            <div className="text-center">
              <Button
                bsStyle="default"
                type="submit"
                disabled={this.props.submitting}
              >
                {this.props.submitting
                  ? _('Resetting password...')
                  : _('Reset password')}
              </Button>
            </div>
          </form>
        )}

        {this.props.success && (
          <Alert bsStyle="success">
            <b>{_('Your password has been reset.')}</b>
            &nbsp;
            {_('We sent you an e-mail with further instructions.')}
          </Alert>
        )}
      </FullWindowContainer>
    );
  }
}

ResetPasswordView.propTypes = {
  actions: PropTypes.shape({
    cleanup: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired,
    resetPasswordStart: PropTypes.func.isRequired
  }),
  error: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    error: state.resetPassword.get('error'),
    submitting: state.resetPassword.get('submitting'),
    success: state.resetPassword.get('success')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      cleanup: ResetPasswordActions.cleanup,
      setDocumentTitle: setDocumentTitle
    },
    dispatch
  );

  actions.resetPasswordStart = autoDispatchActionCreator(
    ResetPasswordActions.start,
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordView);

export default reduxContainer;
