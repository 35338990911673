/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push as pushRoute } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { setDocumentTitle } from 'redux-doctitle';
import underscore from 'underscore';

import {
  Alert,
  Button,
  Col,
  Glyphicon,
  Loader,
  NoDataPlaceholder,
  Row,
  FormControl
} from 'src/components';
import _ from 'src/lib/i18n.js';
import { autoDispatchActionCreator } from 'src/lib/redux';

import * as RCDashboardActions from '../actions/rc_dashboard';
import { ReleaseTable } from '../components';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

export class RCDashboardView extends React.Component {
  constructor(props) {
    super(props);
    this.onReleaseOrderStatusCellClick = this.onReleaseOrderStatusCellClick.bind(
      this
    );
    this.onPopupOverlayClick = this.onPopupOverlayClick.bind(this);
    this.inputOnChange = this.inputOnChange.bind(this);
    this.onReleaseDetailsClick = this.onReleaseDetailsClick.bind(this);
    this.onAddNewChangeClick = this.onAddNewChangeClick.bind(this);
    this.setSearchString = underscore.debounce(
      this.setSearchString.bind(this),
      500
    );
  }
  componentDidMount() {
    this.setDocumentTitle();
    this.loadData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filters !== this.props.filters) {
      this.loadData();
    }
    this.setDocumentTitle();
  }
  componentWillUnmount() {
    this.props.actions.cleanup();
  }
  loadData() {
    this.props.actions.loadData(this.props.filters.toJS());
  }
  setDocumentTitle() {
    const titleParts = [_('Releases')];
    if (this.props.expandedReleaseOrderName) {
      titleParts.unshift(this.props.expandedReleaseOrderName);
    }

    this.props.actions.setDocumentTitle(titleParts.join(' | '));
  }
  onReleaseOrderStatusCellClick(env, releaseOrder) {
    this.props.actions.pushRoute(`/releases/${env.name}/${releaseOrder.name}`);
  }
  onReleaseDetailsClick(release) {
    this.props.actions.pushRoute(`/releases/${release.name}/Details`);
  }
  onAddNewChangeClick() {
    this.props.actions.pushRoute(`/releases/AddNewChange`);
  }
  onPopupOverlayClick() {
    this.props.actions.pushRoute('/releases/');
  }
  inputOnChange(e) {
    this.setSearchString(e.currentTarget.value);
  }
  setSearchString(searchString) {
    this.props.actions.setSearchString(searchString);
  }
  render() {
    return (
      <div id="RCDashboardView">
        <BreadcrumbsItem to="/#/releases">{_('Releases')}</BreadcrumbsItem>
        <Row>
          <Col md={4}>
            <div className="form-group search-input">
              <FormControl
                type="text"
                placeholder="Search releases or changes"
                onChange={this.inputOnChange}
                icon="ICON_Finder"
              />
            </div>
          </Col>
          <Col md={3} className="pull-right">
            <Button className="btn-white" onClick={this.onAddNewChangeClick}>
              Add new change
            </Button>
            <Button>
              <Glyphicon glyph="plus" /> New Release
            </Button>
          </Col>
        </Row>

        {this.props.loading && <Loader />}

        {!this.props.loading && this.props.error && (
          <Alert bsStyle="danger">
            <b>{_('Fetch error.')}</b> {_('Please try again later.')}
          </Alert>
        )}

        {!this.props.loading &&
          !this.props.error &&
          this.props.releases.size === 0 && <NoDataPlaceholder />}

        {!this.props.loading &&
          !this.props.error &&
          this.props.releases.size > 0 && (
            <React.Fragment>
              {underscore.map(this.props.releases.toJS(), (release, index) => {
                return (
                  <ReleaseTable
                    key={release.id}
                    expandedEnvName={this.props.expandedEnvName}
                    expandedReleaseOrderName={
                      this.props.expandedReleaseOrderName
                    }
                    release={release}
                    onReleaseOrderStatusCellClick={
                      this.onReleaseOrderStatusCellClick
                    }
                    onReleaseDetailsClick={this.onReleaseDetailsClick}
                    onPopupOverlayClick={this.onPopupOverlayClick}
                    onAddNewChangeClick={this.onAddNewChangeClick}
                  />
                );
              })}
            </React.Fragment>
          )}
      </div>
    );
  }
}

RCDashboardView.propTypes = {
  actions: PropTypes.shape({
    cleanup: PropTypes.func.isRequired,
    loadData: PropTypes.func.isRequired,
    pushRoute: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired
  }),
  error: PropTypes.string,
  expandedEnvName: PropTypes.string,
  expandedReleaseOrderName: PropTypes.string,
  filters: PropTypes.instanceOf(Map).isRequired,
  loading: PropTypes.bool.isRequired,
  releases: PropTypes.instanceOf(List).isRequired
};

const mapStateToProps = (state, props) => {
  return {
    error: state.rcDashboard.get('error'),
    expandedEnvName: props.params.envName,
    expandedReleaseOrderName: props.params.releaseOrderName,
    filters: state.rcDashboard.get('filters'),
    loading: state.rcDashboard.get('loading'),
    releases: state.rcDashboard.get('releases')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      pushRoute: pushRoute,
      setDocumentTitle: setDocumentTitle,
      setSearchString: RCDashboardActions.setSearchString
    },
    dispatch
  );

  actions.cleanup = autoDispatchActionCreator(
    RCDashboardActions.cleanup,
    dispatch
  );

  actions.loadData = autoDispatchActionCreator(
    RCDashboardActions.loadData,
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RCDashboardView);

export default reduxContainer;
