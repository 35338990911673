/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import underscore from 'underscore';

import * as parsers from './parsers';

const TRACKED_ORDER_KEYS = [
  'id',
  'name',
  'description',
  'status',
  'applications_with_versions'
];

export const OrdersProcessor = (orders, rejectEmpty = false) => {
  let result = underscore.reduce(
    orders,
    (memo, order) => {
      const currentReleaseIndex = underscore.findIndex(memo, item => {
        return item.id === order.release.id;
      });

      let currentRelease = null;
      if (currentReleaseIndex !== -1) {
        currentRelease = memo[currentReleaseIndex];
      } else {
        currentRelease = underscore.extendOwn({}, order.release, {
          max_orders_count: 0,
          start_date: null,
          end_date: null,
          envs: []
        });
        memo.push(currentRelease);
      }

      const currentReleaseStartDates = [];
      const currentReleaseEndDates = [];

      underscore.forEach(
        order.applications_with_versions,
        (application, index) => {
          underscore.forEach(application.envs, (env, index) => {
            const currentEnvIndex = underscore.findIndex(
              currentRelease.envs,
              item => {
                return item.name === env.name;
              }
            );

            let currentEnv = null;
            let currentEnvOrderIds = null;
            if (currentEnvIndex !== -1) {
              currentEnv = currentRelease.envs[currentEnvIndex];

              currentEnvOrderIds = underscore.map(
                currentEnv.orders,
                (currentEnvOrder, index) => {
                  return currentEnvOrder.id;
                }
              );
            } else {
              currentEnv = underscore.extendOwn({}, env, {
                orders: []
              });
              currentRelease.envs.push(currentEnv);

              currentEnvOrderIds = [];
            }

            if (currentEnvOrderIds.indexOf(order.id) !== -1) {
              return;
            }

            const processedOrder = underscore.pick(
              order,
              ...TRACKED_ORDER_KEYS
            );
            processedOrder.start_date = parsers.DateParser(order.start_date);
            processedOrder.end_date = parsers.DateParser(order.end_date);

            processedOrder.release_order_results = underscore.map(
              order.release_order_results,
              (releaseOrderResult, index) => {
                const processedResult = underscore.omit(releaseOrderResult, [
                  'deployment_date'
                ]);

                processedResult.deployment_date = parsers.DateParser(
                  releaseOrderResult.deployment_date
                );

                return processedResult;
              }
            );

            if (processedOrder.start_date) {
              currentReleaseStartDates.push(processedOrder.start_date);
            }

            if (processedOrder.end_date) {
              currentReleaseEndDates.push(processedOrder.end_date);
            }

            currentEnv.orders.push(processedOrder);

            if (currentEnv.orders.length > currentRelease.max_orders_count) {
              currentRelease.max_orders_count = currentEnv.orders.length;
            }
          });
        }
      );

      if (currentReleaseStartDates.length > 0) {
        currentReleaseStartDates.sort();
        const newStartDate = currentReleaseStartDates[0];
        const currentStartDate = currentRelease.start_date;
        if (!currentStartDate || newStartDate < currentStartDate) {
          currentRelease.start_date = newStartDate;
        }
      }

      if (currentReleaseEndDates.length > 0) {
        currentReleaseEndDates.sort();
        const newEndDate =
          currentReleaseEndDates[currentReleaseEndDates.length - 1];
        const currentEndDate = currentRelease.end_date;
        if (!currentEndDate || newEndDate > currentEndDate) {
          currentRelease.end_date = newEndDate;
        }
      }

      return memo;
    },
    []
  );

  if (rejectEmpty) {
    result = underscore.reject(result, item => {
      return item.envs.length === 0;
    });
  }

  return result;
};
