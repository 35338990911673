/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import underscore from 'underscore';

import { Button, Form, Glyphicon } from 'src/components';
import { RE_IP } from 'src/lib/defs';
import _ from 'src/lib/i18n';

import * as SetupWizardActions from '../actions/setup_wizard';
import { HostEditor } from '../components';
import { DEFAULT_HOST_VALIDATION_STATE, ENV_MODES } from '../defs';

export class HostsPageView extends React.Component {
  constructor(props) {
    super(props);
    this.onAddHostButtonClick = this.onAddHostButtonClick.bind(this);

    this.state = {
      validationStates: this.props.hosts.map(() => {
        return DEFAULT_HOST_VALIDATION_STATE;
      })
    };
  }
  componentDidMount() {
    this.props.actions.enableBack();

    const allValid = this.validateAll(this.props.hosts).every(value => {
      return value.get('env') && value.get('ip') && value.get('name');
    });

    const allEnvsAssigned = this.allEnvsAssigned(this.props.hosts);
    if (allValid && allEnvsAssigned) {
      this.props.actions.enableNext();
    } else {
      this.props.actions.disableNext();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.hosts !== nextProps.hosts) {
      const validationStates = this.validateAll(nextProps.hosts);
      const allValid = validationStates.every(value => {
        return value.get('env') && value.get('ip') && value.get('name');
      });

      const allEnvsAssigned = this.allEnvsAssigned(nextProps.hosts);

      if (allValid && allEnvsAssigned) {
        this.props.actions.enableNext();
      } else {
        this.props.actions.disableNext();
      }

      if (this.props.hosts.size >= nextProps.hosts.size) {
        this.setState({ validationStates: validationStates });
      }
    }
  }
  onAddHostButtonClick(event) {
    this.props.actions.addHost(-1, '', '');
  }
  allEnvsAssigned(hosts) {
    let initialHostsToEnvs = Map({});
    this.props.envs.forEach((env, index) => {
      if (env.mode === ENV_MODES.HOST) {
        initialHostsToEnvs = initialHostsToEnvs.set(index, 0);
      }
    });

    const hostsToEnvs = hosts.reduce((memo, host) => {
      if (host.get('env') !== -1) {
        return memo.update(host.get('env'), value => value + 1);
      }

      return memo;
    }, initialHostsToEnvs);

    return hostsToEnvs.every((value, key) => value > 0);
  }
  validateAll(hosts) {
    return hosts.map((host, index) => {
      return Map({
        env: typeof host.get('env') === 'number' && host.get('env') !== -1,
        ip:
          typeof host.get('ip') === 'string' &&
          (host.get('ip') === '' || RE_IP.test(host.get('ip'))),
        name:
          typeof host.get('name') === 'string' && host.get('name').length > 0
      });
    });
  }
  render() {
    return (
      <div>
        <h4>{_('Set up hosts')}</h4>
        <p>
          {_('Specify at least one host for every environment and click Next.')}
        </p>
        <Form>
          {underscore.map(this.props.hosts.toJS(), (host, index) => {
            return (
              <HostEditor
                key={index}
                canRemove={this.props.hosts.size > 1}
                env={host.env}
                envs={this.props.envs}
                index={index}
                ip={host.ip}
                name={host.name}
                removeHost={this.props.actions.removeHost}
                setHostEnv={this.props.actions.setHostEnv}
                setHostIP={this.props.actions.setHostIP}
                setHostName={this.props.actions.setHostName}
                validationState={this.state.validationStates.get(index)}
              />
            );
          })}
        </Form>
        <div className="text-right">
          <Button bsStyle="success" onClick={this.onAddHostButtonClick}>
            <Glyphicon glyph="plus" /> {_('Add new')}
          </Button>
        </div>
      </div>
    );
  }
}

HostsPageView.propTypes = {
  actions: PropTypes.shape({
    addHost: PropTypes.func.isRequired,
    disableNext: PropTypes.func.isRequired,
    enableBack: PropTypes.func.isRequired,
    enableNext: PropTypes.func.isRequired,
    removeHost: PropTypes.func.isRequired,
    setHostEnv: PropTypes.func.isRequired,
    setHostIP: PropTypes.func.isRequired,
    setHostName: PropTypes.func.isRequired
  }),
  envs: PropTypes.instanceOf(Array).isRequired,
  hosts: PropTypes.instanceOf(List).isRequired
};

const mapStateToProps = (state, props) => {
  return {
    envs: state.setupWizard.get('envs').toJS(),
    hosts: state.setupWizard.get('hosts')
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      addHost: SetupWizardActions.addHost,
      disableNext: SetupWizardActions.disableNext,
      enableBack: SetupWizardActions.enableBack,
      enableNext: SetupWizardActions.enableNext,
      removeHost: SetupWizardActions.removeHost,
      setHostEnv: SetupWizardActions.setHostEnv,
      setHostIP: SetupWizardActions.setHostIP,
      setHostName: SetupWizardActions.setHostName
    },
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HostsPageView);

export default reduxContainer;
