/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';

import BootstapButton from 'react-bootstrap/lib/Button';
import ClassName from 'classnames';

export class Button extends React.PureComponent {
  render() {
    const className = ClassName('btn-wrapper', this.props.wrapperClassName);
    const { wrapperClassName, ...props } = this.props;
    return (
      <div className={className}>
        <BootstapButton {...props}>{this.props.children}</BootstapButton>
      </div>
    );
  }
}

Button.propTypes = BootstapButton.propTypes;
