/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push as pushRoute } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { setDocumentTitle } from 'redux-doctitle';

import { FullWindowContainer } from 'src/components';
import _ from 'src/lib/i18n.js';

import * as SetupWizardActions from '../actions/setup_wizard';
import { SETUP_WIZARD_MODAL_MODE } from '../defs';
import SetupWizardModalView from './ModalView';

export class SetupWizardView extends React.Component {
  constructor(props) {
    super(props);
    this.onModalClose = this.onModalClose.bind(this);
  }
  componentDidMount() {
    this.props.actions.setDocumentTitle(_('Setup'));
  }
  componentWillUnmount() {
    this.props.actions.cleanup();
  }
  onModalClose() {
    this.props.actions.pushRoute('/');
  }
  render() {
    return (
      <FullWindowContainer>
        <SetupWizardModalView
          autoFocus={true}
          mode={SETUP_WIZARD_MODAL_MODE.SETUP_WIZARD}
          static={true}
          show={true}
          onClose={this.onModalClose}
        />
      </FullWindowContainer>
    );
  }
}

SetupWizardView.propTypes = {
  actions: PropTypes.shape({
    cleanup: PropTypes.func.isRequired,
    pushRoute: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired
  })
};

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = bindActionCreators(
    {
      cleanup: SetupWizardActions.cleanup,
      pushRoute: pushRoute,
      setDocumentTitle: setDocumentTitle
    },
    dispatch
  );

  return {
    actions: actions
  };
};

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupWizardView);

export default reduxContainer;
