/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import PropTypes from 'prop-types';
import React from 'react';
import Downshift from 'downshift';

export class ReleaseInputAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.autocomplete = null;

    this.onSelectedItem = this.onSelectedItem.bind(this);
  }

  onSelectedItem(selection) {
    this.props.onSelectedItem(selection);
  }

  clearSelection() {
    this.autocomplete.clearSelection();
  }

  render() {
    return (
      <Downshift
        ref={autocomplete => (this.autocomplete = autocomplete)}
        onChange={selection => this.onSelectedItem(selection)}
        itemToString={item => (item ? item.name : '')}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          inputValue,
          highlightedIndex,
          isOpen
        }) => (
          <div>
            <input
              className="autocomplete-input"
              {...getInputProps({
                placeholder: this.props.inputPlaceholder
              })}
            />

            <ul className="autocomplete-list" {...getMenuProps()}>
              {isOpen &&
                this.props.itemsList
                  .filter(
                    item =>
                      !inputValue ||
                      item.name.toLowerCase().includes(inputValue.toLowerCase())
                  )
                  .map((item, index) => (
                    <li
                      className="autocomplete-item"
                      {...getItemProps({
                        key: `${item.id}`,
                        item,
                        index,
                        active: (highlightedIndex === index).toString()
                      })}
                    >
                      {item.name}
                    </li>
                  ))}
            </ul>
          </div>
        )}
      </Downshift>
    );
  }
}

ReleaseInputAutocomplete.propTypes = {
  onSelectedItem: PropTypes.func,
  itemsList: PropTypes.array,
  inputPlaceholder: PropTypes.string
};
