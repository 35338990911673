/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import underscore from 'underscore';

import { NO_VALUE_PLACEHOLDER } from 'src/lib/defs';

export const DateFormatter = props => {
  if (!underscore.isDate(props.value)) {
    return NO_VALUE_PLACEHOLDER;
  }

  return props.value.toDateString();
};

export const SlashDateFormatter = props => {
  if (!underscore.isDate(props.value)) {
    return NO_VALUE_PLACEHOLDER;
  }

  return (
    props.value.getFullYear() +
    '/' +
    props.value.getMonth() +
    '/' +
    props.value.getDate()
  );
};
