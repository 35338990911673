/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import underscore from 'underscore';
import URI from 'urijs';

import Config from 'src/config';
import API from 'src/lib/api';
import { SettingsProcessor } from 'src/lib/data_processors';
import * as i18n from 'src/lib/i18n';
import { get } from 'src/lib/requests';

import { SETTINGS_ACTIONS, SETTINGS_DEFAULTS } from '../defs';

export const initI18N = () => {
  const lang = i18n.init();

  return {
    type: SETTINGS_ACTIONS.INIT_I18N,
    lang: lang
  };
};

export const loadMessages = (dispatch, lang) => {
  const requestURL = new URI(Config.get('window_url'));
  requestURL.pathname(URI.joinPaths('/messages', lang + '.json'));

  get(
    requestURL,
    data => {
      dispatch(onLoadMessagesSuccess(data));
    },
    error => {
      dispatch(onLoadMessagesError(error));
    }
  );

  return {
    type: SETTINGS_ACTIONS.LOAD_MESSAGES_START
  };
};

export const setLang = lang => {
  if (lang) {
    i18n.setLang(lang);
  } else {
    i18n.clearLang();
  }

  const actualLang = i18n.init();

  return {
    type: SETTINGS_ACTIONS.SET_LANG,
    lang: actualLang
  };
};

export const onLoadMessagesSuccess = data => {
  i18n.setMessages(data);

  return {
    type: SETTINGS_ACTIONS.LOAD_MESSAGES_SUCCESS
  };
};

export const onLoadMessagesError = error => {
  return {
    type: SETTINGS_ACTIONS.LOAD_MESSAGES_ERROR,
    messagesLoadError: error
  };
};

export const loadSettings = dispatch => {
  API.Settings.load(
    data => {
      dispatch(onLoadSettingsSuccess(data));
    },
    error => {
      dispatch(onLoadSettingsError(error));
    }
  );

  return {
    type: SETTINGS_ACTIONS.LOAD_SETTINGS_START
  };
};

export const onLoadSettingsSuccess = data => {
  const processedData = SettingsProcessor(data);

  /// #if !development
  // const urlsConfig = {};
  // if (!underscore.isUndefined(processedData.putit_core_url)) {
  //   urlsConfig.api_base_url = processedData.putit_core_url;
  // }

  // if (!underscore.isUndefined(processedData.putit_auth_url)) {
  //   urlsConfig.auth_base_url = processedData.putit_auth_url;
  // }

  // Config.update(urlsConfig);
  /// #endif

  let needsSetupWizard = SETTINGS_DEFAULTS.needsSetupWizard;
  if (!underscore.isUndefined(processedData.needs_setup_wizard)) {
    needsSetupWizard = processedData.needs_setup_wizard;
  }

  return {
    type: SETTINGS_ACTIONS.LOAD_SETTINGS_SUCCESS,
    needsSetupWizard: needsSetupWizard
  };
};

export const onLoadSettingsError = error => {
  return {
    type: SETTINGS_ACTIONS.LOAD_SETTINGS_ERROR,
    settingsLoadError: error
  };
};

export const disableSetupWizard = () => {
  return {
    type: SETTINGS_ACTIONS.DISABLE_SETUP_WIZARD
  };
};

export const reset = () => {
  return {
    type: SETTINGS_ACTIONS.RESET
  };
};
