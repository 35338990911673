import 'react-app-polyfill/stable';
import AuthBlueprint from 'src/blueprints/auth';
import CoreBlueprint from 'src/blueprints/core';
import SetupWizardBlueprint from 'src/blueprints/setup_wizard';
import AppDashboardBlueprint from 'src/blueprints/app_dashboard';
import DashboardBlueprint from 'src/blueprints/dashboard';
import RCDashboardBlueprint from 'src/blueprints/rc_dashboard';
import DevBlueprint from 'src/blueprints/dev';
import * as MockBackend from 'src/mock_backend';
import putitApp from './putit';

import 'styles/main.scss';

if (!process.env.REACT_APP_REAL_BACKEND) {
  MockBackend.install({
    respondImmediately: false
  });
}

const app = new putitApp();
app.registerBlueprint(AuthBlueprint);
app.registerBlueprint(SetupWizardBlueprint);
app.registerBlueprint(AppDashboardBlueprint);
app.registerBlueprint(DashboardBlueprint);
app.registerBlueprint(RCDashboardBlueprint);
app.registerBlueprint(CoreBlueprint);
app.registerBlueprint(DevBlueprint);
app.run(document.getElementById('root'));
