/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ClassName from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import underscore from 'underscore';

export class ModalButton extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick(event) {
    if (underscore.isFunction(this.props.onClick)) {
      this.props.onClick(event);
    }
  }
  render() {
    const className = ClassName('putit-modal-button', {
      disabled: this.props.disabled,
      'pull-right': this.props.right
    });

    return (
      <div className={className} onClick={this.onClick}>
        <button>{this.props.children || null}</button>
      </div>
    );
  }
}

ModalButton.propTypes = {
  disabled: PropTypes.bool,
  right: PropTypes.bool,
  onClick: PropTypes.func
};
