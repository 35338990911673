/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import underscore from 'underscore';

import API from 'src/lib/api';

import * as SetupWizardActions from '../actions/setup_wizard';
import {
  ENV_MODES,
  SETUP_WIZARD_SETTINGS_UPDATE,
  SETUP_WIZARD_MODAL_MODE
} from '../defs';

export class SetupWizardDataSource {
  constructor(dispatch, application, envs, hosts, mode) {
    this.onApplySuccess = this.onApplySuccess.bind(this);
    this.onApplyError = this.onApplyError.bind(this);
    this.onApplySettingsSuccess = this.onApplySettingsSuccess.bind(this);
    this.onApplySettingsError = this.onApplySettingsError.bind(this);

    this.dispatch = dispatch;
    this.application = application;
    this.envs = envs;
    this.hosts = hosts;
    this.mode = mode;
  }
  apply() {
    const payload = {
      application: {
        name: this.application
      },
      envs: underscore.map(this.envs, (env, index) => {
        return { name: env.name, aws_tags: env.aws_tags || null };
      }),
      hosts: {},
      credentials: {}
    };

    const hasHostEnvs = underscore.any(this.envs, (env, index) => {
      return env.mode === ENV_MODES.HOST;
    });

    if (hasHostEnvs) {
      const initialHostsToEnvs = {};
      underscore.forEach(this.envs, (env, index) => {
        if (env.mode === ENV_MODES.HOST) {
          initialHostsToEnvs[env.name] = [];
        }
      });

      payload.hosts = this.hosts.reduce((memo, host) => {
        const hostEnv = this.envs[host.env].name;
        memo[hostEnv].push({
          name: host.name,
          fqdn: host.name,
          ip: host.ip
        });

        return memo;
      }, initialHostsToEnvs);
    }

    underscore.forEach(this.envs, (env, index) => {
      if (env.user) {
        payload.credentials[env.name] = env.user;
      }
    });

    API.SetupWizard.apply(payload, this.onApplySuccess, this.onApplyError);
  }
  onApplySuccess() {
    this.dispatch(SetupWizardActions.onApplySuccess());
    this.applySettings();
  }
  onApplyError(error, response) {
    let errors = response.json.errors;
    if (error === 'Conflict') {
      errors = response.json.conflicts;
    }

    this.dispatch(SetupWizardActions.onApplyError(error, errors));
  }
  applySettings() {
    if (this.mode === SETUP_WIZARD_MODAL_MODE.SETUP_WIZARD) {
      this.dispatch(SetupWizardActions.applySettingsStart());
      API.Settings.update(
        SETUP_WIZARD_SETTINGS_UPDATE,
        this.onApplySettingsSuccess,
        this.onApplySettingsError
      );
    } else {
      this.onApplySettingsSuccess();
    }
  }
  onApplySettingsSuccess() {
    this.dispatch(SetupWizardActions.onApplySettingsSuccess());
  }
  onApplySettingsError(error) {
    this.dispatch(SetupWizardActions.onApplySettingsError(error));
  }
}
